import React, { Component } from 'react';

import { ActivationContext } from './context';

export default class Wizard extends Component{
    constructor(props){
        super(props);

        this.onActivateTab = (activeTab) => {
            if(this.state.activeTab !== activeTab){
                this.setState({
                    activeTab: activeTab
                })
            }
            else{
                this.setState({
                    activeTab: "0"
                })
            }
        }

        this.activateNextTab = () => {
            this.setState({
                activeTab: (1 + parseInt(this.state.activeTab)) + ''
            })
        }

        this.state = {
            activeTab: '1',
            activateTab: this.onActivateTab,
            activateNextTab: this.activateNextTab
        }
    }

    

    render(){
        return(
            <ActivationContext.Provider value={this.state}>
                <div>
                    {this.props.children}
                </div>
            </ActivationContext.Provider>
        )
    }
}