import React, { Component } from 'react';
import { Container, Row, Col, Label, Input, Button } from 'reactstrap';
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faPen, faPlus, faLock, faTimes, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Loader from 'react-loader-spinner';
import AppNavbar from './AppNavbar';

import { HttpGET, HttpPOST, HttpDELETE } from './service/HttpService';

import './liste.css';

export default class Medecins extends Component{
    constructor(props){
        super(props);

        this.state = {
            medecins: [],
            page: 0,
            limit: 4,
            sending: false,
            filtre: '',

            isFirst: null,
            isLast: null
        }

        this.handleChange = this.handleChange.bind(this);
        this.rechercher = this.rechercher.bind(this);
        this.filtrer = this.filtrer.bind(this);
        this.consulter = this.consulter.bind(this);
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    rechercher(next) {
        this.setState({
            sending: true
        })
        var request = {
            filtre: this.state.filtre
        }

        /*this.setState({
            page:  next ? this.state.page + 1 : this.state.page - 1
        })*/
        var nextPage = next ? this.state.page + 1 : this.state.page - 1;

        HttpPOST('/medicinemanagement-api/recherchermedecinpatientparfiltre/' + nextPage + '/' + this.state.limit, request, function (data) {
            if (data.statut != 0) {
                return;
            }

            this.setState({
                medecins: data.donnee.liste,
                isFirst: data.donnee.isFirst,
                isLast: data.donnee.isLast,
                page: nextPage,
                sending: false
            });
        }.bind(this));
    }

    filtrer() {
        var request = {
            filtre: this.state.filtre
        }

        HttpPOST('/medicinemanagement-api/recherchermedecinpatientparfiltre/0/' + this.state.limit, request, function (data) {
            if (data.statut != 0) {
                return;
            }

            this.setState({
                medecins: data.donnee.liste,
                isFirst: data.donnee.isFirst,
                isLast: data.donnee.isLast,
                page: 0,
                sending: false
            });
        }.bind(this));
    }

    consulter(p) {
        this.props.history.push('/detailmedecin/' + p.minisite);
    }

    setDateDebut(date) {
        this.setState({
            dateDebut: date
        })
    }

    setDateFin(date) {
        this.setState({
            dateFin: date
        })
    }

    componentDidMount() {
        this.filtrer();
    }

    render(){
        const medecins = this.state.medecins.map((p) =>
            <div className="admin-element" onClick={this.consulter.bind(this, p)}>
                <div onClick={this.consulter.bind(this, p)}>
                    <div style={{ fontSize: "1.2rem" }}>{p.designation}</div>
                    <div style={{ fontSize: "0.9rem" }}>{p.specialite}</div>
                </div>
                <div className="text-right" onClick={this.consulter.bind(this, p)}>
                    <div style={{ fontSize: "1rem" }}>Dernière consultation</div>
                    <div style={{ fontSize: "0.9rem", color: "green" }}>{p.derniereConsultation}</div>
                </div>
            </div>
        )
        return (
            <div>
                <AppNavbar />
                <Container className="account-container">
                    <h2 className="text-center" style={{ paddingTop: '30px', paddingBottom: '25px', color: '#344B7B', textDecoration: 'uppercase' }}>Mon équipe soignante</h2>
                </Container>
                <div className='admin-filter'>
                    <Row noGutters>
                        <Label sm='1'>Filtre :</Label>
                        <Col sm='4'>
                            <Input type='text' name='filtre' value={this.state.filtre} onChange={this.handleChange} placeholder='Nom, Prénoms, Numéro, etc...'></Input>
                        </Col>
                        <Col sm='7' style={{textAlign: 'end'}}>
                            <Button onClick={this.filtrer} disabled={this.state.sending}>
                                Rechercher
                                &nbsp;
                            <Loader style={{ display: this.state.sending ? 'inline' : 'none' }}
                                    type="Puff"
                                    color="#FFFFFF"
                                    height={25}
                                    width={25}
                                />
                            </Button>
                        </Col>
                    </Row>
                </div>
                <div className="admin-container">
                    {medecins}
                    {((this.state.isFirst != null) && (this.state.isLast != null)) &&
                        <Row style={{ margin: '3rem auto 0 auto' }}>
                            <Col style={{ textAlign: 'right', cursor: 'pointer', pointerEvents: this.state.isFirst?'none':'visible' }} onClick={this.rechercher.bind(this, false)}><FontAwesomeIcon icon={faChevronLeft} style={{ fontSize: '0.9rem', marginRight: '0.5rem' }} />Precedent</Col>
                            <Col style={{ cursor: 'pointer', pointerEvents: this.state.isLast?'none':'visible' }} onClick={this.rechercher.bind(this, true)} disabled={this.state.isLast}>Suivant<FontAwesomeIcon icon={faChevronRight} style={{ fontSize: '0.9rem', marginLeft: '0.5rem' }} /></Col>
                        </Row>
                    }
                </div>
            </div>
        )
    }
}