export function estConnecte(){
    var token = localStorage.getItem("accessToken");

    if((token != null) && (token != undefined)){
        return true;
    }

    return false;
}

export function formatDate(date){
    var jour = date.getDate() + "";
    var mois = (date.getMonth()+1) + "";
    var annee = date.getFullYear() + "";

    jour = jour.length<2?"0"+jour:jour;
    mois = mois.length<2?"0"+mois:mois;

    return (jour + '/' + mois + '/' + annee);
}