import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Form, FormGroup, Input, CustomInput, Label, Button } from 'reactstrap';
import Loader from 'react-loader-spinner';
import DatePicker from "react-datepicker";
import AppNavbar from './AppNavbar';

import { HttpGET, HttpPOST } from './service/HttpService.js';
import { formatDate } from './service/UserService.js';

import "react-datepicker/dist/react-datepicker.css";
import './Profil.css';

class Profil extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: localStorage.getItem("username"),
            estConnecte: localStorage.getItem("accessToken"),

            pays: [],
            paysId: 0,

            sending: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.setDateDeNaissance = this.setDateDeNaissance.bind(this);
        this.enregistrerPatient = this.enregistrerPatient.bind(this);
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    setDateDeNaissance(date) {
        this.setState({
            dateDeNaissance: date
        })
    }

    enregistrerPatient() {
        var requete = {
            id: this.state.id,
            nom: this.state.nom,
            prenoms: this.state.prenoms,
            dateDeNaissance: formatDate(this.state.dateDeNaissance),
            sexe: this.state.sexe,
            assuranceId: this.state.assuranceId,
            numeroAssure: this.state.numeroAssure,
            paysId: this.state.paysId,
            profession: this.state.profession,
            residence: this.state.residence
        };

        this.setState({
            sending: true
        });
        HttpPOST('/medicinemanagement-api/enregistrerpatient', requete, function (data) {
            this.setState({
                sending: false
            });
            localStorage.setItem("patient", "true");
            if ((localStorage.getItem("pageSuivante") != null) && (localStorage.getItem("pageSuivante") != undefined)) {
                this.props.history.replace(localStorage.getItem("pageSuivante"));

                localStorage.removeItem("pageSuivante");
            }
            else {
                this.props.history.replace('/compte');
            }
        }.bind(this));

    }

    rechercherPays = () => {
        HttpGET('/medicinemanagement-api/search/rechercherpays', function (data) {

            if (data.statut !== 0) {
                return;
            }

            this.setState({
                paysId: ((data.donnee.length>0) && (this.state.paysId==0))?data.donnee[0].id:this.state.paysId,
                paysDesignation: data.donnee.length>0?data.donnee[0].designation:'',
                pays: data.donnee
            });
        }.bind(this));
    }

    componentDidMount() {
        const { params } = this.props.match;

        if ((params.id == null) || (params.id == undefined)) {
            return;
        }

        HttpGET('/medicinemanagement-api/rechercherpatient/' + params.id, function (data) {
            this.setState({
                id: data.donnee.id,
                nom: data.donnee.nom,
                prenoms: data.donnee.prenoms,
                dateDeNaissance: new Date(data.donnee.dateDeNaissance),
                sexe: data.donnee.sexe,
                assuranceId: data.donnee.assuranceId,
                numeroAssure: data.donnee.numeroAssure,
                paysId: data.donnee.paysId,
                profession: data.donnee.profession,
                residence: data.donnee.residence
            });
        }.bind(this));

        this.rechercherPays();
    }

    render() {
        const pays = this.state.pays.map((e) =>
            <option key={e.id} value={e.id}>{e.designation}</option>
        );

        return (
            <div>
                <AppNavbar />
                <div className="dl-layout-body dl-layout-body-bottom dl-layout-body-size-small">
                    <h2 style={{ textAlign: "center" }}>Modifer le profil</h2>
                    <Form>
                        <FormGroup>
                            <Label>Civilité</Label>
                        </FormGroup>
                        <div style={{ display: "grid", gridTemplateColumns: "auto auto" }}>
                            <FormGroup check>
                                <Label check>
                                    <Input type="radio" name="sexe" value="FEMME" checked={this.state.sexe === "FEMME"} onChange={this.handleChange} />{' '} Madame
                                </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                    <Input type="radio" name="sexe" value="HOMME" checked={this.state.sexe === "HOMME"} onChange={this.handleChange} />{' '} Monsieur
                                </Label>
                            </FormGroup>
                        </div>
                        <FormGroup>
                            <Label>Nom</Label>
                            <Input type="text" name="nom" value={this.state.nom} placeholder="Nom" onChange={this.handleChange} />
                        </FormGroup>
                        <FormGroup>
                            <Label>Prénoms</Label>
                            <Input type="text" name="prenoms" value={this.state.prenoms} placeholder="Prénoms" onChange={this.handleChange} />
                        </FormGroup>
                        <FormGroup>
                            <Label>Date de naissance</Label><br />
                            <DatePicker
                                selected={this.state.dateDeNaissance}
                                onChange={(date) => this.setDateDeNaissance(date)}
                                dateFormat="dd/MM/yyyy"
                                placeholderText="jj/mm/aaaa"
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                className="form-control w-100"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Profession</Label>
                            <Input type="text" name="profession" value={this.state.profession} placeholder="Profession" onChange={this.handleChange} />
                        </FormGroup>
                        <FormGroup>
                            <Label>Nationnalité</Label>
                            <Input type="select" name="paysId" value={this.state.paysId} onChange={this.handleChange}>
                                {pays}
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label>Lieu de residence</Label>
                            <Input type="text" name="residence" value={this.state.residence} placeholder="Résidence" onChange={this.handleChange} />
                        </FormGroup>
                        {/*<div>
                            <h4>Assurance</h4>
                            <FormGroup>
                                <Label>Assurance</Label>
                                <CustomInput type="select" name="assuranceId" id="assuranceId" onChange={this.handleChange}>
                                    <option value="1">Select</option>
                                    <option value="2">ASCOMA</option>
                                    <option value="3">NSIA</option>
                                    <option value="4">Mugefci</option>
                                </CustomInput>
                            </FormGroup>
                            <FormGroup>
                                <Label>Numéro assuré</Label>
                                <Input type="text" name="numeroAssure" placeholder="Numéro assuré" onChange={this.handleChange} />
                            </FormGroup>
                        </div>*/}
                        <FormGroup>
                            <Button className="dl-button-primary" color="primary" size="lg" onClick={this.enregistrerPatient} disabled={this.state.sending} block>
                                ENREGISTRER
                                &nbsp;
                                <Loader style={{ display: this.state.sending ? 'inline' : 'none' }}
                                    type="Puff"
                                    color="#FFFFFF"
                                    height={25}
                                    width={25}
                                />
                            </Button>
                        </FormGroup>
                    </Form>
                </div>
            </div>
        )
    }
}

export default withRouter(Profil);