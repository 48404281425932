import React, { Component } from 'react';
import { faUsers, faUser, faPlayCircle, faClock, faCheckCircle, faUserTimes } from '@fortawesome/free-solid-svg-icons';
import { FormGroup, CustomInput } from 'reactstrap';

import Wizard from './Wizard';
import WizardContent from './WizardContent';
import WizardConsultationPatient from './WizardConsultationPatient';
import WizardConsultationMotif from './WizardConsultationMotif';
import WizardConsultationPrerequis from './WizardConsultationPrerequis';
import WizardConsultationPaiement from './WizardConsultationPaiement';

import { HttpGET, HttpPOST, HttpDELETE } from "./service/HttpService";

export default class WizardConsultation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            patientId: -1,
            patientName: "",
            motifId: 0,
            motifName: "",
            aAccesNecessaire: 0,
            aAccesNecessaireName: '',
            aPaye: 0
        }

        this.choisirPatient = this.choisirPatient.bind(this);
        this.choisirMotif = this.choisirMotif.bind(this);
        this.autoriserMedia = this.autoriserMedia.bind(this);
        this.calculerCoutConsultation = this.calculerCoutConsultation.bind(this);
        this.completeWizard = this.completeWizard.bind(this);
    }

    choisirPatient(p) {
        this.setState({
            patientId: p.id,
            patientName: " : " + p.prenoms + " " + p.nom,
            motifId: -1
        })
    }

    choisirMotif(m) {
        this.setState({
            motifId: m.id,
            motifName: " : " + m.designation,
            aAccesNecessaire: -1
        });

        this.calculerCoutConsultation(m.id);
    }

    autoriserMedia(next) {
        this.setState({
            aAccesNecessaire: next ? true : 0,
            aAccesNecessaireName: " : ok",
            aPaye: next ? -1 : 0
        })
    }

    calculerCoutConsultation(motifId) {
        HttpGET('/medicinemanagement-api/calculercoutconsultation/' + this.state.patientId + '/' + motifId + '/' + this.props.consultationId, function (data) {
            if (data.statut != 0) {
                return;
            }

            this.setState({
                cout: data.donnee.cout,
                devise: data.donnee.devise
            });
        }.bind(this));
    }

    completeWizard(p){
        this.props.onComplete(p);
    }

    componentDidMount() {
    }

    render() {
        return (
            <div className="settings-container">
                <h3 style={{ margin: '30px 0px', color: "#344B7B", textAlign: 'center' }}>Salle d'attente de teleconsultation 2</h3>
                <Wizard>
                    <WizardContent tab="1" title="Patient" icon={faUser} valeur={this.state.patientId} designation={this.state.patientName}>
                        <WizardConsultationPatient onChoose={this.choisirPatient} />
                    </WizardContent>
                    <WizardContent tab="2" title="Motif" icon={faCheckCircle} valeur={this.state.motifId} designation={this.state.motifName}>
                        <WizardConsultationMotif onChoose={this.choisirMotif} />
                    </WizardContent>
                    <WizardContent tab="3" title="Accès necessaires" icon={faPlayCircle} valeur={this.state.aAccesNecessaire} designation={this.state.aAccesNecessaireName}>
                        <WizardConsultationPrerequis media={this.props.media} onAuthorise={this.props.onAuthorise} onChoose={this.autoriserMedia} />
                    </WizardContent>
                    <WizardContent tab="4" title="Accès salle d'attente" icon={faClock} valeur={this.state.aPaye}>
                        <WizardConsultationPaiement onChoose={this.completeWizard} patientId={this.state.patientId} consultationId={this.props.consultationId} motifId={this.state.motifId} cout={this.state.cout} devise={this.state.devise} />
                    </WizardContent>
                </Wizard>
            </div>
        )
    }
}