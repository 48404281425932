import React from "react";
import "./ModalAide.css";

const ModalAide = () => {
  return (
    <div className="modal-content relative">
      <div className="bg-image"></div>
      <div className="blur-bg">
        <h3
          style={{
            color: "#344B7B",
            fontSize: "21px",
            textAlign: "center",
            zIndex: 1,
            marginTop: "16px",
            fontWeight: "bolder"
          }}
        >
          Vous voulez faire une téléconsultation !
        </h3>
        <p className="text-center" style={{ zIndex: 1 }}>
          Comment ça marche ?
        </p>
        <div
          className="d-md-flex justify-content-between"
          style={{ zIndex: 1 }}
        >
          <div className="section w-50 mt-4 d-flex align-items-start">
            <div
              style={{
                width: "30px",
                height: "30px",
                padding: "10px",
                backgroundColor: "#344B7B",
                borderRadius: "100%",
              }}
              className="text-white d-flex align-items-center justify-content-center mr-2"
            >
              1
            </div>
            <div>
              <p className="font-weight-bold">
                Recherchez et choisissez un médecin ou spécialiste de la santé
              </p>
              <p style={{ fontSize: "14px" }}>
                Dans le champ « médecin, établissement, spécialiste », écrivez
                les premières lettres de votre recherche et faites votre choix
                en fonction des résultats qui s'afficheront et cliquez sur
                <span style={{ color: "#f9ba14", fontWeight: "bold" }}>
                  {" "}
                  rechercher
                </span>
                .
              </p>
            </div>
          </div>
          <div className="section w-50 mt-3 d-flex align-items-start">
            <div
              style={{
                width: "30px",
                height: "30px",
                padding: "10px",
                backgroundColor: "#344B7B",
                borderRadius: "100%",
              }}
              className="text-white d-flex align-items-center justify-content-center mr-2"
            >
              2
            </div>
            <div>
              <p className="font-weight-bold">Enregistrement</p>
              <p style={{ fontSize: "14px" }}>
                Une fois sur la page du médecin ou du spécialiste que vous avez
                choisi, cliquez sur «{" "}
                <span style={{ color: "#f9ba14", fontWeight: "bold" }}>
                  se mettre en salle d’attente
                </span>{" "}
                » pour enregistrer votre demande de consultation.
              </p>
            </div>
          </div>
        </div>
        {/* <img src={MedicalMedicine} style={{ position: "absolute", width: "60px", bottom: "100px", right: "20px", zIndex: 0, transform: "rotate(90deg)" }} /> */}
        <div className="d-md-flex justify-content-between">
          <div className="section w-50 mt-3 d-flex align-items-start">
            <div
              style={{
                width: "30px",
                height: "30px",
                padding: "10px",
                backgroundColor: "#344B7B",
                borderRadius: "100%",
              }}
              className="text-white d-flex align-items-center justify-content-center mr-2"
            >
              3
            </div>
            <div>
              <p className="font-weight-bold">
                Inscription ou connexion sur la page
              </p>
              <p style={{ fontSize: "14px" }}>
                Lorsque vous êtes sur la page de connexion, renseignez votre
                mail et mot de passe et cliquez sur{" "}
                <span style={{ color: "#f9ba14", fontWeight: "bold" }}>
                  se connecter
                </span>
                . Si vous n’avez pas encore de compte cliquez sur le text{" "}
                <span
                  style={{
                    color: "#344B7B",
                    fontWeight: "bold",
                    textDecoration: "underline",
                  }}
                >
                  s’inscrire
                </span>{" "}
                puis entrée votre (nom, prénom, mail, mot de passe etc.) et
                cliquez sur
                <span style={{ color: "#f9ba14", fontWeight: "bold" }}>
                  {" "}
                  s’inscrire
                </span>{" "}
                pour passer à la prochaine étape.
              </p>
            </div>
          </div>
          <div className="section w-50 mt-3 d-flex align-items-start">
            <div
              style={{
                width: "30px",
                height: "30px",
                padding: "10px",
                backgroundColor: "#344B7B",
                borderRadius: "100%",
              }}
              className="text-white d-flex align-items-center justify-content-center mr-2"
            >
              4
            </div>
            <div>
              <p className="font-weight-bold">Salle d’attente </p>
              <p style={{ fontSize: "14px" }}>
                Une fois dans la salle d’attente, choisissez votre motif de
                consultation, autorisez l’accès a votre microphone et votre
                camera, ensuite cliquez sur suivant pour{" "}
                <span style={{ color: "#f9ba14", fontWeight: "bold" }}>
                  passer au paiement
                </span>
                .
              </p>
            </div>
          </div>
        </div>
        <div className="mt-3 d-flex align-items-start">
          <div
            style={{
              width: "30px",
              height: "30px",
              padding: "10px",
              backgroundColor: "#344B7B",
              borderRadius: "100%",
            }}
            className="text-white d-flex align-items-center justify-content-center mr-2"
          >
            5
          </div>
          <div>
            <p className="font-weight-bold">Téléchargement ordonnance</p>
            <p style={{ fontSize: "14px" }}>
              Après la clôture de la consultation par le médecin, cliquez sur
              télécharger pour télécharger votre ordonnance
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalAide;
