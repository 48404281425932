import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Form, Button , Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faUser, faPen, faPlus, faLock, faUserTimes, faPhone } from '@fortawesome/free-solid-svg-icons';
import Loader from 'react-loader-spinner';

import AppNavbar from './AppNavbar';
import Setting from './Setting';
import WizardConsultation from './WizardConsultation';
import WebRTC from './WebRTC';

import { HttpGET, HttpPOST, HttpDELETE } from "./service/HttpService";
import { estConnecte } from './service/UserService';

import './Consultation.css';

const mediaStreamConstraints = {
    video: true,
    audio: true
};

class Consultation extends Component {
    constructor(props) {
        super(props);

        console.log(props);

        this.videoRef = React.createRef();
        this.remoteVideoRef = React.createRef();

        const { params } = this.props.match;

        this.state = {
            id: params.id,
            consultationId: '',
            nomMedecin: '',

            localStream: null,
            remoteStream: null,

            modal: false,
            message: null
        }

        this.toggleModal = this.toggleModal.bind(this);

        this.gotLocalMediaStream = this.gotLocalMediaStream.bind(this);
        this.handleLocalMediaStreamError = this.handleLocalMediaStreamError.bind(this);
        this.onRegistrationCompleted = this.onRegistrationCompleted.bind(this);
        this.verifierConsultation = this.verifierConsultation.bind(this);
        this.marquerConsultationEnCours = this.marquerConsultationEnCours.bind(this);
        this.raccrocherConsultation = this.raccrocherConsultation.bind(this);

        this.loadRemoteStream = this.loadRemoteStream.bind(this);
        this.removeRemoteStream = this.removeRemoteStream.bind(this);
        this.hangUp = this.hangUp.bind(this);
        this.finish = this.finish.bind(this);
    }

    toggleModal() {
        this.setState({
            modal: !this.state.modal
        });
    }

    onRegistrationCompleted(p) {
        //localStorage.setItem("consultationId", p.message);
        this.setState({
            message: <div className="consultation-message">
                <Loader
                    className="consultation-loader"
                    type="ThreeDots"
                    height={70}
                    width={70}
                />
            </div>
        });

        this.verifierConsultation();
    }

    verifierConsultation() {
        const { params } = this.props.match;

        if ((params.id == null) || (params.id == undefined)) {
            return;
        }

        //Verifier l'Id de la consultation
        HttpGET('/medicinemanagement-api/verifierstatutconsultation/' + params.id, function (data) {
            if (data.statut === 1) {
                localStorage.setItem('pageSuivante', '/consultation/' + params.id);
                this.props.history.replace('/compte');
                return;
            }

            //Consultation cloturée
            else if (data.statut === 3) {
                this.setState({
                    message: <div className="consultation-message">
                        Cette consultation a été cloturée par votre medecin !!<br />
                        <a href={'/detailconsultation/' + params.id}>Cliquez ici</a> pour consulter le rapport de la consultation et vos prescriptions (Ordonnance, Examens, ...)<br />
                    </div>
                })
                return;
            }

            //Consultation pas payé
            if (data.statut === 2) {
                this.setState({
                    message: <div>
                        <WizardConsultation consultationId={this.state.id} media={this.state.localStream} onAuthorise={this.gotLocalMediaStream} onComplete={this.onRegistrationCompleted} />
                    </div>
                })
                return;
            }

            //Consultation en cours
            else if (data.statut === 0) {
                //Reccuperer le media
                if ((this.state.localStream === undefined) || (this.state.localStream === null)) {
                    try {
                        navigator.mediaDevices.getUserMedia(mediaStreamConstraints)
                            .then((media) => {
                                this.gotLocalMediaStream(media);
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    }
                    catch (e) { }
                }

                //Verifier le nombre de patient dans la salle d'attente
                const message = data.donnee;

                this.setState({
                    roomId: params.id,
                    medecinToken: message.medecinToken,
                    nomMedecin: message.nomMedecin
                });

                if (message.nombrePatientAvant > 0) {
                    this.setState({
                        message: <div className="consultation-message">
                            Bienvenue dans la salle d'attente virtuelle de {message.nomMedecin} !!!<br />
                            Vous avez {message.nombrePatientAvant} patients avant vous dans la file d'attente.<br />

                            Restez en alerte!!!
                            Vous serez notifié <b>par e-mail</b> lorsque ce sera votre tour.<br />
                            Email: {message.emailClient}
                            <br />
                            <br />
                            <a href='/dossier/consultationsencours'>Cliquez ici</a> pour fermer cette fenetre.<br />
                            <Loader
                                className="consultation-loader"
                                type="ThreeDots"
                                height={70}
                                width={70}
                            />
                        </div>
                    });
                }
                else {
                    this.setState({
                        message: <div className="consultation-message">
                            Bienvenue dans la salle d'attente virtuelle de {message.nomMedecin} !!!<br />
                            Patientez pendant que {message.nomMedecin} vous rejoint<br />
                            <Loader
                                className="consultation-loader"
                                type="ThreeDots"
                                height={70}
                                width={70}
                            />
                        </div>
                    });
                }

                return;
            }
        }.bind(this),
            function (data) {
                localStorage.setItem('pageSuivante', '/consultation/' + params.id);
                this.props.history.push('/identification');
            }.bind(this));
    }

    // Handles success by adding the MediaStream to the video element.
    gotLocalMediaStream(mediaStream) {
        if ((this.state.localStream !== undefined) && (this.state.localStream !== null)) {
            return;
        }

        this.setState({
            localStream: mediaStream,
        })

        this.videoRef.current.srcObject = this.state.localStream;
    }

    handleLocalMediaStreamError(error) {
        console.log('navigator.getUserMedia error: ', error);
    }

    loadRemoteStream(stream) {
        this.setState({
            remoteStream: stream
        });
        this.videoRef.current.srcObject = stream;
        this.remoteVideoRef.current.srcObject = this.state.localStream;

        //Notifier connexion video
        this.marquerConsultationEnCours();
    }

    removeRemoteStream() {
        this.setState({
            remoteStream: null
        });
        this.videoRef.current.srcObject = this.state.localStream;

        this.setState({
            message: <div className="consultation-message">
                Tentative de reconnexion<br />
                <Loader
                    className="consultation-loader"
                    type="ThreeDots"
                    height={70}
                    width={70}
                />
            </div>
        });
    }

    hangUp() {
        this.setState({
            remoteStream: null
        });
        this.videoRef.current.srcObject = this.state.localStream;

        this.setState({
            message: <div className="consultation-message">
                La consultation a été interrompue par votre medecin !!<br />
                <a href={'/'}>Cliquez ici</a> pour revenir à la page d'accueil<br />
            </div>
        })
    }

    finish() {
        const { params } = this.props.match;

        this.setState({
            remoteStream: null
        });
        this.videoRef.current.srcObject = this.state.localStream;

        this.setState({
            message: <div className="consultation-message">
                La consultation a été cloturée par votre medecin !!<br />
                <a href={'/detailconsultation/' + params.id}>Cliquez ici</a> pour consulter le rapport de la consultation et vos prescriptions (Ordonnance, Examens, ...)<br />
            </div>
        })
    }

    marquerConsultationEnCours() {
        HttpPOST('/medicinemanagement-api/marquerconsultationencours/' + this.state.id, {}, (data) => {
            if (data.statut === 0) {
            }
        });
    }

    raccrocherConsultation() {
        const { params } = this.props.match;

        var message = {
            from: localStorage.getItem('username'),
            to: this.state.medecinToken,
            roomId: params.id,
            event: "hangup",
            text: JSON.stringify({event: "hangup"})
        };
        HttpPOST('/medicinemanagement-api/notifierinterlocuteur/' + params.id, message, () => { });
        this.props.history.replace('/dossier/consultationsencours');
    }

    componentDidMount() {
        const { params } = this.props.match;

        if ((params.id == null) || (params.id == undefined)) {
            return;
        }

        this.setState({
            id: params.id
        });

        if (!estConnecte()) {
            localStorage.setItem('pageSuivante', '/consultation/' + params.id);
            this.props.history.push('/identification');
            return;
        }

        if (!(localStorage.getItem("patient") == "true")) {
            localStorage.setItem('pageSuivante', '/consultation/' + params.id);
            this.props.history.replace('/profil');
        }

        this.verifierConsultation();
    }

    componentWillUnmount() {
        try {
            if ((this.videoRef.current.srcObject == null) || (this.videoRef.current.srcObject == undefined)) {
                return;
            }

            this.videoRef.current.srcObject.getTracks().forEach(function (track) {
                track.stop();
            });

            this.localStream = null;
        }
        catch (e) { }

        try {
            if ((this.remoteVideoRef.current.srcObject == null) || (this.remoteVideoRef.current.srcObject == undefined)) {
                return;
            }

            this.remoteVideoRef.current.srcObject.getTracks().forEach(function (track) {
                track.stop();
            });

            this.remoteStream = null;
        }
        catch (e) { }
    }

    render() {
        return (
            <div>
                <AppNavbar />
                <div className="consultation-container">
                    <video ref={this.videoRef} className="backgroundvideo" autoPlay muted={this.state.remoteStream == null} playsInline></video>

                    {this.state.remoteStream ?
                        <Container className="consultation-remote-video-container" style={{ color: 'black' }}>
                            <div className="remote_video">
                                <div></div>
                                <div></div>
                                <video ref={this.remoteVideoRef} autoPlay muted playsInline></video>
                            </div>
                        </Container>
                        :
                        <Container className="consultation-settings-container" style={{ color: 'black' }}>
                            {this.state.message}
                        </Container>
                    }

                    <Container className="consultation-remote-video-container" style={{ color: 'black' }}>
                        <div className="consultation-bouton-terminer" onClick={this.toggleModal}>
                            <FontAwesomeIcon icon={faPhone} style={{ transform: 'rotate(225deg)', verticalAlign: 'middle', marginRight: '0.2rem' }} /> Raccrocher
                        </div>
                    </Container>
                </div>
                {/*<div class="cookie_banner" style={{ display: "none" }}>
                    <img src="/static/images/cookies.svg" alt="illuscookies" />
                    <p>En poursuivant, vous acceptez l'utilisation de cookies qui permettront l'utilisation de vos données par Maiia, afin de nous permettre d'améliorer la navigation sur le site. Pour en savoir plus, consultez <a href="/mentions-legales">notre charte cookies</a></p>
                    <svg viewBox="0 0 24 24" width="20" height="20" name="close" class="btn icon" fill=""><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>
                </div>*/}

                {/* Raccrocher l'appel vidéo */}
                <Modal isOpen={this.state.modal} toggle={this.toggleModal}>
                    <ModalHeader toggle={this.toggleModal}>Attention</ModalHeader>
                    <ModalBody>
                        <p>
                            Vous allez <b>raccrocher l'appel vidéo avec {this.state.nomMedecin}</b>.<br />
                            Cette action <b>ne va pas</b> cloturer la consultation. Elle sera encore <b>disponible dans votre dossier consultations en cours</b>.<br />
                        </p>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.raccrocherConsultation}>Raccrocher</Button>{' '}
                        <Button color="secondary" onClick={this.toggleModal}>Annuler</Button>
                    </ModalFooter>
                </Modal>

                {this.state.localStream &&
                    <WebRTC roomId={this.state.roomId} to={this.state.medecinToken} stream={this.state.localStream} onLoadRemoteStream={this.loadRemoteStream} onDisconnectRemote={this.removeRemoteStream} onHangUp={this.hangUp} onFinish={this.finish} />
                }
            </div>
        )
    }
}

export default withRouter(Consultation);