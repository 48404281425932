import React, { Component } from "react";
import "./App.css";
import AppNavbar from "./AppNavbar";
import Header from "./Header";
import {
  Button,
  Container,
  Row,
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  NavLink,
} from "reactstrap";
import BarreDeRecherche from "./BarreDeRecherche";

import "./Home.css";

import teleconsultation from "./assets/img/teleconsultation.png";
import document from "./assets/img/document.png";
import prelevement from "./assets/img/prelevement.png";
import kitsante from "./assets/img/kitsante.png";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import ModalAide from "./ModalAide";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      modalOpen: false,
      suggestion: "",
    };

    this.onSuggestionChange = this.onSuggestionChange.bind(this);
    this.rechercher = this.rechercher.bind(this);
    this.afficherMedecin = this.afficherMedecin.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({ modalOpen: true });
  }

  closeModal() {
    this.setState({ modalOpen: false });
  }

  onSuggestionChange(suggestion) {
    this.setState({
      suggestion: suggestion,
    });
  }

  componentDidMount() {
    if (localStorage.getItem("initialHomeView")) {
      this.setState({ modalOpen: false });
    } else {
      this.setState({ modalOpen: true });
      localStorage.setItem("initialHomeView", true);
    }
  }

  rechercher(e) {
    e.preventDefault();
    if (this.state.suggestion == "") {
      return;
    }
    this.props.history.push(
      "/listeresultatderecherche/" + this.state.suggestion
    );
  }

  afficherMedecin(minisite) {
    this.props.history.push("/detailmedecin/" + minisite);
  }

  afficherEtablissement(minisite) {
    this.props.history.push("/detailetablissement/" + minisite);
  }

  render() {
    return (
      <div>
        <AppNavbar />
        <Header />
        <div>
          <section
            class="testimonials text-center"
            style={{ backgroundColor: "#FFFFFF", paddingTop: "3rem" }}
          >
            <div class="container">
              <Modal
                open={this.state.modalOpen}
                onClose={this.closeModal}
                aria-labelledby="fenetre-tutoriel"
                aria-describedby="Fenetre de tutorial"
                center
              >
                <ModalAide />
              </Modal>
              <h2 class="mb-5">Retrouvez-les sur SKANMED</h2>
              <div class="row">
                <div class="col-lg-4">
                  {/*<NavLink href='/detailmedecin/kone-kafana'>
                    <div class="testimonial-item mx-auto mb-5 mb-lg-0">
                      <img class="img-fluid rounded-circle mb-3" src={require('./assets/img/kone.jpg')} alt="" />
                      <h5>Dr KONE Kafana</h5>
                      <p class="font-weight-light mb-0">Medecin generaliste <br /> Centre medical Konos</p>
                    </div>
                  </NavLink>*/}
                  <NavLink href="/listeresultatderecherche/Medecine%20generale">
                    <div class="testimonial-item mx-auto mb-5 mb-lg-0">
                      <img
                        class="img-fluid rounded-circle mb-3"
                        height="5em"
                        src={require("./assets/img/medecine_generale_2.jpg")}
                        alt=""
                      />
                      <h5>MEDECINE GENERALE</h5>
                      <p class="font-weight-light mb-0">
                        Cliquez pour afficher tous les médécins généralistes
                        disponibles
                      </p>
                    </div>
                  </NavLink>
                </div>
                <div class="col-lg-4">
                  <NavLink href="/listeresultatderecherche/Cardiologie">
                    <div class="testimonial-item mx-auto mb-5 mb-lg-0">
                      <img
                        class="img-fluid rounded-circle mb-3"
                        height="5em"
                        src={require("./assets/img/cardiologie2.jpg")}
                        alt=""
                      />
                      <h5>CARDIOLOGIE</h5>
                      <p class="font-weight-light mb-0">
                        Cliquez pour afficher tous les médécins cardiologues
                        disponibles
                      </p>
                    </div>
                  </NavLink>
                </div>
                <div class="col-lg-4">
                  <NavLink href="/listeresultatderecherche/Pediatrie">
                    <div class="testimonial-item mx-auto mb-5 mb-lg-0">
                      <img
                        class="img-fluid rounded-circle mb-3"
                        height="5em"
                        src={require("./assets/img/pediatrie_3.jpg")}
                        alt=""
                      />
                      <h5>PEDIATRIE</h5>
                      <p class="font-weight-light mb-0">
                        Cliquez pour afficher tous les médécins pédiatres
                        disponibles
                      </p>
                    </div>
                  </NavLink>
                </div>
              </div>
            </div>
          </section>

          <section class="showcase">
            <div class="container-fluid p-0">
              <div class="row no-gutters" style={{ backgroundColor: "#CCC6" }}>
                <div
                  class="col-lg-6 order-lg-2 text-white showcase-img"
                  style={{
                    backgroundImage: `url(${teleconsultation})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                  }}
                ></div>
                <div class="col-lg-6 order-lg-1 my-auto showcase-text">
                  <h2>Votre téléconsultation à tout moment</h2>
                  <p class="lead mb-0">
                    Besoin d'un diagnostic rapidement ? Sans vous déplacer ?{" "}
                    <br /> Nos praticiens sont disponibles tout de suite pour
                    une consultation vidéo.
                  </p>
                </div>
              </div>
              <div class="row no-gutters" style={{ backgroundColor: "#FFFF" }}>
                <div
                  class="col-lg-6 text-white showcase-img"
                  style={{
                    backgroundImage: `url(${document})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                    backgroundColor: "#FFF",
                  }}
                ></div>
                <div class="col-lg-6 my-auto showcase-text">
                  <h2>Vos documents de santé, toujours avec vous</h2>
                  <p class="lead mb-0">
                    Conservez vos ordonnances, résultats d'examen, certificats
                    dans un environnement sécurisé. Partagez-les avec vos
                    praticiens quand vous le souhaitez.
                  </p>
                </div>
              </div>
              <div
                id="kit_sante"
                class="row no-gutters"
                style={{ backgroundColor: "#CCC6" }}
              >
                <div
                  class="col-lg-6 order-lg-2 text-white showcase-img"
                  style={{
                    backgroundImage: `url(${kitsante})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                  }}
                ></div>
                <div class="col-lg-6 order-lg-1 my-auto showcase-text">
                  <h2>Votre kit de santé, pour surveiller vos constantes</h2>
                  <p class="lead mb-0">
                    Votre kit est composé d'un tensiomètre électronique, d'un
                    thermomètre éléctronique, d'un thermomètre à mercure et
                    d'une balance électronique. <br />
                    <br />
                    <a href="/commandeprestation/KIT_SANTE">Commander un kit</a>
                  </p>
                </div>
              </div>
              <div class="row no-gutters" style={{ backgroundColor: "#FFFF" }}>
                <div
                  class="col-lg-6 text-white showcase-img"
                  style={{
                    backgroundImage: `url(${prelevement})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                    backgroundColor: "#FFF",
                  }}
                ></div>
                <div class="col-lg-6 my-auto showcase-text">
                  <h2>Vos examens de santé, sans vous déplacer</h2>
                  <p class="lead mb-0">
                    Faites vous prélever pour vos examens de santé depuis
                    n'importe quel endroit, SkanMed partage vos résultats à
                    votre praticien.
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section
            class="call-to-action text-white text-center"
            style={{ paddingTop: "4rem", paddingBottom: "4rem" }}
          >
            <div class="overlay"></div>
            <div class="container">
              <div class="row">
                <div class="col-xl-9 mx-auto">
                  <h2 class="mb-4" style={{ color: "white" }}>
                    Téléconsultez maintenant
                  </h2>
                </div>
                <div class="col-md-10 col-lg-8 col-xl-7 mx-auto">
                  <InputGroup>
                    <BarreDeRecherche
                      onSuggestionChange={this.onSuggestionChange}
                      className="searchbar-input form-control"
                    />
                    <InputGroupAddon addonType="append">
                      <Button
                        className="search-button"
                        style={{
                          padding: "0",
                          height: "50px",
                          width: "180px",
                          textAlign: "center",
                          verticalAlign: "center",
                          fontWeight: "bold",
                          fontSize: "0.9rem",
                        }}
                        color="secondary"
                        onClick={this.rechercher}
                      >
                        Rechercher
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                </div>
              </div>
            </div>
          </section>

          <footer class="footer bg-light">
            <div class="container">
              <div class="row">
                <div class="col-lg-6 h-100 text-center text-lg-left my-auto">
                  <ul class="list-inline mb-2">
                    <li class="list-inline-item">
                      <a href="#">A propos</a>
                    </li>
                    <li class="list-inline-item">&sdot;</li>
                    <li class="list-inline-item">
                      <a href="#">Contacts</a>
                    </li>
                    <li class="list-inline-item">&sdot;</li>
                    <li class="list-inline-item">
                      <a href="/conditions/conditionsdutilisation">
                        Conditions d'utilisation
                      </a>
                    </li>
                    <li class="list-inline-item">&sdot;</li>
                    <li class="list-inline-item">
                      <a href="/conditions/confidentialite">Confidentialité</a>
                    </li>
                  </ul>
                  <p class="text-muted small mb-4 mb-lg-0">
                    Copyright &copy; 2020. SKAN TECHNOLOGIES.
                  </p>
                </div>
                <div class="col-lg-6 h-100 text-center text-lg-right my-auto">
                  <ul class="list-inline mb-0">
                    <li class="list-inline-item mr-3">
                      <a href="https://facebook.com/skanmed">
                        <i class="fab fa-facebook fa-2x fa-fw"></i>
                      </a>
                    </li>
                    <li class="list-inline-item mr-3">
                      <a href="#">
                        <i class="fab fa-twitter-square fa-2x fa-fw"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a href="#">
                        <i class="fab fa-instagram fa-2x fa-fw"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default Home;
