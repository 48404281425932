import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';

import './ChampDeRecherche.css';
import avatar from './assets/img/default_doctor_avatar_m.png';

import { HttpGET } from './service/HttpService';

export default class ChampDeRecherche extends Component {
  constructor() {
    super();

    // Autosuggest is a controlled component.
    // This means that you need to provide an input value
    // and an onChange handler that updates this value (see below).
    // Suggestions also need to be provided to the Autosuggest,
    // and they are initially empty because the Autosuggest is closed.
    this.state = {
      value: '',
      suggestions: []
    };

    this.getSuggestionValue = this.getSuggestionValue.bind(this);
  }

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  getSuggestionValue (suggestion){
    this.props.onSuggestionChosen(suggestion);

    return suggestion.designation;
  }

  // Use your imagination to render suggestions.
  renderSuggestion = suggestion => {
    return(
      <div>
        {suggestion.designation}
      </div>
    )
    
  };

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    });

    this.props.onSuggestionChange(newValue);
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {

    HttpGET(this.props.url + value, function (data) {
      this.setState({
        suggestions: data
      });
    }.bind(this));
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  render() {
    const { value, suggestions } = this.state;

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: this.props.placeholder,
      value: this.props.suggestionValue,
      onChange: this.onChange
    };

    // Finally, render it!
    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        inputProps={inputProps}
      />
    );
  }
}