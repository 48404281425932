import React, { Component } from 'react';
import { Container, Row, Col, Input, Button, Breadcrumb, BreadcrumbItem, Form, FormGroup, Label, FormFeedback } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import Loader from 'react-loader-spinner';
import AppNavbar from './AppNavbar';

import { PaiementContext } from './context';

import { HttpGET, HttpPOST, HttpDELETE, baseUrl } from './service/HttpService';

export default class CommandeAdresse extends Component {
    static contextType = PaiementContext;

    constructor(props) {
        super(props);

        this.state = {
            pays: [],
            villes: [],
            communes: []
        }

        this.continuer = this.continuer.bind(this);
        this.rechercherPays = this.rechercherPays.bind(this);
        this.rechercherVilles = this.rechercherVilles.bind(this);
        this.rechercherCommunes = this.rechercherCommunes.bind(this);
        this.handlePaysChange = this.handlePaysChange.bind(this);
        this.handleVilleChange = this.handleVilleChange.bind(this);
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handlePaysChange = event => {
        //console.log(event.nativeEvent.target[event.nativeEvent.target.selectedIndex].text)
        this.setState({
            [event.target.name]: event.target.value,
            paysDesignation: event.nativeEvent.target[event.nativeEvent.target.selectedIndex].text

        });
        this.rechercherVilles(event.target.value);
    }

    handleVilleChange = event => {
        this.setState({
            [event.target.name]: event.target.value,
            villeDesignation: event.nativeEvent.target[event.nativeEvent.target.selectedIndex].text
        });
        this.rechercherCommunes(event.target.value);
    }

    continuer() {
        let estValide = this.context.modifierAdresse(this.state);
        if(estValide){
            this.props.history.push('/commandeprestation/paiement/' + this.context.articleCode);
        }
    }

    rechercherPays(){
        HttpGET('/medicinemanagement-api/search/rechercherpays', function (data) {

            if (data.statut !== 0) {
                return;
            }

            this.setState({
                paysId: data.donnee.length>0?data.donnee[0].id:0,
                paysDesignation: data.donnee.length>0?data.donnee[0].designation:'',
                pays: data.donnee
            });

            this.rechercherVilles(this.state.paysId);
        }.bind(this));
    }

    rechercherVilles(paysId){
        HttpGET('/medicinemanagement-api/search/recherchervilles/' + paysId, function (data) {

            if (data.statut !== 0) {
                return;
            }

            this.setState({
                villeId: data.donnee.length>0?data.donnee[0].id:0,
                villeDesignation: data.donnee.length>0?data.donnee[0].designation:'',
                villes: data.donnee
            });

            this.rechercherCommunes(this.state.villeId);
        }.bind(this));
    }

    rechercherCommunes(villeId){
        HttpGET('/medicinemanagement-api/search/recherchercommunes/' + villeId, function (data) {

            if (data.statut !== 0) {
                return;
            }

            this.setState({
                communeId: data.donnee.length>0?data.donnee[0].id:0,
                communeDesignation: data.donnee.length>0?data.donnee[0].designation:'',
                communes: data.donnee
            });
        }.bind(this));
    }

    componentDidMount() {
        if(this.context.localisationsChargees){
            this.setState({
                nom: this.context.nom,
                prenoms: this.context.prenoms,
                telephone: this.context.telephone,
                email: this.context.email,
                villeId: this.context.villeId,
                communeId: this.context.communeId,
                adresse: this.context.adresse,
                pays: this.context.pays,
                villes: this.context.villes,
                communes: this.context.communes
            });
        }
        else{
            this.rechercherPays();
        }
    }

    render() {
        const pays = this.state.pays.map((e) =>
            <option key={e.id} value={e.id}>{e.designation}</option>
        );

        const villes = this.state.villes.map((e) =>
            <option key={e.id} value={e.id}>{e.designation}</option>
        );

        const communes = this.state.communes.map((e) =>
            <option key={e.id} value={e.id}>{e.designation}</option>
        );

        return (
            <PaiementContext.Consumer>
                {({ articleCode, quantite, nom, prenoms, telephone, email, villeId, communeId, adresse, typePaiement, operateur, token, numero, modifierPanier, modifierAdresse, commander }) => (
                    <div>
                        <AppNavbar />
                        <Container style={{ marginTop: '30px', marginBottom: '30px' }}>
                            <Breadcrumb>
                                <BreadcrumbItem>Panier</BreadcrumbItem>
                                <BreadcrumbItem active>Adresse</BreadcrumbItem>
                                <BreadcrumbItem>Paiement</BreadcrumbItem>
                            </Breadcrumb>
                            <Row form>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="nom">Nom</Label>
                                        <Input invalid={!this.state.nom} type="text" name="nom" value={this.state.nom} onChange={this.handleChange} />
                                        <FormFeedback invalid={!this.state.nom}>Ce champ est obligatoire</FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="prenoms">Prénoms</Label>
                                        <Input invalid={!this.state.prenoms} type="text" name="prenoms" value={this.state.prenoms} onChange={this.handleChange} />
                                        <FormFeedback invalid={!this.state.prenoms}>Ce champ est obligatoire</FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <FormGroup>
                                <Label for="telephone">Téléphone</Label>
                                <Input invalid={!this.state.telephone} type="text" name="telephone" value={this.state.telephone} onChange={this.handleChange} />
                                <FormFeedback invalid={!this.state.telephone}>Ce champ est obligatoire</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label for="email">Email</Label>
                                <Input invalid={!this.state.email} type="text" name="email" value={this.state.email} onChange={this.handleChange} />
                                <FormFeedback invalid={!this.state.email}>Ce champ est obligatoire</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label>Pays</Label>
                                <Input type="select" name="paysId" value={this.state.paysId} onChange={this.handlePaysChange}>
                                    {pays}
                                </Input>
                            </FormGroup>
                            <Row form>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>Ville</Label>
                                        <Input type="select" name="villeId" value={this.state.villeId} onChange={this.handleVilleChange}>
                                            {villes}
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>Commune</Label>
                                        <Input type="select" name="communeId" value={this.state.communeId} onChange={this.handleChange}>
                                            {communes}
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <FormGroup>
                                <Label>Adresse</Label>
                                <Input type="textarea" placeholder="Rue / Appartement / Lot / Batiment" name="adresse" value={this.state.adresse} onChange={this.handleChange} />
                            </FormGroup>
                            <Row xs='1' sm='2'>
                                <Col>
                                    <span onClick={this.props.history.goBack} style={{ cursor: 'pointer' }}>
                                        <FontAwesomeIcon icon={faChevronLeft} style={{ fontSize: "0.9rem", marginRight: '0.5rem' }} />Retour
                                    </span>
                                </Col>
                                <Col style={{textAlign: 'right'}}>
                                    <Button className='action-button' onClick={this.continuer} disabled={this.state.sending}>
                                        CONTINUER
                                        &nbsp;
                                        <Loader style={{ display: this.state.sending ? 'inline' : 'none' }}
                                            type="Puff"
                                            color="#FFFFFF"
                                            height={25}
                                            width={25}
                                        />
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                )}
            </PaiementContext.Consumer>
        )
    }
}