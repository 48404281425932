import React, { Component } from 'react';
import { Card, CardImg, CardBody, CardTitle, CardSubtitle, CardText, Button, Row, Col } from 'reactstrap';

import AppNavbar from './AppNavbar';

import './PrestationADomicile.css';

import { HttpGET, HttpPOST, HttpDELETE, baseUrl } from './service/HttpService';

import iconeconsultation from './assets/img/iconeconsultation.png';
import iconeprelevement from './assets/img/iconeprelevement.png';
import iconehospitalisation from './assets/img/iconehospitalisation.png';
import iconehospitalisationavecassistance from './assets/img/iconehosptitalisationavecassistance.png';
import iconepansement from './assets/img/iconepansement.png';
import iconeinjection from './assets/img/iconeinjection.png';
import iconeecg from './assets/img/iconeecg.png';
import iconeechographie from './assets/img/iconeechographie.png';
import iconeechographiemarternelle from './assets/img/iconeechographiemarternelle.png';

export default class PrestationADomicile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            prestations: []
        }
    }

    componentDidMount() {
        HttpGET('/medicinemanagement-api/search/rechercherprestations/SERVICE', function (data) {

            if (data.statut !== 0) {
                return;
            }

            this.setState({
                prestations: data.donnee
            });
        }.bind(this));
    }

    render() {
        const prestations = this.state.prestations.map((p) =>
            <Col>
                <a href={"/commandeprestation/" + p.code} style={{ decoration: 'none' }}>
                    <Card className='prestation-card'>
                        <CardBody>
                            <img src={baseUrl + '/medicinemanagement-api/fichier/' + p.photoId} />
                            {/*<CardTitle>Card title</CardTitle>
                                    <CardSubtitle>Card subtitle</CardSubtitle>*/}
                            <CardText><h5>{p.designation} <br />à domicile</h5></CardText>
                        </CardBody>
                    </Card>
                </a>
            </Col>
        )

        return (
            <div>
                <AppNavbar />
                <h2 className="text-center" style={{ paddingTop: '20px', color: '#344B7B', textDecoration: 'uppercase' }}>Prestations à domicile</h2>
                <div className='prestation-container'>
                    <h1 style={{ display: 'block' }}>Notre équipe de médécins et d'infirmiers est disponible 24/7 pour vous apporter des soins à domicile</h1>
                    <Row xs='1' sm='3'>
                        {prestations}
                    </Row>
                </div>
            </div>
        )
    }
}