import React, { Component } from 'react';
import { Container, Row, Col, Label, Input, Button, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faPen, faPlus, faLock, faTimes, faChevronLeft, faChevronRight, faFileDownload } from '@fortawesome/free-solid-svg-icons';
import Loader from 'react-loader-spinner';

import './Documents.css';

import axios from 'axios';
import { HttpGET, HttpPOST, HttpDELETE, baseUrl } from './service/HttpService';

export default class Documents extends Component {
    constructor(props) {
        super(props);

        this.state = {
            caracteristiqueId: 0,
            typeEtablissements: [],
            documents: [],
            patients: [],
            patientId: 0,
            fichiers: [],
            fichiersIds: [],
            documentPatientId: 0,
            id: 0,
            description: '',
            
            page: 0,
            limit: 4,
            sending: false,
            filtre: '',

            isFirst: null,
            isLast: null
        }

        this.handleChange = this.handleChange.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.toggleModalSuppression = this.toggleModalSuppression.bind(this);
        this.afficherModal = this.afficherModal.bind(this);
        this.rechercher = this.rechercher.bind(this);
        this.filtrer = this.filtrer.bind(this);
        this.recharger = this.recharger.bind(this);
        this.consulter = this.consulter.bind(this);
        this.enregistrer = this.enregistrer.bind(this);
        this.supprimer = this.supprimer.bind(this);
        this.afficherModifier = this.afficherModifier.bind(this);
        this.afficherModifierSupports = this.afficherModifierSupports.bind(this);
    }

    handleChange = event => {
        const target = event.target;
        const value = (target.name === 'aAbonnement') ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleFichiersChange = event => {
        this.setState({
            fichiers: event.target.files
        })
    }

    toggleModal() {
        this.setState({
            modal: !this.state.modal
        });
    }

    afficherModal(modalName) {
        this.setState({
            [modalName]: !this.state[modalName]
        })
    }

    toggleModalSuppression() {
        this.setState({
            modalSuppression: !this.state.modalSuppression
        });
    }

    rechercher(next) {
        this.setState({
            sending: true
        })
        var request = {
            filtre: this.state.filtre
        }

        var nextPage = next ? this.state.page + 1 : this.state.page - 1;

        HttpPOST('/medicinemanagement-api/rechercherdocumentspatientparfiltre/' + nextPage + '/' + this.state.limit, request, function (data) {
            if (data.statut != 0) {
                return;
            }

            this.setState({
                documents: data.donnee.liste,
                isFirst: data.donnee.isFirst,
                isLast: data.donnee.isLast,
                page: nextPage,
                sending: false
            });
        }.bind(this));
    }

    filtrer() {
        var request = {
            filtre: this.state.filtre
        }

        HttpPOST('/medicinemanagement-api/rechercherdocumentspatientparfiltre/0/' + this.state.limit, request, function (data) {
            if (data.statut != 0) {
                return;
            }

            this.setState({
                documents: data.donnee.liste,
                isFirst: data.donnee.isFirst,
                isLast: data.donnee.isLast,
                page: 0,
                sending: false
            });
        }.bind(this));
    }

    recharger() {
        var request = {
            filtre: this.state.filtre
        }

        HttpPOST('/medicinemanagement-api/rechercherdocumentspatientparfiltre/' + this.state.page + '/' + this.state.limit, request, function (data) {
            if (data.statut != 0) {
                return;
            }

            if((data.donnee.liste.length <= 0) && (this.state.page > 0)){
                this.setState({
                    page : this.state.page - 1
                })
                this.recharger();
                return;
            }

            this.setState({
                documents: data.donnee.liste,
                isFirst: data.donnee.isFirst,
                isLast: data.donnee.isLast,
                sending: false
            });
        }.bind(this));
    }

    rechercherPatients = () => {
        HttpGET('/medicinemanagement-api/rechercherpatients', function (data) {
            this.setState({
                patients: data.donnee
            });
            if(data.donnee.length > 0){
                this.setState({
                    patientId: data.donnee[0].id
                });
            }
        }.bind(this));
    }

    consulter(e) {
        this.props.history.push('/etablissement/' + e.id);
    }

    afficherModifier(e) {
        this.setState({
            id: e.id,
            designation: e.designation,
            caracteristiqueId: e.caracteristiqueId,

            modal: true
        });
    }

    enregistrer = () => {
        this.setState({
            sending: true
        });

        const data = new FormData();
        data.append('documentPatientId', this.state.documentPatientId);
        data.append('patientId', this.state.patientId);
        data.append("description", this.state.description);

        for(var i in this.state.fichiers){
            data.append("fichiers", this.state.fichiers[i]);
        }
        

        axios.post(baseUrl + '/medicinemanagement-api/enregistrerdocumentpatient', data,
            {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem("accessToken")
                }
            }
        ).then((response) => {
            this.setState({
                sending: false,
            });

            if (response.data.statut != 0) {
                this.setState({
                    messageErreur: response.data.donnee
                })
                return;
            }

            this.recharger();

            this.setState({
                documentPatientId: 0,
                patientId: 0,
                description: '',
                fichiers: [],

                modal: false
            })
        }).catch((error) => {
            this.setState({
                sending: false,
                message: (error.response!==null) && (error.response!==undefined)?error.response.data:""
            });
        });
    }

    afficherSupprimer(e) {
        this.setState({
            modalSuppression: true,
            id: e.id,
            titre: 'Supprimer un document',
            message: 'Souhaitez-vous supprimer tous les fichiers liés au document ' + e.description
        });
    }

    supprimer() {
        this.setState({
            sending: true
        });
        HttpDELETE('/medicinemanagement-api/supprimerdocumentpatient/' + this.state.id,
            (data) => {
                if (data.statut != 0) {
                    return;
                }

                this.recharger();

                this.setState({
                    sending: false,
                    modalSuppression: false,

                    id: 0
                });
            });
    }

    afficherModifierSupports(e){
        this.setState({
            'id': e.id,
            'designation': e.designation
        });
        this.afficherModal('modalSupports');    
    }

    afficherFichiers = (e) => {
        HttpGET('/medicinemanagement-api/rechercherfichiersdocumentspatient/' + e.id, function (data) {
            this.setState({
                fichiersIds: data.donnee
            });

            this.afficherModal('modalFichiers');    
        }.bind(this));
    }

    ouvrirFichier = (fichierId) => {
        this.setState({
            fichierId: fichierId
        });

        this.afficherModal('modalFichier'); 
    }

    telechargerFichier = (fichierId) => {
        document.location = baseUrl + '/medicinemanagement-api/telechargerfichier/' + fichierId + '?stomp-jwt-token=' + localStorage.getItem("accessToken");
    }

    enregistrerMinisite() {
        this.setState({
            sending: true
        })
        var request = {
            id: this.state.id,
            minisite: this.state.minisite
        }

        HttpPOST('/medicinemanagement-api/enregistrerminisite', request, function (data) {
            if (data.statut != 0) {
                return;
            }

            this.setState({
                id: 0,
                minisite: data.donnee,
                sending: false,
                modalMinisite: false
            });

            this.recharger();
        }.bind(this));
    }

    componentDidMount() {
        this.filtrer();
        this.rechercherPatients();

    }

    render() {
        const patients = this.state.patients.map((e) =>
            <option key={e.id} value={e.id}>{e.nom + " " + e.prenoms}</option>
        );

        const documents = this.state.documents.map((e) =>
            <div key={e.id} className="admin-element">
                <div>
                    <div style={{ fontSize: "1.2rem" }}>{e.description}</div>
                    <div style={{ fontSize: "0.9rem" }}>{'Date: ' + e.date + ' | Patient: ' + e.patient}</div>
                </div>
                <div className="text-right">
                    <div style={{ fontSize: "1rem" }}><span onClick={this.afficherFichiers.bind(this, e)}>fichiers</span> &bull; <span onClick={this.afficherModifierSupports.bind(this, e)}>modifier</span></div>
                    <div style={{ fontSize: "1rem", color: "red" }}><span onClick={this.afficherSupprimer.bind(this, e)}>supprimer</span></div>
                    <div style={{ fontSize: "1rem", color: "#344B7B" }} onClick={this.afficherSupprimer.bind(this, e)}>envoyer à un médecin</div>
                </div>
            </div>
        );

        const fichiers = this.state.fichiersIds.map((e) => 
            <Col style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', marginBottom: '1em'}}>
                <div className='fichier-card' style={{cursor: 'pointer'}} onClick={this.ouvrirFichier.bind(this, e.fichierId)}>
                    <img style={{maxHeight: '150px', width: '100%'}} className='medecin-photo' src={baseUrl + '/medicinemanagement-api/fichier/' + e.fichierId} alt='Aperçu indisponible'/>
                </div>
                <div style={{textAlign: 'center'}}>
                    <br/>
                    <a href='javascript:;' onClick={this.telechargerFichier.bind(this, e.fichierId)}><FontAwesomeIcon style={{ fontSize: "0.9rem" }} icon={faFileDownload}/> Télécharger</a>
                </div>
            </Col>
        );

        return (
            <div>
                <Container className="account-container">
                    <h2 className="text-center" style={{ paddingTop: '30px', paddingBottom: '25px', color: '#344B7B', textDecoration: 'uppercase' }}>Mes Documents</h2>
                </Container>
                <div className='admin-filter'>
                    <Row noGutters>
                        <Label sm='1'>Filtre :</Label>
                        <Col sm='4'>
                            <Input type='text' name='filtre' value={this.state.filtre} onChange={this.handleChange} placeholder='Désignation, Type, etc...'></Input>
                        </Col>
                        <Col sm='7' style={{ textAlign: 'end' }}>
                            <Button onClick={this.filtrer} disabled={this.state.sending}>
                                Rechercher
                                &nbsp;
                            <Loader style={{ display: this.state.sending ? 'inline' : 'none' }}
                                    type="Puff"
                                    color="#FFFFFF"
                                    height={25}
                                    width={25}
                                />
                            </Button>
                            <div className="add-element" style={{ display: 'inline' }} onClick={this.toggleModal}>
                                <FontAwesomeIcon icon={faPlus} /> Ajouter
                            </div>
                        </Col>
                        {/*<Col sm='4'>
                           
                        </Col>*/}
                    </Row>
                </div>
                <div className="admin-container">
                    {documents}
                    {((this.state.isFirst != null) && (this.state.isLast != null)) &&
                        <Row style={{ margin: '3rem auto 0 auto' }}>
                            <Col style={{ textAlign: 'right', cursor: 'pointer', pointerEvents: this.state.isFirst ? 'none' : 'visible' }} onClick={this.rechercher.bind(this, false)}><FontAwesomeIcon icon={faChevronLeft} style={{ fontSize: '0.9rem', marginRight: '0.5rem' }} />Precedent</Col>
                            <Col style={{ cursor: 'pointer', pointerEvents: this.state.isLast ? 'none' : 'visible' }} onClick={this.rechercher.bind(this, true)} disabled={this.state.isLast}>Suivant<FontAwesomeIcon icon={faChevronRight} style={{ fontSize: '0.9rem', marginLeft: '0.5rem' }} /></Col>
                        </Row>
                    }
                </div>

                <Modal isOpen={this.state.modal} toggle={this.toggleModal}>
                    <ModalHeader toggle={this.toggleModal}>Document</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label>Patient</Label>
                            <Input type="select" name="patientId" value={this.state.patientId} onChange={this.handleChange}>
                                    {patients}
                                </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label>Description</Label>
                            <Input type="text" name="description" value={this.state.description} onChange={this.handleChange} />
                        </FormGroup>
                        <FormGroup>
                            <Label>Fichiers</Label>
                            <Input type="file" onChange={this.handleFichiersChange} multiple/>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.enregistrer} disabled={this.state.sending}>
                            Enregistrer
                            &nbsp;
                            <Loader style={{ display: this.state.sending ? 'inline' : 'none' }}
                                type="Puff"
                                color="#FFFFFF"
                                height={25}
                                width={25}
                            />
                        </Button>{' '}
                        <Button color="secondary" onClick={this.toggleModal}>Annuler</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.modalSuppression} toggle={this.toggleModalSuppression}>
                    <ModalHeader toggle={this.toggleModalSuppression}>{this.state.titre}</ModalHeader>
                    <ModalBody>
                        {this.state.message}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.supprimer} disabled={this.state.sending}>
                            Supprimer
                            &nbsp;
                            <Loader style={{ display: this.state.sending ? 'inline' : 'none' }}
                                type="Puff"
                                color="#FFFFFF"
                                height={25}
                                width={25}
                            />
                        </Button>{' '}
                        <Button color="secondary" onClick={this.toggleModalSuppression}>Annuler</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.modalFichiers} toggle={this.afficherModal.bind(this, 'modalFichiers')}>
                    <ModalHeader toggle={this.afficherModal.bind(this, 'modalFichiers')}>Fichiers</ModalHeader>
                    <ModalBody>
                        <Row xs='1' sm='1' md='3'>
                            {fichiers}
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.afficherModal.bind(this, 'modalFichiers')}>Fermer</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.modalFichier} toggle={this.afficherModal.bind(this, 'modalFichier')} size='lg'>
                    <ModalHeader toggle={this.afficherModal.bind(this, 'modalFichier')}>Fichier</ModalHeader>
                    <ModalBody>
                        <div style={{textAlign: 'center'}}>
                            <img style={{maxWidth: '100%'}} className='medecin-photo' src={baseUrl + '/medicinemanagement-api/fichier/' + this.state.fichierId} alt='Aperçu indisponible'/>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.afficherModal.bind(this, 'modalFichier')}>Fermer</Button>
                    </ModalFooter>
                </Modal>
                
            </div>
        )
    }
}