import React, { Component } from "react";
import {
  Button,
  Container,
  Row,
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import BarreDeRecherche from "./BarreDeRecherche";

import "./Header.css";
import PlacesAutocomplete from "react-places-autocomplete";
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      suggestion: "",
      address: "",
      typeRecherche: "SPECIALITE"
    };

    this.onSuggestionChange = this.onSuggestionChange.bind(this);
    this.rechercher = this.rechercher.bind(this);
  }

  onSuggestionChange(suggestion) {
    this.setState({
      suggestion: suggestion,
    });
  }

  rechercher(e) {
    e.preventDefault();
    if (this.state.suggestion == "") {
      return;
    }
    this.props.history.push(
      "/listeresultatderecherche/" + this.state.suggestion
    );
  }

  handleChange = (address) => {
    this.setState({ address });
  };

  handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => console.log("Success", latLng))
      .catch((error) => console.error("Error", error));
  };

  render() {
    return (
      <header className="masthead text-white text-center">
        <Container>
          <Row>
            <div className="col-xl-9 mx-auto">
              <h1 className="mb-3">
                Téléconsultez avec vos professionnels de santé
              </h1>
              <h6 className="sous-titre">
                Saisissez pour rechercher un médecin par son nom ou sa
                spécialité ou une localité
              </h6>
            </div>
            <div className="col-md-10 col-lg-8 col-xl-7 mx-auto">
              <InputGroup>
                <select value={this.state.typeRecherche} onChange={(e) => this.setState({ typeRecherche: e.target.value })} className="form-control" style={{ height: "50px" }}>
                  <option value="ZONE">Par zone</option>
                  <option value="SPECIALITE">Par spécialité</option>
                </select>
                {this.state.typeRecherche === "ZONE" ? <PlacesAutocomplete
                  value={this.state.address}
                  onChange={this.handleChange}
                  onSelect={this.handleSelect}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div>
                      <input
                        {...getInputProps({
                          placeholder: "Rechercher une zone...",
                          className: "location-search-input",
                        })}
                      />
                      <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item";
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? { backgroundColor: "#fafafa", cursor: "pointer" }
                            : { backgroundColor: "#ffffff", cursor: "pointer" };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete> : null}
                {this.state.typeRecherche === "SPECIALITE" ? <BarreDeRecherche
                  onSuggestionChange={this.onSuggestionChange}
                  className="searchbar-input form-control"
                /> : null}
                <InputGroupAddon addonType="append">
                  <Button
                    className="search-button"
                    style={{
                      padding: "0",
                      height: "50px",
                      width: "180px",
                      textAlign: "center",
                      verticalAlign: "center",
                      backgroundColor: "#F9BA14",
                      borderColor: "#F9BA14",
                      fontWeight: "bold",
                      fontSize: "0.9rem",
                      zIndex: 0,
                    }}
                    color="secondary"
                    onClick={this.rechercher}
                  >
                    Rechercher
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </div>
          </Row>
        </Container>
      </header>
    );
  }
}

export default withRouter(Header);
