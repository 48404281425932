import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, NavLink } from 'react-router-dom';
import { Nav, NavItem } from 'reactstrap';
import ConditionsDUtilisation from './ConditionsDUtilisation';
import Confidentialite from './Confidentialite';
import AppNavbar from './AppNavbar';

import './Conditions.css'

export default class Conditions extends Component{
    constructor(props){
        super(props);

        this.state= {

        }
    }

    render(){
        return (
            <div>
                <AppNavbar />
                <div className="header_search" style={{ marginBottom: "3rem" }}>
                    <Nav style={{ justifyContent: "center" }}>
                        <NavItem>
                            <NavLink to="/conditions/conditionsdutilisation" class="nav-link" activeClassName="active">Conditions d'utilisation</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink to="/conditions/confidentialite" class="nav-link" activeClassName="active">Politique de confidentialité</NavLink>
                        </NavItem>
                    </Nav>
                </div>
                {/*<div>
                    <Nav style={{ justifyContent: "center", marginTop: "3rem", marginBottom: "3rem" }}>
                        <NavItem>
                            <NavLink to="/conditions/conditionsdutilisation" class="nav-link" activeClassName="active">Conditions d'utilisation</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink to="/conditions/confidentialite" class="nav-link" activeClassName="active">Politique de confidentialité</NavLink>
                        </NavItem>
                    </Nav>
                </div>*/}
                <Switch>
                    <Route exact path={this.props.match.path} component={ConditionsDUtilisation} />
                    <Route path="/conditions/conditionsdutilisation" component={ConditionsDUtilisation} />
                    <Route path="/conditions/confidentialite" component={Confidentialite} />
                </Switch>
            </div>
        )
    }
}

//export default withRouter(Conditions);