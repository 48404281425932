import React, { Component } from 'react';

import { ActivationContext } from './context';

import { HttpGET, HttpPOST, HttpDELETE } from "./service/HttpService";
import { estConnecte } from './service/UserService';

export default class WizardConsultationPatient extends Component {
    static contextType = ActivationContext;

    constructor(props) {
        super(props);

        this.state = {
            patients: [],
            patient: null
        }

        this.rechercherPatients = this.rechercherPatients.bind(this);
        this.choisirPatient = this.choisirPatient.bind(this);
    }

    rechercherPatients() {
        HttpGET('/medicinemanagement-api/rechercherpatients', function (data) {
            this.setState({
                patients: data.donnee
            });
            if(data.donnee.length==1){
                this.choisirPatient(data.donnee[0]);
            }
        }.bind(this));
    }

    choisirPatient(patient) {
        this.context.activateNextTab();
        this.props.onChoose(patient)
    }

    componentDidMount() {
        if (!estConnecte()) {
            return;
        }
        this.rechercherPatients();
    }

    render() {
        const patients = this.state.patients.map((patient) =>
            <div className="account-patient">
                <div className="initial" onClick={this.choisirPatient.bind(this, patient)}>{patient.prenoms.slice(0, 1)}</div>
                <div onClick={this.choisirPatient.bind(this, patient)}>
                    <div>{patient.prenoms + ' ' + patient.nom}</div>
                    <div>{patient.dateDeNaissance}</div>
                </div>
            </div>
        );
        return (
            <ActivationContext.Consumer>
                {({ activeTab, activateTab, activateNextTab }) => (
                    <div>
                        {patients}
                    </div>
                )}
            </ActivationContext.Consumer>
        )
    }
}