import React from 'react';

export const ActivationContext = React.createContext({
    activeTab: '1',
    activateTab: () => {},
    activateNextTab: () => {}
});

export const PaiementContext = React.createContext({
    articleCode: '',
    articleCategorie: '',
    articleDesignation: '',
    articleDescription: '',
    articleCout: 0,
    coutAffiche: '',
    articlePhotoId: 0,
    quantite: 1,
    prestationChargee: false,

    nom: '',
    prenoms: '',
    telephone: '',
    email: '',
    pays: [],
    paysId: 0,
    paysDesignation: '',
    villes: [],
    villeId: 0,
    villeDesignation: '',
    communes: [],
    communeId: 0,
    communeDesignation: '',
    localisationsChargees: false,
    adresse: '',

    typePaiement: '',
    operateur: '',
    token: '',
    numero: '',
    commandeValidee: false,

    chargerPrestation: () => {},
    modifierPanier: () => {},
    modifierAdresse: () => {},
    commander: () => {}
});