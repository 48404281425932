import React, { Component } from 'react';

export default class Confidentialite extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <div className="admin-container">
                    <div class="dl-legal-content-html">
                    
                        <h1>Politique de protection des données personnelles</h1>

                        <p><strong>Le respect de la vie privée est un droit fondamental et l’une des valeurs essentielles de SKANMED.</strong></p>

                        <p><strong>SKANMED respecte la réglementation ivoienne sur la protection des données personnelles, en particulier la loi n° 2013-450 du 19 juin 2013.</strong></p>

                        <h2>OBJET DE LA PRÉSENTE POLITIQUE</h2>

                        <p><strong>SKANMED souhaite vous informer par l’intermédiaire de la présente politique de la manière dont nous protégeons vos données à caractère personnel traitées via le site <a href='https://skanmed.com'>https://skanmed.com</a> ou via l’application « SkanMed » disponible sur mobile.</strong></p>

                        <p>La présente politique décrit la manière dont SKANMED et les professionnels de santé abonnés à ses services traitent les données à caractère personnel des visiteurs et des utilisateurs (ci-après le/les « Utilisateur(s) ») lors de leur navigation sur le site <a href='https://skanmed.com'>https://skanmed.com</a> (ci-après le « Site ») et de leur utilisation des services de SKANMED ou de son application mobile (ci-après l’”Application”).</p>

                        <p>Certaines Données Personnelles de l’Utilisateur doivent être considérées comme des données à caractère personnelles de santé, et sont ci-après dénommées « Données Personnelles de Santé ».</p>

                        <p>Cette Politique peut être modifiée, complétée ou mise à jour afin notamment de se conformer à toute évolution légale, réglementaire, jurisprudentielle et technique. Cependant, les Données Personnelles de l’Utilisateur seront toujours traitées conformément à la politique en vigueur au moment de leur collecte, sauf si une prescription légale impérative venait à en disposer autrement et serait d'application rétroactive.</p>

                        <p>Cette politique fait partie intégrante des <a href="/conditions/conditionsdutilisation">Conditions Générales d’Utilisation</a> du Site.</p>

                        <h2>IDENTITE ET COORDONNEES DES RESPONSABLES DE TRAITEMENT</h2>

                        <p><em>Rappel légal : Le responsable du traitement est la personne qui détermine les moyens et les finalités du traitement. Le sous-traitant est une personne traitant des données à caractère personnel pour le compte du responsable du traitement. Il agit sous l’autorité du responsable du traitement et sur instruction de celui-ci.</em></p>

                        <p>Le responsable du traitement des Données Personnelles est :</p>

                        <ol>
                            <li><p><strong>Pour les Données Personnelles de Santé</strong> collectées (i) lors de la prise de rendez-vous par l'Utilisateur directement via le Site ou l’Application ou (ii) par le Professionnel de Santé dans son agenda SkanMed : <strong>chaque Professionnel de Santé individuellement auprès duquel vous prenez rendez-vous.</strong> Chaque professionnel de santé est considéré comme responsable de traitement des Données Personnelles de Santé de ses patients tandis que SkanMed est sous-traitant : il agit sur instruction particulière de chaque Professionnel de Santé.</p></li>
                            <li><p><strong>Pour les Données Personnelles collectées dans le cadre de (a) la création du compte personnel de l’Utilisateur</strong>, (b) sa navigation sur le Site ou l’Application (c) et la création de statistiques relatives à l'utilisation de l'outil, leur computation et leur anonymisation : <strong>SKAN TECHNOLOGIES</strong>, RCCM N° CI-ABJ-2011-A-6390 (ci-avant et ci-après « <strong>SKANMED</strong> »). SkanMed est représentée par Stanislas Niox-Chateau, son Président.</p></li>
                        </ol>

                        <p>Qu’elle soit responsable de traitement ou sous-traitant, SKANMED prend les mesures propres à assurer la protection et la confidentialité des Données Personnelles qu’elle détient ou qu’elle traite.
Pour plus d’information concernant les services proposés par SKANMED vous pouvez vous référer aux Conditions générales d’utilisation du Site (<a href="https://skanmed.com/conditions">https://skanmed.com/conditions</a>).</p>

                        <h2>COLLECTE &amp; ORIGINE DES DONNEES</h2>

                        <p>Toutes les données concernant les Utilisateurs sont collectées directement auprès de ces derniers, par SKANMED ou le Professionnel de Santé.</p>

                        <p>SKANMED s’engage à recueillir le consentement de ses Utilisateurs et/ou à leur permettre de s’opposer à l’utilisation de leurs données pour certaines finalités, dès que cela est nécessaire.</p>

                        <p>Lors de leur navigation sur le site SKANMED, les Utilisateurs sont informés des finalités pour lesquelles leurs données sont collectées via les différents formulaires de collecte de données en ligne, via la présente Politique de Protection des Données.</p>

                        <h2>FINALITE DES DONNEES COLLECTEES</h2>

                        <p>1. Nécessité de la collecte.</p>

                        <p>Lors de la prise de rendez-vous en ligne avec un Professionnel de Santé, l’Utilisateur communique certaines Données Personnelles. Si l’Utilisateur ne souhaite pas communiquer les informations qui lui sont demandées, il se peut que l’Utilisateur ne puisse pas accéder à certaines parties du Site ou de l’Application, et que SKANMED soit dans l’impossibilité de répondre à sa demande.</p>

                        <p>2. Finalités</p>

                        <p>Le recueil de vos Données Personnelles a pour base légale :</p>

                        <ul>
                            <li>l’intérêt légitime de SKANMED à assurer la meilleure qualité de ses services, à fournir à ses Utilisateurs le meilleur suivi possible de leurs rendez-vous et de leurs parcours de soins, et à améliorer le fonctionnement de son Site et de son Application;</li>
                            <li>l’intérêt légitime de SKANMED à produire des données statistiques anonymisées relatives à l'impact de SKANMED sur l’activité des Professionnels de santé et du secteur de la santé afin de communiquer sur son outil et améliorer ses services;</li>
                            <li>l’intérêt légitime de SKANMED à réaliser des enquêtes facultatives sur ses services en vue de les améliorer;</li>
                            <li>le consentement de ses Utilisateurs lorsque celui-ci est requis par la réglementation en vigueur, notamment en matière de prospection commerciale et de cookies.</li>
                        </ul>

                        <p>Les données des Utilisateurs de SKANMED sont principalement traitées pour :</p>

                        <ul>
                            <li>permettre leur navigation sur le Site et permettre leur utilisation de l’Application</li>
                            <li>les mettre en relation avec un Professionnel de Santé</li>
                            <li>leur permettre de visualiser et gérer leurs parcours de soin, tant pour les rendez-vous pris directement sur le Site, que les rendez-vous renseigné par leurs Professionnels de Santé directement</li>
                            <li>leur permettre de partager des documents avec leurs Professionnels de santé</li>
                            <li>leur permettre de gérer les rendez-vous de leurs proches et leurs parcours de soin</li>
                            <li>leur permettre d’être pris en charge de façon optimale par le Professionnel de Santé</li>
                        </ul>

                        <p>A titre subsidiaire les données des Utilisateurs sont également collectées pour :</p>

                        <ul>
                            <li>prévenir et lutter contre la fraude informatique (spamming, hacking…)</li>
                            <li>améliorer la navigation sur le Site et l’utilisation de l’Application</li>
                            <li>mener des enquêtes facultatives sur les services de SKANMED et leurs possibles évolutions (ces enquêtes sont réalisées de manière anonyme ou sont anonymisées à bref délai)</li>
                            <li>mener des enquêtes facultatives sur la qualité de leur prise en charge par leurs Professionnels de Santé (à l’initiative des Professionnels de Santé)</li>
                            <li>effectuer des statistiques anonymisées sur l’utilisation de l’outil qui seront communiquées au public, un reporting interne pour les équipes de recherche &amp; développement de SKANMED ainsi qu’un reporting à destination des Professionnels de Santé (sans qu’aucune Donnée de Santé ne soit utilisée).</li>
                        </ul>

                        <p>Le caractère obligatoire ou facultatif des données personnelles demandées et les éventuelles conséquences d'un défaut de réponse à l’égard des Utilisateurs de SKANMED sont précisés lors de leur(s) collecte(s).</p>

                        <h2>TYPES DE DONNÉES TRAITÉES</h2>

                        <p>SKANMED est susceptible de traiter, en tant que <strong>Sous-traitant</strong>, pour permettre la prise de rendez-vous, la gestion des rendez-vous et du parcours de soins, tout ou partie des données suivantes :</p>

                        <ul>
                            <li>Nom (et nom de naissance), prénom, date de naissance</li>
                            <li>Numéro de téléphone, adresse email, adresse postale (éventuellement digicode)</li>
                            <li>Mot de passe</li>
                            <li>Motif du rendez-vous auprès du praticien, spécialité du praticien, fréquence des rendez-vous</li>
                            <li>Médecin traitant et adressant</li>
                            <li>Historique des rendez-vous</li>
                            <li>Données appartenant au dossier médical que le Professionnel de Santé souhaite partager avec l'Utilisateur ou un autre Professionnel de Santé appartenant à l’équipe de soins du patient</li>
                            <li>Ces mêmes données concernant les proches pour lesquels l’Utilisateur pourrait prendre rendez-vous</li>
                        </ul>

                        <p>SKANMED est susceptible de traiter, en tant que <strong>Responsable de Traitement</strong>, pour permettre la prise de rendez-vous, la gestion des rendez-vous et du parcours de soins, tout ou partie des données suivantes :</p>

                        <ul>
                            <li>Nom (et nom de naissance), prénom</li>
                            <li>Données de connexion et d’utilisation du Site ou de l’Application</li>
                            <li>Matériel informatique utilisé pour la navigation, l’adresse IP, le mot de passe (hashé)</li>
                            <li>Données de connexion et d’utilisation</li>
                            <li>Adresse email</li>
                            <li>Numéro de téléphone</li>
                        </ul>

                        <h2>NON-COMMUNICATION DES DONNÉES PERSONNELLES</h2>

                        <p><strong>Les Données Personnelles de l’Utilisateur ne seront pas transmises à des acteurs commerciaux ou publicitaires.</strong></p>

                        <p>Les Données Personnelles de l’Utilisateur peuvent être traitées par des filiales de SKANMED et des sous-traitants (prestataires de services), dans le respect absolu du principe énoncé ci-dessus, exclusivement afin de réaliser les finalités de la présente politique.</p>

                        <p>Dans la limite de leurs attributions respectives et pour les finalités rappelées ci-dessus, les principales personnes susceptibles d’avoir accès aux données des Utilisateurs (hors Données de Santé) de SKANMED sont principalement les agents de notre service client.</p>

                        <p>SKANMED recourt également aux prestations fournies par plusieurs sociétés spécialisées (mailing, analyse d’audience) dont la liste peut être communiquée aux personnes concernées sur demande adressée à contact@skanmed.com. Aucune Donnée Personnelle de Santé ne leur est communiquée.</p>

                        <h2>DURÉE DE CONSERVATION DES DONNÉES</h2>

                        <p>Nous conservons vos données uniquement le temps nécessaire pour les finalités poursuivies, conformément aux prescriptions légales.</p>

                        <h2>LES DROITS DES UTILISATEURS</h2>

                        <p>Chaque fois que SKANMED traite des Données Personnelles, SKANMED prend toutes les mesures raisonnables pour s’assurer de l’exactitude et de la pertinence des Données Personnelles au regard des finalités pour lesquelles SKANMED les traite.</p>

                        <p>Conformément à la réglementation ivoienne en vigueur, les Utilisateurs de SKANMED disposent des droits suivants :</p>

                        <ul>
                            <li>droit d'accès et de rectification, de mise à jour, de complétude des données des Utilisateurs</li>
                            <li>droit de verrouillage ou d’effacement des données des Utilisateurs à caractère personnel, lorsqu’elles sont inexactes, incomplètes, équivoques, périmées, ou dont la collecte, l'utilisation, la communication ou la conservation est interdite</li>
                            <li>droit de retirer à tout moment un consentement</li>
                            <li>droit à la limitation du traitement des données des Utilisateurs</li>
                            <li>droit d’opposition au traitement des données des Utilisateurs</li>
                            <li>droit à la portabilité des données que les Utilisateurs auront fournies, lorsque ces données font l’objet de traitements automatisés fondés sur leur consentement ou sur un contrat</li>
                            <li>droit de définir le sort des données des Utilisateurs après leur mort et de choisir à qui SKANMED devra communiquer (ou non) ses données à un tiers qu’ils aura préalablement désigné</li>
                        </ul>

                        <p>Dès que SKANMED a connaissance du décès d’un Utilisateur et à défaut d’instructions de sa part, SKANMED s’engage à détruire ses données, sauf si leur conservation s’avère nécessaire à des fins probatoires ou pour répondre à une obligation légale (telle que la conservation du dossier patient).</p>

                        <p>Si l’Utilisateur souhaite savoir comment SKANMED utilise ses Données Personnelles, demander à les rectifier ou s’oppose à leur traitement, l’Utilisateur peut contacter SKANMED par courrier électronique à l’adresse suivante : contact@skanmed.com. Dans ce cas, l’Utilisateur doit indiquer les Données Personnelles qu’il souhaiterait que SKANMED corrige, mette à jour ou supprime, en s’identifiant de manière précise avec une copie d’une pièce d’identité (carte d’identité ou passeport) ou tout autre élément permettant de justifier de son identité. Les demandes de suppression de Données Personnelles seront soumises aux obligations qui sont imposées à SKANMED par la loi, notamment en matière de conservation ou d’archivage des documents. Enfin, les Utilisateurs de SKANMED peuvent déposer une réclamation auprès des autorités de contrôle.</p>

                        <h2>RÉSEAUX SOCIAUX</h2>

                        <p>L’Utilisateur de SKANMED a la possibilité de cliquer sur les icônes dédiées aux réseaux sociaux Twitter, Facebook, Linkedin et Google Plus figurant sur le Site ou dans l’Application de SKANMED.</p>

                        <p>Les réseaux sociaux permettent d'améliorer la convivialité du Site et aident à sa promotion via les partages. Les services de partage de vidéo permettent d'enrichir le Site de SKANMED de contenus vidéo et augmentent sa visibilité.</p>

                        <p>Lorsque l’Utilisateur clique sur ces boutons, SKANMED pourra avoir accès aux informations personnelles que l’Utilisateur aura indiquées comme publiques et accessibles depuis ses profils Twitter, Facebook, Linkedin et Google plus. Cependant, SKANMED ne crée ni n’utilise aucune base de données indépendante de Twitter, Facebook, Linkedin et Google Plus à partir des informations personnelles que l’Utilisateur peut y publier et SKANMED ne traitera aucune donnée relevant de sa vie privée par ce biais.</p>

                        <p>Si l’Utilisateur ne souhaite pas que SKANMED ait accès aux informations personnelles publiées sur l’espace public de ses profils ou de ses comptes sociaux, l’Utilisateur devra alors utiliser les moyens mis à sa disposition par Twitter, Facebook, Linkedin et Google Plus afin de limiter l’accès à ses données.</p>

                        <h2>SÉCURITÉ</h2>

                        <p>SKANMED met en œuvre toutes les mesures techniques et organisationnelles afin d’assurer la sécurité des traitements de données à caractère personnel et la confidentialité de Données Personnelles.</p>

                        <p>A ce titre, SKANMED prend toutes les précautions utiles, au regard de la nature des données et des risques présentés par le traitement, afin de préserver la sécurité des données et, notamment, d’empêcher qu’elles soient déformées, endommagées, ou que des tiers non autorisés y aient accès (protection physique des locaux, procédés d’authentification avec accès personnel et sécurisé via des identifiants et mots de passe confidentiels, journalisation des connexions, chiffrement de certaines données…).</p>

                        <h2>INFORMATIONS PERSONNELLES ET MINEURS</h2>

                        <p>En principe, le Site et l’Application s’adressent à des personnes majeures capables de contracter des obligations conformément à la législation du pays dans lequel se trouve l’Utilisateur.</p>

                        <p>L'Utilisateur mineur de moins de 15 ans ou incapable doit obtenir le consentement préalable de son responsable légal préalablement à la saisie de ses données sur le Site et l’Application. Sauf obligation légale de confidentialité ou liée au secret médical, SKANMED pourra directement informer ce responsable (i) des catégories spécifiques de Données Personnelles recueillies auprès de la personne mineure (ii) de la possibilité de s’opposer à la collecte, l’utilisation ou la conservation de celles-ci.</p>

                        <h2>NOUS CONTACTER</h2>

                        <p>Si l’Utilisateur a des questions ou des réclamations concernant le respect par SKANMED de la présente Politique, ou si l’Utilisateur souhaite faire part à SKANMED de recommandations ou des commentaires visant à améliorer la qualité de la présente politique, l’Utilisateur peut contacter SKANMED par écrit à l’adresse suivante : contact@skanmed.com.</p>
                        <h2>COMPLEMENT APPLICABLE AU SERVICE DE CONSULTATION PAR VIDEO</h2>

                        <p>Le présent complément à la Politique de protection des données personnelles des Services SKANMED s’applique au Service de Consultation par Vidéo (ou Téléconsultation) proposé par SKANMED. Il complète la Politique de protection des données personnelles des Services SKANMED. Si tel n’était pas déjà le cas, l’acceptation du présent complément emporte acceptation pleine et entière de la Politique de protection des données personnelles des Services SKANMED. Pour plus d’informations concernant le service de Téléconsultation proposé par SKANMED, vous pouvez vous référer aux Conditions Particulières d’Utilisation du Service de Consultation par Vidéo.</p>

                        <h2>DEFINITIONS ADDITIONNELLES</h2>

                        <p>Au sens du présent complément, les expressions ci-dessous ont la définition suivante :</p>

                        <ul>
                            <li><p><strong>Application Mobile</strong> désigne l'application SKANMED disponible depuis un smartphone par suite du téléchargement de celle-ci sur un magasin d’applications.</p></li>
                            <li><p><strong>Site Mobile</strong> désigne le site internet skanmed.com accessible depuis un smartphone.</p></li>
                            <li><p><strong>Service(s) de Téléconsultation</strong> ou Service de Consultation par Vidéo désigne la mise à disposition par SKANMED à l’Utilisateur d’un outil permettant de réaliser une consultation à distance par vidéotransmission avec un Professionnel de santé au travers de l’Application Mobile ou du Site Mobile.</p></li>
                            <li><p><strong>Téléconsultation</strong> ou Consultation par Vidéo désigne une consultation à distance entre un Utilisateur et un Professionnel de santé réalisée par vidéotransmission via l’Application Mobile ou le Site Mobile.</p></li>
                        </ul>

                        <h2>QUI EST RESPONSABLE DU TRAITEMENT DES DONNEES COLLECTEES POUR LA TELECONSULTATION?</h2>

                        <p>Le responsable du traitement des Données Personnelles traitées spécifiquement pour le Service de Téléconsultation est chaque Professionnel de Santé avec lequel un rendez-vous pour une Téléconsultation est pris. SKANMED est sous-traitant : il agit sur instruction particulière de chaque Professionnel de Santé.</p>

                        <h2>A QUELLES FINS SONT-ELLES UTILISEES?</h2>

                        <p>Le recueil de vos Données Personnelles a pour base légale :</p>

                        <ul>
                            <li><p>votre acceptation des Conditions Générales et Particulières d’Utilisation (CGU et CPU) et la présente politique et son complément;</p></li>
                            <li><p>l’intérêt légitime de SKANMED à assurer la meilleure qualité de ses services, à fournir à ses Utilisateurs le meilleur suivi possible de leurs parcours de soins, et à améliorer le fonctionnement de son Site (web ou mobile) et de son Application;</p></li>
                            <li><p>l’intérêt légitime de SKANMED à réaliser des enquêtes facultatives sur ses services en vue de les améliorer;</p></li>
                            <li><p>le consentement de ses Utilisateurs lorsque celui-ci est requis par la réglementation en vigueur.</p></li>
                        </ul>

                        <p>Les données des Utilisateurs prenant rendez-vous pour une Téléconsultation sont traitées pour :</p>

                        <ul>
                            <li>permettre la prise de rendez-vous en ligne par les patients et leurs proches pour une téléconsultation</li>
                            <li>permettre de réaliser une téléconsultation par vidéotransmission</li>
                            <li>permettre la transmission de documents aux patients (ordonnance, compte-rendu médical, note d’honoraires ...)</li>
                            <li>permettre le paiement de la téléconsultation</li>
                            <li>gérer les litiges qui naîtraient en relation avec le Service de Téléconsultation</li>
                        </ul>

                        <h2>QUELLES SONT-ELLES EXACTEMENT?</h2>

                        <p>En complément des Données Personnelles traitées pour son service de prise de rendez-vous en ligne, SKANMED ou son propre sous-traitant (prestataire de services de paiement ou prestataire vidéo) est susceptible de traiter, en tant que <strong>Sous-traitant</strong>, pour son Service de Téléconsultation, tout ou partie des données suivantes :</p>

                        <ul>
                            <li>Coordonnées bancaires</li>
                            <li>Informations sur les transactions bancaires associées à l’utilisation du service de Téléconsultation : l'heure et la date de prestation du service, le montant facturé, le moyen de paiement utilisé</li>
                            <li>Attestation de droits permettant au patient de bénéficier du tiers payant</li>
                        </ul>

                        <p>SKANMED est susceptible de traiter, en tant que <strong>Responsable de Traitement</strong>, pour son Service de Téléconsultation, tout ou partie des données suivantes :</p>

                        <ul>
                            <li>Informations relatives à la durée de la vidéoconsultation, à la tenue de la vidéoconsultation, à la qualité de la vidéo et du son, dans un but d’amélioration du service de Téléconsultation.</li>
                        </ul>

                        <h2>ET BIEN EVIDEMMENT...</h2>

                        <p><strong>Les Données Personnelles de l’Utilisateur ne seront pas transmises à des acteurs commerciaux ou publicitaires.</strong></p>

                        <p>Les Données Personnelles de l’Utilisateur spécifiques à la Téléconsultation peuvent être traitées par des filiales de SKANMED et des sous-traitants (prestataire pour l’hébergement des données de santé, prestataire de services de paiement, prestataire pour la vidéotransmission), dans le respect absolu de la Politique de protection des données personnelles des Services SKANMED.</p>
                    </div>
                </div>
            </div>
        )
    }
}