import React, { Component } from "react";

export default class ConsultationEnAttente extends Component {
  constructor() {
    super();

    this.state = {
      suggestion: "",
      professionnels: [],

      searching: false,
      modalOpen: false,
    };
  }

  render() {
    return <div>ConsultationEnAttente</div>;
  }
}
