import React, { Component } from 'react';
import { Container, Row, Col, Input, Button, Breadcrumb, BreadcrumbItem, Form, FormGroup, Label, FormFeedback } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faCheck, faTimes, faCalculator, faCalendar, faCreditCard, faClipboard, faExclamationTriangle, faHome } from '@fortawesome/free-solid-svg-icons';
import Loader from 'react-loader-spinner';
import AppNavbar from './AppNavbar';

import { PaiementContext } from './context';

import { HttpGET, HttpPOST, HttpDELETE, baseUrl } from './service/HttpService';

let timer = null;

export default class CommandeResume extends Component {
    static contextType = PaiementContext;

    constructor(props) {
        super(props);

        this.state = {
            commandeValidee: -1
        }

        this.retrouverEtatVente = this.retrouverEtatVente.bind(this);
    }

    retrouverEtatVente() {
        const { params } = this.props.match;

        if (params.id === null) {
            this.setState({
                message: "Une erreur s'est produite pendant le paiement!"
            });

            return;
        }

        HttpGET('/medicinemanagement-api/retrouveretatvente/' + params.id, function (data) {
            this.setState({
                commandeValidee: data.statut
            });

            if (data.statut === 0) {
                this.setState({
                    prestation: data.donnee.prestation,
                    date: data.donnee.date,
                    cout: data.donnee.cout,
                    typePaiement: data.donnee.typePaiement,
                    message: data.donnee.message
                });

                return;
            }
            else if (data.statut === 1) {
                this.setState({
                    message: 'Paiement en attente de validation.'
                });

                //Vérifier à nouveau dans 5s
                timer = setTimeout(() => {
                    this.retrouverEtatVente();
                    clearTimeout(timer);
                }, 5000);
            }
            else if (data.statut === 2) {
            }
            else if (data.statut === 500) {
                this.setState({
                    message: data.donnee
                });
            }
        }.bind(this));
    }

    componentDidMount() {
        //Rechercher les details de la commande
        this.retrouverEtatVente();
    }

    render() {

        return (
            <PaiementContext.Consumer>
                {({ articleCode, quantite, nom, prenoms, telephone, email, villeId, communeId, adresse, typePaiement, operateur, token, numero, modifierPanier, modifierAdresse, commander }) => (
                    <div>
                        <AppNavbar />
                        <Container style={{ marginTop: '30px', marginBottom: '30px' }}>
                            <Breadcrumb>
                                <BreadcrumbItem>Panier</BreadcrumbItem>
                                <BreadcrumbItem>Adresse</BreadcrumbItem>
                                <BreadcrumbItem>Paiement</BreadcrumbItem>
                                <BreadcrumbItem active>Résumé</BreadcrumbItem>
                            </Breadcrumb>
                            {
                                (this.state.commandeValidee === -1) ?

                                    <div>
                                        <div style={{ marginTop: '3em', textAlign: 'center' }}>
                                            <Loader
                                                type="Puff"
                                                color="#F9BA14"
                                                height={50}
                                                width={50}
                                            />
                                        </div>
                                    </div>

                                    :

                                    (this.state.commandeValidee === 0) ?

                                        <div>
                                            <div style={{ marginTop: '3em', textAlign: 'center' }}>
                                                <FontAwesomeIcon icon={faCheck} color='green' size='3x' />
                                                <h3 style={{ color: 'gray', marginTop: '1em' }}><span>Merci. Votre commande a été reçue.</span></h3>
                                            </div>
                                            <div style={{ marginTop: '3em', padding: '3em', backgroundColor: 'white', color: '#344B7B' }}>
                                                <Row xs='1' sm='4' style={{ marginLeft: '0' }}>
                                                    <Row style={{ marginLeft: '0' }}>
                                                        <FontAwesomeIcon icon={faClipboard} size='3x' />
                                                        <div style={{ marginLeft: '1em' }}>
                                                            <b>Produit:</b>
                                                            <p style={{ color: 'gray' }}>{this.state.prestation}</p>
                                                        </div>
                                                    </Row>
                                                    <Row style={{ marginLeft: '0' }}>
                                                        <FontAwesomeIcon icon={faCalendar} size='3x' />
                                                        <div style={{ marginLeft: '1em' }}>
                                                            <b>Date:</b>
                                                            <p style={{ color: 'gray' }}>{this.state.date}</p>
                                                        </div>
                                                    </Row>
                                                    <Row style={{ marginLeft: '0' }}>
                                                        <FontAwesomeIcon icon={faCalculator} size='3x' />
                                                        <div style={{ marginLeft: '1em' }}>
                                                            <b>Total:</b>
                                                            <p style={{ color: 'gray' }}>{this.state.cout}</p>
                                                        </div>
                                                    </Row>
                                                    <Row style={{ marginLeft: '0' }}>
                                                        <FontAwesomeIcon icon={faCreditCard} size='3x' />
                                                        <div style={{ marginLeft: '1em' }}>
                                                            <b>Mode de paiement:</b>
                                                            <p style={{ color: 'gray' }}>{this.state.typePaiement}</p>
                                                        </div>
                                                    </Row>
                                                </Row>
                                            </div>
                                            {
                                                this.state.message &&
                                                <div style={{ marginTop: '3em', textAlign: 'center' }}>
                                                    <h6 style={{ color: 'gray', marginTop: '1em' }}><FontAwesomeIcon icon={faExclamationTriangle} />&nbsp;<span>Paiement en argent comptant à la livraison</span></h6>
                                                </div>
                                            }
                                            <div style={{ textAlign: 'center', marginTop: '3em' }}>
                                                <a href="/" style={{ cursor: 'pointer', textDecoration: 'none', color: "#344B7B" }}>
                                                    <FontAwesomeIcon icon={faHome} style={{ fontSize: "0.9rem", marginRight: '0.5rem' }} />Accueil
                                                </a>
                                            </div>
                                        </div>

                                        :

                                        (this.state.commandeValidee === 1) ?

                                            <div>
                                                <div style={{ marginTop: '3em', textAlign: 'center' }}>
                                                    <Loader
                                                        type="Puff"
                                                        color="#F9BA14"
                                                        height={50}
                                                        width={50}
                                                    />
                                                    <h3 style={{ color: 'gray', marginTop: '1em' }}><span>{this.state.message}</span></h3>
                                                </div>
                                                <div style={{ textAlign: 'center', marginTop: '3em' }}>
                                                    <span onClick={this.props.history.goBack} style={{ cursor: 'pointer' }} disabled={this.state.sendingConsultation}>
                                                        <FontAwesomeIcon icon={faChevronLeft} style={{ fontSize: "0.9rem", marginRight: '0.5rem' }} />Annuler
                                                    </span>
                                                </div>
                                            </div>

                                            :

                                            <div>
                                                <div style={{ marginTop: '3em', textAlign: 'center' }}>
                                                    <FontAwesomeIcon icon={faTimes} color='red' size='3x' />
                                                    <h3 style={{ color: 'gray', marginTop: '1em' }}><span>{this.state.message}</span></h3>
                                                </div>
                                                <div style={{ textAlign: 'center', marginTop: '3em' }}>
                                                    <span onClick={this.props.history.goBack} style={{ cursor: 'pointer' }} disabled={this.state.sendingConsultation}>
                                                        <FontAwesomeIcon icon={faChevronLeft} style={{ fontSize: "0.9rem", marginRight: '0.5rem' }} />Retour
                                                    </span>
                                                </div>
                                            </div>
                            }


                        </Container>
                    </div>
                )}
            </PaiementContext.Consumer>
        )
    }
}