export const baseUrl = 'https://skanmed.com';
//export const baseUrl = 'http://localhost:9090';
export function HttpGET(url, callback, callbackError=null){
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem("accessToken") 
        }
    };
    fetch(baseUrl + url, requestOptions)
        .then(response => response.json())
        .then(data => {
            console.log(data);
            if(data.status === 401){
                if(callbackError){
                    callbackError(data);
                    return;
                }
                // localStorage.clear();
                // document.location.href = '/identification';
                return;
            }
            
            callback(data);
        });
}

export function HttpPOST(url, data, callback, callbackError=null){
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem("accessToken") 
        },
        body: JSON.stringify(data)
    };
    fetch(baseUrl + url, requestOptions)
        .then(response => response.json())
        .then(data => {
            console.log(data);
            if(data.status === 401){
                if(callbackError){
                    callbackError(data);
                    return;
                }
                localStorage.clear();
                document.location.href = '/identification';
                return;
            }

            callback(data);
        });
}

export function HttpDELETE(url, callback, callbackError=null){
    const requestOptions = {
        method: 'DELETE',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem("accessToken") 
        }
    };
    fetch(baseUrl + url, requestOptions)
        .then(response => response.json())
        .then(data => {
            console.log(data);
            if(data.status === 401){
                if(callbackError){
                    callbackError(data);
                    return;
                }
                localStorage.clear();
                document.location.href = '/identification';
                return;
            }
            
            callback(data);
        });
}