import React, { Component } from 'react';
import { Container, Row, Col, Input, Button, Breadcrumb, BreadcrumbItem, Form, FormGroup, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import Loader from 'react-loader-spinner';
import AppNavbar from './AppNavbar';

import { PaiementContext } from './context';

import { HttpGET, HttpPOST, HttpDELETE, baseUrl } from './service/HttpService';

import logoMTN from './assets/img/mtn.jpg';
import logoMoov from './assets/img/moov.jpg';
import logoOrange from './assets/img/orange.jpg';

export default class CommandePaiement extends Component {
    static contextType = PaiementContext;

    constructor(props) {
        super(props);

        this.state = {
            typePaiement: 'EN_LIGNE',
            cout: '',
            devise: 'FCFA',
            operateur: 'OM_SKAN',
            token: '',
            numero: ''
        }

        this.payer = this.payer.bind(this);
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    payer() {
        this.setState({
            sendingConsultation: true
        });

        var requete = {
            code: this.context.articleCode,
            quantite: this.context.quantite,

            nom: this.context.nom,
            prenoms: this.context.prenoms,
            telephone: this.context.telephone,
            email: this.context.email,
            paysId: this.context.paysId,
            villeId: this.context.villeId,
            communeId: this.context.communeId,
            adresse: this.context.adresse,

            typePaiement: this.state.typePaiement,
            operateur: this.state.operateur,
            numero: this.state.numero,
            token: this.state.token
        }

        HttpPOST("/medicinemanagement-api/enregistrerventeprestation", requete, function (data) {
            if (data.statut != 0) {
                this.props.history.push('/commandeprestation/resume/' + data.donnee.venteId)
                return;
            }

            this.setState({
                sendingConsultation: false
            });

            //Rediriger vers la page de resultat
            this.props.history.replace('/commanderesume/' + data.donnee.venteId);
        }.bind(this));
    }

    componentDidMount() {

    }

    render() {
        return (
            <div>
                <AppNavbar />
                <Container style={{ marginTop: '30px' }}>
                    <Breadcrumb>
                        <BreadcrumbItem>Panier</BreadcrumbItem>
                        <BreadcrumbItem>Adresse</BreadcrumbItem>
                        <BreadcrumbItem active>Paiement</BreadcrumbItem>
                    </Breadcrumb>
                    <Row xs='1' sm='2'>
                        <Col>
                            <div style={{ margin: '1em 0' }}>
                                <h5>Commande</h5>
                                <h6>{this.context.articleDesignation.toUpperCase()}</h6>
                                <p style={{ color: 'gray' }}>
                                    Quantite : {this.context.quantite} <br />
                                    Total : {(this.context.articleCout * this.context.quantite) + " FCFA"}
                                </p>
                            </div>
                            <div style={{ margin: '1em 0' }}>
                                <h6>{this.context.nom + ' ' + this.context.prenoms}</h6>
                                <p style={{ color: 'gray' }}>
                                    {this.context.paysDesignation + ',' + this.context.villeDesignation + ',' + this.context.communeDesignation}<br />
                                    {this.context.adresse} <br />
                                    {this.context.telephone}
                                </p>
                            </div>
                        </Col>

                        <Col>
                            <div style={{ margin: '1em 0' }}>
                                <h5>Paiement</h5>
                                {
                                    (this.context.articleCategorie === 'BIEN') &&


                                    <div style={{ margin: '1em 0' }}>
                                        <Input type="radio" name="typePaiement" value="CASH_A_LA_LIVRAISON" checked={this.state.typePaiement == "CASH_A_LA_LIVRAISON"} onChange={this.handleChange} style={{ marginLeft: '0', position: 'inherit' }} /> Payer cash à la livraison <br />
                                        <Input type="radio" name="typePaiement" value="EN_LIGNE" checked={this.state.typePaiement == "EN_LIGNE"} onChange={this.handleChange} style={{ marginLeft: '0', position: 'inherit' }} /> Payer en ligne
                                    </div>
                                }
                                {
                                    (this.state.typePaiement == 'EN_LIGNE') &&

                                    <div>
                                        <h6>Choisissez un opérateur</h6>
                                        <div style={{ display: "grid", gridTemplateColumns: "auto auto auto" }}>
                                            <FormGroup check inline>
                                                <Label check style={{ margin: "0 auto" }}>
                                                    <Input type="radio" name="operateur" value="OM_SKAN" checked={this.state.operateur == "OM_SKAN"} onChange={this.handleChange} /> <img src={logoOrange} alt="Orange Money" width="50px" style={{ borderRadius: "50%" }}></img>
                                                </Label>
                                            </FormGroup>
                                            {/*<FormGroup check inline>
                                                <Label check style={{ margin: "0 auto" }}>
                                                    <Input type="radio" name="operateur" value="MOMO_SKAN" checked={this.state.operateur == "MOMO_SKAN"} onChange={this.handleChange} /> <img src={logoMTN} width="50px" style={{ borderRadius: "50%" }}></img>
                                                </Label>
                                            </FormGroup>
                                            <FormGroup check inline>
                                                <Label check style={{ margin: "0 auto" }}>
                                                    <Input type="radio" name="operateur" value="MOOV_SKAN" checked={this.state.operateur == "MOOV_SKAN"} onChange={this.handleChange} /> <img src={logoMoov} width="50px" style={{ borderRadius: "50%" }}></img>
                                                </Label>
                                            </FormGroup>*/}
                                        </div>
                                        <h6 style={{ marginTop: "1rem" }}>Effectuez le paiement</h6>
                                        <div>
                                            {this.state.operateur == "OM_SKAN" ?
                                                <FormGroup>
                                                    <Label for="token">Code de sécurité</Label>
                                                    <Input type="text" name="token" id="token" placeholder="Saisissez le CODE reçu" onChange={this.handleChange} />
                                                    <Label for="token" style={{ fontSize: "0.8rem", color: 'red' }}>Tapez #144*82# pour obtenir un code</Label>
                                                </FormGroup>
                                                : ''}
                                            <FormGroup>
                                                <Label for="numero">Numéro</Label>
                                                <Input type="text" name="numero" id="numero" placeholder="Ex: 07682326" onChange={this.handleChange} />
                                            </FormGroup>
                                        </div>
                                    </div>
                                }

                                <Row xs='1' sm='2'>
                                    <Col>
                                        <span onClick={this.props.history.goBack} style={{ cursor: 'pointer' }} disabled={this.state.sendingConsultation}>
                                            <FontAwesomeIcon icon={faChevronLeft} style={{ fontSize: "0.9rem", marginRight: '0.5rem' }} />Retour
                                        </span>
                                    </Col>
                                    <Col style={{ textAlign: 'right' }}>
                                        <Button className="action-button" onClick={this.payer} disabled={this.state.sendingConsultation}>
                                            CONFIRMER COMMANDE
                                            &nbsp;
                                                    <Loader style={{ display: this.state.sendingConsultation ? 'inline' : 'none' }}
                                                type="Puff"
                                                color="#FFFFFF"
                                                height={25}
                                                width={25}
                                            />
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}