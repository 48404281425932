import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Container, Form, FormGroup, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faPen, faPlus, faLock, faUserTimes } from '@fortawesome/free-solid-svg-icons';
import Loader from 'react-loader-spinner';
import AppNavbar from './AppNavbar';
import Setting from './Setting';

import { HttpGET, HttpPOST, HttpDELETE } from "./service/HttpService";
import { estConnecte } from './service/UserService';

import './Compte.css';

class Compte extends Component {
    constructor(props) {
        super(props);

        this.state = {
            patients: [],

            username: localStorage.getItem('username'),
            numero: localStorage.getItem('numero'),
            password: '',
            newPassword: '',
            confirmationNewPassword: '',

            modalSuppression: false,
            patientASupprimerId: 0,
            message: '',

            modalNumero: false,
            modalPassword: false
        };

        if (!estConnecte()) {
            this.props.history.push('/identification');
            localStorage.setItem('pageSuivante', '/compte');
            return;
        }

        if (!(localStorage.getItem("patient") == "true")) {
            this.props.history.replace('/profil');
        }

        this.handleChange = this.handleChange.bind(this);
        this.afficherEnregistrerPatient = this.afficherEnregistrerPatient.bind(this);
        this.afficherModifierPatient = this.afficherModifierPatient.bind(this);
        this.supprimerPatient = this.supprimerPatient.bind(this);
        this.afficherSupprimerPatient = this.afficherSupprimerPatient.bind(this);
        this.toggle = this.toggle.bind(this);
        this.toggleModalNumero = this.toggleModalNumero.bind(this);
        this.toggleModalPassword = this.toggleModalPassword.bind(this);

        this.modifierNumero = this.modifierNumero.bind(this);
        this.modifierPassword = this.modifierPassword.bind(this);
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    toggle() {
        this.setState({
            modalSuppression: !this.state.modalSuppression
        });
    }

    toggleModalNumero() {
        this.setState({
            modalNumero: !this.state.modalNumero
        });
    }

    toggleModalPassword() {
        this.setState({
            modalPassword: !this.state.modalPassword
        });
    }

    afficherEnregistrerPatient() {
        this.props.history.push("/profil");
    }

    afficherModifierPatient(id) {
        this.props.history.push("/profil/" + id);
    }

    rechercherPatients() {
        HttpGET('/medicinemanagement-api/rechercherpatients', function (data) {
            this.setState({
                patients: data.donnee
            });
        }.bind(this));
    }

    afficherSupprimerPatient(p) {
        this.setState({
            modalSuppression: true,
            patientASupprimerId: p.id,
            message: 'Souhaitez-vous supprimer le patient ' + p.nom + ' ' + p.prenoms + ' de votre compte SkanMed ?'
        });
    }

    supprimerPatient(id) {
        HttpDELETE('/medicinemanagement-api/supprimerpatient/' + id, function (data) {
            this.toggle();
            this.rechercherPatients();
        }.bind(this));
    }

    modifierNumero(){
        var requete = {
            numero: this.state.numero
        };

        this.setState({
            sending: true
        });
        HttpPOST('/medicinemanagement-api/modifiernumero', requete, function (data) {
            this.setState({
                sending: false,
                modalNumero: false
            });
            localStorage.setItem("numero", this.state.numero);
        }.bind(this));
    }

    modifierPassword(){
        if(this.state.newPassword !== this.state.confirmationNewPassword){
            this.setState({
                messageErreur: 'Vérifiez votre nouveau mot de passe'
            });
            return;
        }

        var requete = {
            password: this.state.password,
            newPassword: this.state.newPassword
        };

        this.setState({
            sending: true
        });
        HttpPOST('/medicinemanagement-api/modifiermotdepasse', requete, function (data) {
            if((data.statut != undefined) && (data.statut != null) && (data.statut != 0)){
                this.setState({
                    sending: false,
                    messageErreur: data.donnee
                });
                return;
            }

            this.setState({
                sending: false,
                modalPassword: false
            });
        }.bind(this));
    }

    componentDidMount() {
        this.rechercherPatients();
    }

    render() {
        const patients = this.state.patients.map((p) =>
            <div className="account-patient" key={p.id}>
                <div className="initial" onClick={this.afficherModifierPatient.bind(this, p.id)}>{p.prenoms.slice(0, 1)}</div>
                <div onClick={this.afficherModifierPatient.bind(this, p.id)}>
                    <div>{p.prenoms + ' ' + p.nom}</div>
                    <div>{p.dateDeNaissance}</div>
                </div>
                {p.estDefaut ?
                    <div style={{ textAlign: "right" }}>compte par defaut</div> :
                    <div className="text-right">
                        <FontAwesomeIcon icon={faUserTimes} onClick={this.afficherSupprimerPatient.bind(this, p)} />
                    </div>
                }
            </div>
        );
        return (
            <div>
                <AppNavbar />
                <Container className="account-container">
                    <h2 className="text-center" style={{ paddingTop: '30px', paddingBottom: '25px' }}>Parametrage du compte</h2>

                    <Setting title="Patients" icon={faUsers} isOpen={true}>
                        {patients}
                        <div className="add-patient" onClick={this.afficherEnregistrerPatient}>
                            <FontAwesomeIcon icon={faPlus} style={{ marginRight: "5px" }} /> Ajouter un proche
                        </div>
                    </Setting>
                    <Setting title="Identifiants" icon={faLock} isOpen={false}>
                        <Form>
                            <div className="account-credential">
                                <div>
                                    <div>E-mail</div>
                                    <div>{this.state.username}</div>
                                </div>
                            </div>
                            <div className="account-credential">
                                <div>
                                    <div>Téléphone</div>
                                    <div>{this.state.numero}</div>
                                </div>
                                <div className="text-right">
                                    <FontAwesomeIcon icon={faPen} onClick={this.toggleModalNumero} style={{ cursor: 'pointer' }}/>
                                </div>
                            </div>
                            <div className="account-credential last">
                                <div>
                                    <div>Mot de passe</div>
                                    <div>******</div>
                                </div>
                                <div className="text-right">
                                    <FontAwesomeIcon icon={faPen} onClick={this.toggleModalPassword} style={{ cursor: 'pointer' }}/>
                                </div>
                            </div>
                        </Form>
                    </Setting>
                </Container>

                <Modal isOpen={this.state.modalPassword} toggle={this.toggleModalPassword}>
                    <ModalHeader toggle={this.toggleModalPassword}>Modifier votre mot de passe</ModalHeader>
                    <ModalBody>
                    {this.state.messageErreur && <div style={{ color: "red", textAlign: "center", fontSize: '0.8rem' }}>{this.state.messageErreur}</div>}
                        <FormGroup>
                            <Label>Ancien mot de passe</Label>
                            <Input type="password" name="password" value={this.state.password} onChange={this.handleChange} />
                        </FormGroup>
                        <FormGroup>
                            <Label>Nouveau mot de passe</Label>
                            <Input type="password" name="newPassword" value={this.state.newPassword} onChange={this.handleChange} />
                        </FormGroup>
                        <FormGroup>
                            <Label>Confirmer nouveau mot de passe</Label>
                            <Input type="password" name="confirmationNewPassword" value={this.state.confirmationNewPassword} onChange={this.handleChange} />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.modifierPassword}>
                            Enregistrer
                            &nbsp;
                            <Loader style={{ display: this.state.sending ? 'inline' : 'none' }}
                                type="Puff"
                                color="#FFFFFF"
                                height={25}
                                width={25}
                            />
                        </Button>{' '}
                        <Button color="secondary" onClick={this.toggleModalPassword}>Annuler</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.modalNumero} toggle={this.toggleModalNumero}>
                    <ModalHeader toggle={this.toggleModalNumero}>Modifier votre numéro</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label>Numéro</Label>
                            <Input type="text" name="numero" value={this.state.numero} placeholder="Nom" onChange={this.handleChange} />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.modifierNumero}>
                            Enregistrer
                            &nbsp;
                            <Loader style={{ display: this.state.sending ? 'inline' : 'none' }}
                                type="Puff"
                                color="#FFFFFF"
                                height={25}
                                width={25}
                            />
                        </Button>{' '}
                        <Button color="secondary" onClick={this.toggleModalNumero}>Annuler</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.modalSuppression} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>Suppression d'un patient</ModalHeader>
                    <ModalBody>
                        {this.state.message}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.supprimerPatient.bind(this, this.state.patientASupprimerId)}>Supprimer</Button>{' '}
                        <Button color="secondary" onClick={this.toggle}>Annuler</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default withRouter(Compte);