import React, { Component } from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import Recherche from './Recherche';

import { ActivationContext } from './context';

import { HttpGET, HttpPOST, HttpDELETE } from "./service/HttpService";

export default class WizardConsultationMotif extends Component {
    static contextType = ActivationContext;

    constructor(props) {
        super(props);

        this.state = {
            suggestionContentValue: '',
            suggestionContent: null,
            motifs: []
        }

        this.choisirMotif = this.choisirMotif.bind(this);
        this.choisirMotifAffiche = this.choisirMotifAffiche.bind(this);
        this.onContentChosen = this.onContentChosen.bind(this);
        this.onSuggestionContentChange = this.onSuggestionContentChange.bind(this);
    }

    onContentChosen(content) {
        this.setState({
            suggestionContent: content
        });
    }

    onSuggestionContentChange(suggestion) {
        this.setState({
            suggestionContentValue: suggestion
        });
    }

    choisirMotif(motif) {
        if (this.state.suggestionContentValue == '') {
            return;
        }
        if ((this.state.suggestionContent == undefined) || (this.state.suggestionContent == null)) {
            var requete = {
                designation: this.state.suggestionContentValue
            };

            HttpPOST("/medicinemanagement-api/ajoutermotif", requete, function (data) {
                if (data.statut != 0) {
                    return;
                }

                this.context.activateNextTab();
                this.props.onChoose(data.donnee);

                this.setState({
                    suggestionContentValue: "",
                    suggestionContent: null
                });
            }.bind(this));
        }
        else {
            this.context.activateNextTab();
            this.props.onChoose(this.state.suggestionContent);

            this.setState({
                suggestionContentValue: "",
                suggestionContent: null
            });
        }
    }

    choisirMotifAffiche(motif) {
        this.setState({
            motif: motif.id
        });

        this.context.activateNextTab();
        this.props.onChoose(motif);
    }

    componentDidMount() {
        HttpGET('/medicinemanagement-api/search/motifspatient', function (data) {
            this.setState({
                motifs: data
            });
        }.bind(this));
    }

    render() {
        const motifs = this.state.motifs.map(m =>
            <FormGroup check sm={12} key={m.id}>
                <Label check>
                    <Input type="radio" name='motif' value={m.id} checked={this.state.motif === m.id} onChange={this.choisirMotifAffiche.bind(this, m)} /> {m.designation}
                </Label>
            </FormGroup>
        );
        return (
            <div>
                {/*<ActivationContext.Consumer>
                {({ activeTab, activateTab, activateNextTab }) => (
                    <Recherche type="motif"
                        suggestionValue={this.state.suggestionContentValue}
                        placeholder="Motif..."
                        onSuggestionChange={this.onSuggestionContentChange}
                        onSuggestionChosen={this.onContentChosen}
                        onClick={this.choisirMotif}
                url="/medicinemanagement-api/search/motifs?filtre=" />

                        
                )}
            </ActivationContext.Consumer>*/


                }

                <ActivationContext.Consumer>
                    {({ activeTab, activateTab, activateNextTab }) => (
                        <div>
                            {motifs}
                        </div>
                    )}
                </ActivationContext.Consumer>

            </div>
        )
    }
}