import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faMicrophoneSlash, faVideo, faVideoSlash } from '@fortawesome/free-solid-svg-icons';

import { ActivationContext } from './context';

import { estConnecte } from './service/UserService';

const mediaStreamConstraints = {
    video: true,
    audio: true
};

export default class WizardConsultationPrerequis extends Component {
    static contextType = ActivationContext;

    constructor(props) {
        super(props);

        this.state = {
            isAuthourized: false
        }

        this.autoriserMedia = this.autoriserMedia.bind(this);
    }

    choisirPatient(patient) {
        this.context.activateNextTab();
        this.props.onChoose(patient)
    }

    componentDidMount() {
        if (!estConnecte()) {
            return;
        }
        if (this.props.media === null) {
            try {
                navigator.mediaDevices.getUserMedia(mediaStreamConstraints)
                    .then((media) => {
                        this.props.onAuthorise(media);
                        this.setState({
                            isAuthourized: true
                        });
                        this.props.onChoose(false);
                    }).catch((err) => {
                        console.log(err);
                        this.setState({
                            isAuthourized: false
                        })
                    });
            }
            catch (e) {
                this.setState({
                    isAuthourized: false
                })
            }
        }
        else {
            this.setState({
                isAuthourized: true
            });
            this.props.onChoose(false);
        }
    }

    autoriserMedia() {
        
        if (this.props.media === null) {
            try{
                navigator.mediaDevices.getUserMedia(mediaStreamConstraints)
                .then((media) => {
                    this.props.onAuthorise(media);
                    this.setState({
                        isAuthourized: true
                    })
                    this.context.activateNextTab();
                    this.props.onChoose(true);
                }).catch((err) => {
                    console.error(`${err.name}: ${err.message}`);
                    this.setState({
                        isAuthourized: false
                    })
                });
            }
            catch(e){
                this.setState({
                    isAuthourized: false
                })
            }
        }
        else {
            this.setState({
                isAuthourized: true
            })
            this.context.activateNextTab();
            this.props.onChoose(true);
        }
    }

    render() {
        return (
            <ActivationContext.Consumer>
                {({ activeTab, activateTab, activateNextTab }) => (
                    <div>
                        <div style={{ display: 'grid', gridTemplateColumns: 'auto auto' }}>
                            <div style={{ textAlign: 'center' }}>
                                <FontAwesomeIcon icon={this.state.isAuthourized ? faMicrophone : faMicrophoneSlash} size="2x" />
                                <div style={{ marginTop: "10px" }}>Microphone</div>
                            </div>
                            <div style={{ textAlign: 'center' }}>
                                <FontAwesomeIcon icon={this.state.isAuthourized ? faVideo : faVideoSlash} size="2x" />
                                <div style={{ marginTop: "10px" }}>Camera</div>
                            </div>
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            <Button className="search-button" style={{ backgroundColor: "#F9BA14", borderColor: "#F9BA14" }} onClick={this.autoriserMedia}>{this.state.isAuthourized ? "Suivant" : "Autoriser"}</Button>
                        </div>
                    </div>
                )}
            </ActivationContext.Consumer>
        )
    }
}