import React, { Component } from 'react';
import { Container, Row, Col, Input, Button, Breadcrumb, BreadcrumbItem, Form, FormGroup, Label, FormFeedback } from 'reactstrap';
import Loader from 'react-loader-spinner';
import AppNavbar from './AppNavbar';

import { PaiementContext } from './context';

import { HttpGET, HttpPOST, HttpDELETE, baseUrl } from './service/HttpService';

import teleconsultation from './assets/img/teleconsultation.png';
import kitsante from './assets/img/kitsante.png';
import { roundToNearestMinutesWithOptions } from 'date-fns/esm/fp';

export default class CommandePanier extends Component {
    static contextType = PaiementContext;

    constructor(props) {
        super(props);

        this.state = {
            estQuantiteValide: true
        }

        this.commander = this.commander.bind(this);
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    commander() {
        let estUnEntier = this.context.modifierPanier(this.state.quantite);
        this.setState({
            estQuantiteValide: estUnEntier
        })
        if(estUnEntier){
            this.props.history.push('/commandeprestation/adresse/' + this.context.articleCode);
        }
    }

    componentDidMount() {
        this.setState({
            quantite: this.context.quantite
        });

        if (this.context.prestationChargee) {
            this.setState({
                articleDesignation: this.context.articleDesignation.toUpperCase(),
                articleDescription: this.context.articleDescription,
                articleCout: this.context.articleCout,
                articleCoutAffiche: this.state.articleCoutAffiche,
                articlePhotoId: this.context.articlePhotoId
            });
        }
        else {
            HttpGET('/medicinemanagement-api/search/rechercherprestation/' + this.context.articleCode, function (data) {

                if (data.statut !== 0) {
                    return;
                }

                this.setState({
                    articleCategorie: data.donnee.categorie,
                    articleDesignation: data.donnee.designation.toUpperCase(),
                    articleDescription: data.donnee.description,
                    articleCout: data.donnee.cout,
                    articleCoutAffiche: data.donnee.coutAffiche,
                    articlePhotoId: data.donnee.photoId
                });

                this.context.chargerPrestation(data.donnee);
            }.bind(this));
        }


    }

    render() {
        return (

            <PaiementContext.Consumer>
                {({ articleCode, quantite, nom, prenoms, telephone, email, villeId, communeId, adresse, typePaiement, operateur, token, numero, modifierPanier, modifierAdresse, commander }) => (
                    <div>
                        <AppNavbar />
                        <Container style={{ marginTop: '30px' }}>
                            <Breadcrumb>
                                <BreadcrumbItem active>Panier</BreadcrumbItem>
                                <BreadcrumbItem>Adresse</BreadcrumbItem>
                                <BreadcrumbItem>Paiement</BreadcrumbItem>
                            </Breadcrumb>
                            <Row xs='1' sm='2'>
                                <Col className='showcase-img'>
                                    <img src={baseUrl + '/medicinemanagement-api/fichier/' + this.state.articlePhotoId} style={{ width: '90%' }}></img>
                                </Col>
                                <Col style={{ marginTop: '20px' }}>
                                    <h3 style={{ margin: '1em 0', color: '#344B7B' }}>{this.state.articleDesignation}</h3>
                                    <p style={{ color: 'gray' }}>
                                        {this.state.articleDescription}
                                    </p>
                                    <h4 style={{ margin: '1em 0' }}>{this.state.articleCoutAffiche}</h4>
                                    {
                                        (this.state.articleCategorie === 'BIEN') &&

                                        <FormGroup>
                                            <Label for="quantite">Quantité</Label>
                                            <Input invalid={!this.state.estQuantiteValide} type="number" name="quantite" value={this.state.quantite} onChange={this.handleChange} />
                                            <FormFeedback invalid={!this.state.estQuantiteValide}>Vous devez saisir un nombre superieur à 0</FormFeedback>
                                        </FormGroup>
                                    }
                                    <Button className='action-button' onClick={this.commander} disabled={this.state.sending}>
                                        COMMANDER
                                        &nbsp;
                                        <Loader style={{ display: this.state.sending ? 'inline' : 'none' }}
                                            type="Puff"
                                            color="#FFFFFF"
                                            height={25}
                                            width={25}
                                        />
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                )}
            </PaiementContext.Consumer>
        )
    }
}