import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Link, NavLink } from 'react-router-dom';

import CommandePanier from './CommandePanier';
import CommandeAdresse from './CommandeAdresse';
import CommandePaiement from './CommandePaiement';
import CommandeResume from './CommandeResume';

import { PaiementContext } from './context';

import { HttpGET, HttpPOST, HttpDELETE, baseUrl } from './service/HttpService';
import { estConnecte } from './service/UserService';


export default class CommandePrestation extends Component{
    constructor(props){
        super(props);

        this.chargerPrestation = (prestation) => {
            this.setState({
                articleCategorie: prestation.categorie,
                articleDesignation: prestation.designation,
                articleDescription: prestation.description,
                articleCout: prestation.cout,
                coutAffiche: prestation.coutAffiche,
                articlePhotoId: prestation.photoId,

                prestationChargee: true
            })
        }

        this.modifierPanier = (quantite) => {
            //Vérifier la quantité
            if(isNaN(quantite) || (quantite <= 0)){
                return false;
            }

            //Modifier la quantité
            this.setState({
                quantite: quantite
            });

            return true;
        }

        this.modifierAdresse = (adresse) => {
            //Vérifier l'adresse
            if(!adresse.nom ||
               !adresse.prenoms ||
               !adresse.telephone ||
               !adresse.email){
                   return false;
               }

            //Modifier l'adresse
            this.setState({
                nom: adresse.nom,
                prenoms: adresse.prenoms,
                telephone: adresse.telephone,
                email: adresse.email,
                paysId: adresse.paysId,
                paysDesignation: adresse.paysDesignation,
                villeId: adresse.villeId,
                villeDesignation: adresse.villeDesignation,
                communeId: adresse.communeId,
                communeDesignation: adresse.communeDesignation,
                adresse: adresse.adresse,

                pays: adresse.pays,
                villes: adresse.villes,
                communes: adresse.communes,
                localisationsChargees: true
            });

            return true;
        }

        this.commander = (paiement) => {
            this.setState({
                typePaiement: paiement.typePaiement,
                operateur: paiement.operateur,
                token: paiement.token,
                numero: paiement.numero
            })
        }

        const { params } = this.props.match;

        this.state = {
            articleCode: params.id,
            articleCategorie: '',
            articleDesignation: '',
            articleDescription: '',
            articleCout: 0,
            coutAffiche: '',
            articlePhotoId: 0,
            prestationChargee: false,
            quantite: 1,
            nom: '',
            prenoms: '',
            telephone: '',
            email: '',
            pays: [],
            paysId: 0,
            paysDesignation: '',
            villes: [],
            villeId: 0,
            villeDesignation: '',
            communes: [],
            communeId: 0,
            communeDesignation: '',
            localisationsChargees: false,
            adresse: '',
            typePaiement: '',
            operateur: '',
            token: '',
            numero: '',

            chargerPrestation: this.chargerPrestation,
            modifierPanier: this.modifierPanier,
            modifierAdresse: this.modifierAdresse,
            commander: this.commander
        }
    }

    componentDidMount(){
        const { params } = this.props.match;

        if (!estConnecte()) {
            localStorage.setItem('pageSuivante', '/commandeprestation/' + params.id);
            this.props.history.push('/identification');
            return;
        }
        
        this.props.history.replace('/commandeprestation/' + params.id);
    }

    render(){
        return(
            <PaiementContext.Provider value={this.state}>
                <div>
                    <Switch>
                        <Route path='/commandeprestation/:id' exact={true} component={CommandePanier}/>
                        <Route path="/commandeprestation/panier/:id" component={CommandePanier} />
                        <Route path="/commandeprestation/adresse/:id" component={CommandeAdresse} />
                        <Route path="/commandeprestation/paiement/:id" component={CommandePaiement} />
                        <Route path="/commandeprestation/resume/:id" component={CommandeResume} />
                    </Switch>
                </div>
            </PaiementContext.Provider>
        )
    }
}