import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { withRouter, Link } from 'react-router-dom';
import { Container, Nav, NavItem, NavLink, UncontrolledDropdown, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, TabContent, TabPane, Row, Col, Card, CardTitle, CardText, FormGroup, Label, Button, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHospitalUser, faStethoscope, faPlus, faFileDownload, faTimes, faEdit, faChevronLeft, faBook, faFile, faHeadSideCough, faDiagnoses, faPills, faVial } from '@fortawesome/free-solid-svg-icons';
import Rating from '@mui/material/Rating';
import Box from '@mui/material/Box';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';

import AppNavbar from './AppNavbar';

import { HttpGET, HttpPOST, HttpDELETE, baseUrl } from './service/HttpService';
import { estConnecte } from './service/UserService';

const labels = {
    1: 'Insatisfait',
    2: 'Satisfait',
    3: 'Très satisfait',
};

const icons = {
    1: <SentimentVeryDissatisfiedIcon color="error" sx={{ fontSize: '40px' }} />,
    2: <SentimentSatisfiedAltIcon color="warning" sx={{ fontSize: '40px' }} />,
    3: <SentimentVerySatisfiedIcon color="success"sx={{ fontSize: '40px' }} />,
};

// const StyledRating = styled(Rating)({
//     '& .MuiRating-iconFilled': {
//       color: '#ff6d75',
//     },
//     '& .MuiRating-iconHover': {
//       color: '#F9BA14',
//     },
// });

const StyledRating = styled(Rating)(({ theme }) => ({
    '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
        color: theme.palette.action.disabled,
    },
}));

const customIcons = {
    1: {
        icon: <SentimentVeryDissatisfiedIcon color="error" sx={{ fontSize: '45px' }} />,
        label: 'Very Dissatisfied',
    },
    2: {
        icon: <SentimentSatisfiedAltIcon color="warning" sx={{ fontSize: '45px' }} />,
        label: 'Satisfied',
    },
    3: {
        icon: <SentimentVerySatisfiedIcon color="success" sx={{ fontSize: '45px' }} />,
        label: 'Very Satisfied',
    },
};

function IconContainer(props) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value].icon}</span>;
}

class DetailConsultation extends Component {
    constructor(props) {
        super(props);

        const { params } = this.props.match;

        this.state = {
            id: params.id,
            histoire: null,
            observation: null,

            patient: {},
            symptomes: [],
            diagnostics: [],
            medicaments: [],
            examens: [],
            bilanRealise: [],
            traitementEnCours: [],

            value: '',
            hover: '',
            avisEnvoye: false
        }

        this.retourner = this.retourner.bind(this);
        this.telechargerOrdonnance = this.telechargerOrdonnance.bind(this);
        this.telechargerBilan = this.telechargerBilan.bind(this);
    }

    retourner(){
        this.props.history.goBack();
    }

    telechargerOrdonnance(){
        const { params } = this.props.match;

        document.location = baseUrl + '/medicinemanagement-api/telechargerordonnance/' + params.id + '?stomp-jwt-token=' + localStorage.getItem("accessToken");
    }

    telechargerBilan(){
        const { params } = this.props.match;

        document.location = baseUrl + '/medicinemanagement-api/telechargerbilan/' + params.id + '?stomp-jwt-token=' + localStorage.getItem("accessToken");
    }

    soumettreSatifaction () {

    }

    componentDidMount() {
        const { params } = this.props.match;

        if (!estConnecte()) {
            localStorage.setItem('pageSuivante', '/detailconsultation/' + params.id);
            this.props.history.push('/identification');
            return;
        }

        // Afficher la liste des patients dans la salle d'attente
        HttpGET('/medicinemanagement-api/rechercherrapportconsultation/' + params.id, function (data) {
            if ((data.donnee !== undefined) && (data.donnee !== null)) {
                this.setState({
                    patient: (data.donnee.patient !== null) && (data.donnee.patient !== undefined) ? data.donnee.patient : {},
                    symptomes: (data.donnee.symptomes !== null) && (data.donnee.symptomes !== undefined) ? data.donnee.symptomes : [],
                    diagnostics: (data.donnee.diagnostics !== null) && (data.donnee.diagnostics !== undefined) ? data.donnee.diagnostics : [],
                    medicaments: (data.donnee.medicaments !== null) && (data.donnee.medicaments !== undefined) ? data.donnee.medicaments : [],
                    examens: (data.donnee.examens !== null) && (data.donnee.examens !== undefined) ? data.donnee.examens : [],
                    bilanRealise: (data.donnee.bilanRealise !== null) && (data.donnee.bilanRealise !== undefined) ? data.donnee.bilanRealise : [],
                    traitementEnCours: (data.donnee.traitementEnCours !== null) && (data.donnee.traitementEnCours !== undefined) ? data.donnee.traitementEnCours : [],
                    observation: data.donnee.observation
                });
            }
        }.bind(this));
    }

    render() {
        return (
            <div>
                <AppNavbar />
                <Container className="account-container">
                    <h2 className="text-center" style={{ paddingTop: '30px', paddingBottom: '25px', color: '#344B7B', textDecoration: 'uppercase' }}>Rapport de consultation</h2>
                </Container>
                <div className="admin-menu-retour" onClick={this.retourner}>
                    <FontAwesomeIcon icon={faChevronLeft} style={{ fontSize: "0.9rem", marginRight: '0.5rem' }}/>Retour
                </div>
                <div className="admin-container" style={{ padding: '1rem' }}>
                    <Row style={{ margin: '1rem 0' }} xs='1' sm='2'>
                        <Col>
                            <h5><FontAwesomeIcon icon={faHospitalUser} style={{ fontSize: "0.9rem" }} /> &nbsp; Patient</h5>
                            <div>
                                <Row>
                                    <Col sm="3">Nom</Col>
                                    <Col sm="9">: <span className="consultation-informations-valeur">{this.state.patient.nom}</span></Col>
                                </Row>
                                <Row>
                                    <Col sm="3">Prenoms</Col>
                                    <Col sm="9">: <span className="consultation-informations-valeur">{this.state.patient.prenoms}</span></Col>
                                </Row>
                                <Row>
                                    <Col sm="3">Sexe</Col>
                                    <Col sm="9">: <span className="consultation-informations-valeur">{this.state.patient.sexe}</span></Col>
                                </Row>
                                <Row>
                                    <Col sm="3">Age</Col>
                                    <Col sm="9">: <span className="consultation-informations-valeur">{this.state.patient.age}</span></Col>
                                </Row>
                            </div>
                        </Col>
                        <Col>
                            <h5><FontAwesomeIcon icon={faStethoscope} style={{ fontSize: "0.9rem" }} /> &nbsp; Informations consultation</h5>
                            <div>
                                <Row>
                                    <Col sm="2">Date</Col>
                                    <Col sm="10">: <span className="consultation-informations-valeur">{this.state.patient.date}</span></Col>
                                </Row>
                                <Row>
                                    <Col sm="2">Motif</Col>
                                    <Col sm="10">: <span className="consultation-informations-valeur">{this.state.patient.motif}</span></Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Row className='d-none' style={{ margin: '1rem 0' }} xs='1' sm='2'>
                        <Col>
                        <h5><FontAwesomeIcon icon={faBook} style={{ fontSize: "0.9rem" }} /> &nbsp; Histoire de la maladie</h5>
                        <p>{this.state.patient.histoire}</p>
                        </Col>
                    </Row>
                    <Row className='d-none' style={{ margin: '1rem 0' }} xs='1' sm='2'>
                        <Col>
                            <h5><FontAwesomeIcon icon={faHeadSideCough} style={{ fontSize: "0.9rem" }} /> &nbsp; Symptomes</h5>
                            <div>
                                {
                                    this.state.symptomes.length > 0 ?
                                        this.state.symptomes.map((e) =>
                                            <div>
                                                <div style={{ fontSize: "0.9rem" }}>{e.designation}</div>
                                                <div style={{ fontSize: "0.7rem" }}>{e.detail}</div>
                                                <div style={{ fontSize: "0.7rem" }}>{e.observation}</div>
                                            </div>
                                        )
                                        :
                                        <p>
                                            Aucun symptome !!!
                                            </p>
                                }
                            </div>
                        </Col>
                        <Col>
                            <h5><FontAwesomeIcon icon={faDiagnoses} style={{ fontSize: "0.9rem" }} /> &nbsp; Diagnostic</h5>
                            <div>
                                {
                                    this.state.diagnostics.length > 0 ?
                                        this.state.diagnostics.map((e) =>
                                            <div>
                                                <div style={{ fontSize: "0.9rem" }}>{e.designation}</div>
                                                <div style={{ fontSize: "0.7rem" }}>{e.detail}</div>
                                                <div style={{ fontSize: "0.7rem" }}>{e.observation}</div>
                                            </div>
                                        )
                                        :
                                        <p>
                                            Aucun diagnostic !!!
                                            </p>
                                }
                            </div>
                        </Col>
                    </Row>
                    <Row className='d-none' style={{ margin: '1rem 0' }} xs='1' sm='2'>
                        <Col>
                            <h5><FontAwesomeIcon icon={faVial} style={{ fontSize: "0.9rem" }} /> &nbsp; Bilan réalisé</h5>
                            <div>
                                {
                                    this.state.bilanRealise.length > 0 ?
                                        this.state.bilanRealise.map((e) =>
                                            <div>
                                                <div style={{ fontSize: "0.9rem" }}>{e.designation}</div>
                                                <div style={{ fontSize: "0.7rem" }}>{e.detail}</div>
                                                <div style={{ fontSize: "0.7rem" }}>{e.observation}</div>
                                            </div>
                                        )
                                        :
                                        <p>
                                            Aucun examen réalisé !!!
                                            </p>
                                }
                            </div>
                        </Col>
                        <Col>
                            <h5><FontAwesomeIcon icon={faPills} style={{ fontSize: "0.9rem" }} /> &nbsp; Traitement en cours</h5>
                            <div>
                                {
                                    this.state.traitementEnCours.length > 0 ?
                                        this.state.traitementEnCours.map((e) =>
                                            <div>
                                                <div style={{ fontSize: "0.9rem" }}>{e.designation}</div>
                                                <div style={{ fontSize: "0.7rem" }}>{e.detail}</div>
                                                <div style={{ fontSize: "0.7rem" }}>{e.observation}</div>
                                            </div>
                                        )
                                        :
                                        <p>
                                            Aucun traitement en cours !!!
                                            </p>
                                }
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ margin: '1rem 0' }} xs='1' sm='2'>
                        <Col>
                            <h5><FontAwesomeIcon icon={faVial} style={{ fontSize: "0.9rem" }} /> &nbsp; Bilan prescrit</h5>
                            <div>
                                {
                                    this.state.examens.length > 0 ?
                                        this.state.examens.map((e) =>
                                            <div>
                                                <div style={{ fontSize: "0.9rem" }}>{e.designation}</div>
                                                <div style={{ fontSize: "0.7rem" }}>{e.detail}</div>
                                                <div style={{ fontSize: "0.7rem" }}>{e.observation}</div>
                                            </div>
                                        )
                                        :
                                        <p>
                                            Aucun examen prescrit !!!
                                            </p>
                                }
                            </div>
                            {
                                this.state.examens.length > 0 &&
                                <div>
                                    <br/>
                                    <a href='javascript:;' onClick={this.telechargerBilan}><FontAwesomeIcon style={{ fontSize: "0.9rem" }} icon={faFileDownload}/> Télécharger bilan</a>
                                </div>
                            }
                        </Col>
                        <Col>
                            <h5><FontAwesomeIcon icon={faPills} style={{ fontSize: "0.9rem" }} /> &nbsp; Ordonnance prescrite</h5>
                            <div>
                                {
                                    this.state.medicaments.length > 0 ?
                                        this.state.medicaments.map((e) =>
                                            <div>
                                                <div style={{ fontSize: "0.9rem" }}>{e.designation}</div>
                                                <div style={{ fontSize: "0.7rem" }}>{e.detail}</div>
                                                <div style={{ fontSize: "0.7rem" }}>{e.observation}</div>
                                            </div>
                                        )
                                        :
                                        <p>
                                            Aucune ordonnance prescrite !!!
                                            </p>
                                }
                            </div>
                            {
                                this.state.medicaments.length > 0 &&
                                <div>
                                    <br/>
                                    <a href='javascript:;' onClick={this.telechargerOrdonnance}><FontAwesomeIcon style={{ fontSize: "0.9rem" }} icon={faFileDownload}/> Télécharger ordonnance</a>
                                </div>
                            }
                        </Col>
                    </Row>
                    {this.state.observation &&
                        <Row className='d-none' style={{ margin: '1rem 0' }} xs='1' sm='2'>
                            <Col>
                                <h5><FontAwesomeIcon icon={faEdit} style={{ fontSize: "0.9rem" }} /> &nbsp; Observation</h5>
                                <div style={{ fontSize: "0.9rem" }}>
                                    {this.state.observation}
                                </div>
                            </Col>
                        </Row>}
                </div>



                <Container className="account-container">
                    <h2 className="text-center" style={{ paddingTop: '30px', paddingBottom: '25px', color: '#F9BA14', textDecoration: 'uppercase' }}>Aidez-nous à améliorer SkanMed</h2>
                </Container>

                <div className="admin-container" style={{ padding: '1rem', marginBottom: '80px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <p className="text-center mt-2">Qu'avez-vous pensé de cette consultation ?</p>

                    <StyledRating
                        name="highlight-selected-only"
                        // defaultValue={2}
                        onChange={(event, newValue) => {
                            this.setState({
                                value: newValue
                            });
                        }}
                        onChangeActive={(event, newHover) => {
                            this.setState({
                                hover: newHover
                            });
                        }}
                        IconContainerComponent={IconContainer}
                        getLabelText={(value) => customIcons[value].label}
                        max={3}
                        highlightSelectedOnly
                    />
                    
                    {this.state.value !== null && (
                        <Box>
                            <p style={{ fontWeight: 'bold'}}>{labels[this.state.hover !== -1 ? this.state.hover : this.state.value]}</p>
                        </Box>
                    )}

                    <div style={{ display: 'flex', flexDirection: 'column', width: '400px' }}>
                    {!this.state.avisEnvoye && this.state.value === 1 ||  !this.state.avisEnvoye && this.state.value === 2 ? <textarea placeholder='Laisser un commentaire' style={{ width: '400px', height: '100px', borderRadius: '5px', padding: '5px' }} /> : null}
                        {!this.state.avisEnvoye ? <button className='mt-2 btn btn-avis-commentaire' onClick={this.soumettreSatifaction}>Envoyer</button> : null}
                    </div>
                </div>

                
            </div>
        )
    }
}

export default withRouter(DetailConsultation);