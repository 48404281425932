import React, { Component } from "react";
import { Button, InputGroup, InputGroupAddon } from "reactstrap";
import Loader from "react-loader-spinner";
import AppNavbar from "./AppNavbar";
import BarreDeRecherche from "./BarreDeRecherche";
import ResultatDeRecherche from "./ResultatDeRecherche";
import Modal from "react-modal";

import { HttpGET, HttpPOST } from "./service/HttpService";
import { estConnecte } from "./service/UserService";

import "./ListeResultatDeRecherche.css";

import iconeRechercheVide from "./assets/img/icone_web-07.png";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    zIndex: 999,
  },
};

// Modal.setAppElement(document.getElementById('root'));
Modal.defaultStyles.overlay.backgroundColor = "rgba(0, 0, 0, 0.5)";

export default class ListeResultatDeRecherche extends Component {
  constructor() {
    super();

    this.state = {
      suggestion: "",
      professionnels: [],

      searching: false,
      modalOpen: false,
    };

    this.onSuggestionChange = this.onSuggestionChange.bind(this);
    this.rechercherEntitee = this.rechercherEntitee.bind(this);
    this.rechercher = this.rechercher.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({ modalOpen: true });
  }

  closeModal() {
    this.setState({ modalOpen: false });
  }

  componentDidMount() {
    const { params } = this.props.match;

    if (params.filtre == null || params.filtre == undefined) {
      return;
    }

    this.setState({
      filtre: params.filtre,
    });

    // if (localStorage.getItem('initialDetailView')) {
    //   this.setState({ modalOpen: false })
    // } else {
    //     this.setState({ modalOpen: true })
    //     localStorage.setItem('initialDetailView', true)
    // }

    this.rechercherEntitee(params.filtre);
  }

  onSuggestionChange(suggestion) {
    this.setState({
      suggestion: suggestion,
    });
  }

  rechercher(e) {
    e.preventDefault();
    if (this.state.suggestion == "") {
      return;
    }
    this.rechercherEntitee(this.state.suggestion);
    this.props.history.replace(
      "/listeresultatderecherche/" + this.state.suggestion
    );
  }

  rechercherEntitee(filtre) {
    this.setState({
      searching: true,
    });
    HttpGET(
      "/medicinemanagement-api/search/entiteemedicales?filtre=" + filtre,
      function (data) {
        this.setState({
          searching: false,
        });
        this.setState({
          professionnels: data,
        });
      }.bind(this)
    );
  }

  render() {
    var professionnels = (
      <div style={{ textAlign: "center" }}>
        <img src={iconeRechercheVide} style={{ marginTop: "3rem" }}></img>
        <div style={{ fontSize: "1.5rem" }}>
          Désolé!!! Nous n'avons trouvé aucun professionnel / etablissement de
          santé de disponible correspondant à votre recherche
        </div>
      </div>
    );

    if (this.state.professionnels.length > 0) {
      professionnels = this.state.professionnels.map((p) => (
        <ResultatDeRecherche
          key={p.key}
          filtre={this.state.filtre}
          entitee={p}
          rechercheId={p.id}
          type={p.type}
          designation={p.designation}
          caracteristique={p.caracteristique}
          etablissement={p.etablissement}
          assurances={p.assurances}
          sexe={p.sexe}
          grade={p.grade}
        />
      ));
    }

    return (
      <div>
        <AppNavbar />
        <Modal
          isOpen={this.state.modalOpen}
        //   onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <h3 style={{ color: "#344B7B" }}>Rechercher sur SkanMed</h3>

          <div>I am a modal</div>
          <button onClick={this.closeModal}>x</button>
        </Modal>
        <div className="header_search">
          <div className="col-md-10 col-lg-8 col-xl-7 mx-auto">
            <InputGroup>
              <BarreDeRecherche
                onSuggestionChange={this.onSuggestionChange}
                className="searchbar-input form-control"
              />
              <InputGroupAddon addonType="append">
                <Button
                  className="search-button"
                  style={{
                    height: "50px",
                    width: "150px",
                    textAlign: "center",
                    verticalAlign: "center",
                  }}
                  color="secondary"
                  onClick={this.rechercher}
                >
                  Demarrer
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </div>
        </div>
        <div className="results">
          <h1
            style={{
              display: this.state.professionnels.length > 0 ? "block" : "none",
            }}
          >
            Trouvez un professionnel de santé pratiquant la consultation vidéo
          </h1>

          <div className="search">
            {this.state.searching ? (
              <Loader
                style={{
                  display: this.state.searching ? "block" : "none",
                  marginTop: "5rem",
                }}
                type="ThreeDots"
                color="#F9BA14"
                height={125}
                width={125}
              />
            ) : (
              <div className="search-results">{professionnels}</div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
