import React, { Component } from 'react';
import SockJsClient from 'react-stomp';

import { setMediaBitrates } from './service/WebRTCUtils.js';

export default class WebRTC extends Component {
    constructor(props) {
        super(props);

        this.state = {
            peerConnection: null,
            id: new Date().getTime(),

            sessionId: ''
        }

        this.onConnect = this.onConnect.bind(this);
        this.setSessionId = this.setSessionId.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.receiveMessage = this.receiveMessage.bind(this);
        this.hangup = this.hangup.bind(this);
        this.finish = this.finish.bind(this);

        this.createOffer = this.createOffer.bind(this);
        this.initializePeerConnection = this.initializePeerConnection.bind(this);
    }

    setSessionId() {
        var url = this.clientRef.client.ws._transport.url;
        url = url.split('?')[0];
        url = url.replace("ws://localhost:8081/medicinemanagement-api/secured/room/", "");
        url = url.replace("/websocket", "");
        url = url.replace(/^[0-9]+\//, "");
        console.log("Your current session is: " + url);

        this.setState({
            sessionId: url
        });
    }

    onConnect() {
        console.log('this.clientRef :', this.clientRef);
        this.initializePeerConnection();
        this.createOffer();
    }

    sendMessage = (msg) => {
        var message = {
            from: localStorage.getItem('username'),
            to: this.props.to,
            roomId: this.props.roomId,
            event: msg.event,
            text: JSON.stringify(msg)
        };
        
        try{
            this.clientRef.sendMessage('/spring-security-mvc-socket/secured/room', JSON.stringify(message));
        }
        catch(e){}
    }

    receiveMessage(message) {
        // Verifier que le message vient de la consultation en cours
        if(message.roomId!==this.props.roomId){
            return;
        }

        var reponse = message.text;
        var content = reponse != undefined ? JSON.parse(reponse) : message;

        // Verifier que l'interlocuteur veut raccrocher
        switch(content.event){
            case "hangup":
                this.hangup();
                break;
            case "finish":
                this.finish();
                break;
            default:
                break;
        }

        // Ne recoit pas de message quand connecté
        if (this.state.peerConnection.iceConnectionState == 'connected') {
            return;
        }

        switch (content.event) {
            // when somebody wants to call us
            case "offer":
                this.handleOffer(content.data);
                break;
            case "answer":
                this.handleAnswer(content.data);
                break;
            // when a remote peer sends an ice candidate to us
            case "candidate":
                this.handleCandidate(content.data);
                break;
            default:
                break;
        }
    }

    hangup(){
        /* Disconnect stomp */
        try{
            this.clientRef.disconnect();
        }
        catch(e){}

        /* Disconnect webrtc */
        try{
            this.state.peerConnection.close();
        }
        catch(e){}

        this.props.onHangUp();
    }

    finish(){
        /* Disconnect stomp */
        try{
            this.clientRef.disconnect();
        }
        catch(e){}

        /* Disconnect webrtc */
        try{
            this.state.peerConnection.close();
        }
        catch(e){}

        this.props.onFinish();
    }

    /* WebRTC management */
    createOffer() {
        this.state.peerConnection.createOffer(function (offer) {
            offer.sdp = setMediaBitrates(offer.sdp);

            this.sendMessage({
                event: "offer",
                data: offer
            });
            this.state.peerConnection.setLocalDescription(offer);
        }.bind(this), function (error) {
            alert("Error creating an offer");
        });
    }

    handleOffer(offer) {
        this.initializePeerConnection();
        this.state.peerConnection.setRemoteDescription(new RTCSessionDescription(offer))
            .then(
                function () {
                    // create and send an answer to an offer
                    this.state.peerConnection.createAnswer(function (answer) {
                        this.state.peerConnection.setLocalDescription(answer);

                        answer.sdp = setMediaBitrates(answer.sdp);

                        this.sendMessage({
                            event: "answer",
                            data: answer
                        });
                    }.bind(this),
                        function (error) {
                            console.log(error);
                            alert("Error creating an answer");
                        });
                }.bind(this)
            )

    };

    handleCandidate(candidate) {
        console.log("Handle candidate", candidate);
        this.state.peerConnection.addIceCandidate(new RTCIceCandidate(candidate));
    };

    handleAnswer(answer) {
        try{
            this.state.peerConnection.setRemoteDescription(new RTCSessionDescription(answer));
            console.log("connection established successfully!!");
        }
        catch(e){
            this.createOffer();
        }
        
    };

    initializePeerConnection() {
        //Configure peer connexion
        var configuration = {
            'iceServers': [
                {
                    'urls': 'stun:51.38.232.210:5349'
                },
                {
                    //'urls': 'turn:skanmed.com:5349?transport=tcp',
                    'urls': 'turn:51.38.232.210:5349',
                    /*'credential': 'skanmed',
                    'username': 'pwdskanmed2020'*/

                    'credential': 'pwdskanmed2020',
                    'username': 'skanmed'
                }
            ]
        };

        this.state.peerConnection = new RTCPeerConnection(configuration, {
            optional: [{
                RtpDataChannels: true
            }]
        });

        // Setup ice handling
        this.state.peerConnection.onicecandidate = function (event) {
            if (event.candidate) {
                this.sendMessage({
                    event: "candidate",
                    data: event.candidate
                });
            }
        }.bind(this);

        // Handle remote stream added
        this.state.peerConnection.onaddstream = function (event) {
            this.props.onLoadRemoteStream(event.stream);
        }.bind(this);

        // Handle remote connexion lost
        this.state.peerConnection.oniceconnectionstatechange = function () {
            if (this.state.peerConnection.iceConnectionState == 'disconnected') {
                this.props.onDisconnectRemote();
            }
        }.bind(this);

        try{
            this.state.peerConnection.addStream(this.props.stream);
            //this.props.stream.getTracks().forEach(track => this.state.peerConnection.addTrack(track, this.props.stream));
        }
        catch(e){
            console.log(e);
        }
    }

    componentDidMount() {
    }

    componentWillUnmount(){
        try{
            this.state.peerConnection.close();
        }
        catch(e){}
    }

    render() {
        const customHeaders = {
            "X-Authorization": localStorage.getItem("accessToken")
        };

        const stompUrl = 'https://skanmed.com/medicinemanagement-api/secured/room?stomp-jwt-token=' + localStorage.getItem("accessToken");
        //const stompUrl = 'http://localhost:8081/medicinemanagement-api/secured/room?stomp-jwt-token=' + localStorage.getItem("accessToken");
        const stompSubcribeUrl = '/user/queue/specific-user';

        return (
            <div>
                <SockJsClient
                    onConnect={this.onConnect}
                    url={stompUrl}
                    topics={[stompSubcribeUrl]}
                    onMessage={this.receiveMessage}
                    ref={(client) => { this.clientRef = client }} />
            </div>
        );
    }
}