import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Form, FormGroup, Input, InputGroup, Label, Button, Collapse } from 'reactstrap';
import Loader from 'react-loader-spinner';
import AppNavbar from './AppNavbar';

import { HttpGet, HttpPOST } from './service/HttpService';

import './Identification.css'

class ForgetPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            seConnecter: true,
            sending: false,
            messageErreurConnexion: null,
            messageErreurInscription: null,
            username: ""
        };

        this.seConnecter = this.seConnecter.bind(this);
        this.sEnregistrer = this.sEnregistrer.bind(this);
        this.reinitialiser = this.reinitialiser.bind(this);
    }

    handleChange = event => {
        const target = event.target;
        const value = (target.name === 'conditionDUtilisation') || (target.name === 'resterConnecte') ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }
    
    handleChangeEmailInput = event => {
        const target = event.target.value;

        this.setState({
            username: target
        });
    }

    seConnecter() {
        var requete = { username: this.state.username, password: this.state.password };

        this.setState({
            sending: true
        });
        HttpPOST('/medicinemanagement-api/signin', requete,
            function (data) {
                this.setState({
                    sending: false
                });
                localStorage.setItem('accessToken', data.accessToken);
                localStorage.setItem('username', data.username);
                localStorage.setItem('numero', data.numero);
                localStorage.setItem('patient', data.patient);

                if (localStorage.getItem("patient") == "true") {
                    if ((localStorage.getItem("pageSuivante") != null) && (localStorage.getItem("pageSuivante") != undefined)) {
                        this.props.history.replace(localStorage.getItem("pageSuivante"));

                        localStorage.removeItem("pageSuivante");
                    }
                    else {
                        this.props.history.replace('/compte');
                    }
                }
                else {
                    this.props.history.replace('/profil');
                }
            }.bind(this),
            function (data) {
                this.setState({
                    sending: false
                });
                if(data.status == 401){
                    this.setState({
                        messageErreurConnexion: "Verifiez votre Email et votre mot de passe!"
                    });
                }
            }.bind(this));
    }

    sEnregistrer() {
        //Vérifier la validité des champs
        if(this.state.password != this.state.confirmationPassword){
            this.setState({
                messageErreurInscription: "Vérifiez votre mot de passe!"
            });
            return;
        }

        //Envoyer la requete
        var requete = { numero: this.state.numero, username: this.state.username, password: this.state.password, conditionDUtilisation: this.state.conditionDUtilisation, resterConnecte: this.state.resterConnecte };

        this.setState({
            sending: true
        });
        HttpPOST('/medicinemanagement-api/signup', requete, function (data) {
            this.setState({
                sending: false
            });
            if((data.statut != undefined) && (data.statut != null) && (data.statut != 0)){
                this.setState({
                    messageErreurInscription: data.donnee
                });
                return;
            }
            localStorage.setItem('accessToken', data.accessToken);
            localStorage.setItem('username', data.username);
            localStorage.setItem('numero', data.numero);
            localStorage.setItem('patient', data.patient);

            this.props.history.replace('/profil');
        }.bind(this));
    }

    reinitialiser() {
        var requete = { username: this.state.username };
        
        this.setState({
            sending: true
        });

        HttpPOST('/medicinemanagement-api/reinitialiserpassword', requete, function (data) {
            if((data.statut == undefined) || (data.statut == null) || (data.statut != 0)){
                this.setState({
                    messageErreur: data.donnee,
                    sending: false
                });
                return;
            }

            this.props.history.replace('/identification');
        }.bind(this));
    }

    render() {
        return (
            <div className='identification-background'>
                <AppNavbar />
                <div className="dl-layout-body dl-layout-body-bottom dl-layout-body-size-small">
                    <div className="dl-card dl-card-bg-white dl-card-margin dl-card-padding">
                        <div className="dl-card-content">
                            <div className="dl-text dl-text-title-sub-regular dl-text-center">Réinitialiser mon mot de passe</div>
                            {this.state.messageErreurConnexion && <div style={{ color: "red", textAlign: "center", fontSize: '0.8rem' }}>{this.state.messageErreurConnexion}</div>}
                            <Collapse isOpen={this.state.seConnecter}>
                                <Form>
                                    <div className="dl-toggleable-form" style={{ maxHeight: "350px" }}>
                                        <FormGroup>
                                            <Input type="email" name="username" onChange={this.handleChangeEmailInput} placeholder="Adresse email" />
                                        </FormGroup>
                                    </div>
                                    <FormGroup>
                                        <Button className="dl-button-primary" color="primary" size="lg" style={{ textTransform: "uppercase" }} onClick={this.reinitialiser} disabled={this.state.sending} block>
                                            Réinitialiser
                                            &nbsp;
                                            <Loader style={{ display: this.state.sending ? 'inline' : 'none' }}
                                                type="Puff"
                                                color="#FFFFFF"
                                                height={25}
                                                width={25}
                                            />
                                        </Button>
                                    </FormGroup>
                                    <div class="txt-center">
                                        <a id="lienMotDePasse" class="Tappable-inactive dl-button-default-real-link dl-margin-y dl-button dl-button-size-normal" role="button" href="/identification" type="button">
                                            <span class="dl-button-label">Me connecter à mon compte</span>
                                        </a>
                                    </div>
                                </Form>
                            </Collapse>
                            <div style={{ textAlign: "center" }}>
                                {!this.state.seConnecter &&
                                    <Button color="link" className="lien-connexion" onClick={() => { this.setState({ seConnecter: true }) }}>
                                        Réinitialisation
                                    </Button>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dl-layout-body dl-layout-body-bottom dl-layout-body-size-small">
                    <div className="dl-card dl-card-bg-white dl-card-margin dl-card-padding">
                        <div className="dl-card-content">
                            <div class="dl-text dl-text-title-sub-regular dl-text-center">Nouveau sur SkanMed?</div>
                            <Collapse isOpen={!this.state.seConnecter}>
                                <Form>
                                    <div className="dl-toggleable-form" style={{ maxHeight: "700px" }}>
                                        <p class="dl-text dl-text-body dl-text-margin dl-text-center">Saisissez vos informations pour continuer.</p>
                                        {this.state.messageErreurInscription && <div style={{ color: "red", textAlign: "center", fontSize: '0.8rem' }}>{this.state.messageErreurInscription}</div>}
                                        <FormGroup>
                                            <Input type="text" name="numero" onChange={this.handleChange} placeholder="Téléphone portable" />
                                            <div class="dl-text dl-text-body-subtitle dl-input-hint">Votre numero servira à vous contacter en cas de besoin.</div>
                                        </FormGroup>
                                        <FormGroup>
                                            <Input type="email" name="username" onChange={this.handleChange} placeholder="votre adresse email" />
                                        </FormGroup>
                                        <FormGroup>
                                            <Input type="password" name="password" onChange={this.handleChange} placeholder="Mot de passe" />
                                        </FormGroup>
                                        <FormGroup>
                                            <Input type="password" name="confirmationPassword" onChange={this.handleChange} placeholder="Confirmez votre mot de passe" />
                                            <div class="dl-text dl-text-body-subtitle dl-input-hint">Votre mot de passe vous permettra de gérer vos consultations.</div>
                                        </FormGroup>
                                    </div>
                                    <hr class="dl-divider"></hr>
                                    <div className="dl-margin-y">
                                        <FormGroup check>
                                            <Label className="dl-checkbox-label" check>
                                                <Input type="checkbox" name="conditionDUtilisation" onChange={this.handleChange}/>{' '} <span class="dl-checkbox-label-text">J'accepte les <a href="/conditions/conditionsdutilisation"> Conditions d'Utilisation de SkanMed </a></span>
                                            </Label>
                                        </FormGroup>
                                        <FormGroup check>
                                            <Label className="dl-checkbox-label" check>
                                                <Input type="checkbox" name="resterConnecte" onChange={this.handleChange}/>{' '} <span class="dl-checkbox-label-text">Je souhaite rester connecté</span>
                                            </Label>
                                        </FormGroup>
                                    </div>
                                    <FormGroup>
                                        <Button className="dl-button-primary" color="primary" size="lg" onClick={this.sEnregistrer} disabled={this.state.sending} block>
                                            S'INSCRIRE
                                            &nbsp;
                                            <Loader style={{ display: this.state.sending ? 'inline' : 'none' }}
                                                type="Puff"
                                                color="#FFFFFF"
                                                height={25}
                                                width={25}
                                            />
                                        </Button>
                                    </FormGroup>
                                </Form>
                            </Collapse>
                            <div style={{ textAlign: "center" }}>
                                {this.state.seConnecter &&
                                    <Button color="link" className="lien-connexion" onClick={() => this.setState({ seConnecter: false })} >
                                        S'INSCRIRE
                                    </Button>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(ForgetPassword);