import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Collapse,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";

import "./AppNavbar.css";
import logo from "./assets/img/logo_skanmed.png";

class AppNavbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      username: localStorage.getItem("username"),
      estConnecte: localStorage.getItem("accessToken"),
    };

    this.toggle = this.toggle.bind(this);
    this.seDeconnecter = this.seDeconnecter.bind(this);
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  seDeconnecter() {
    this.setState({
      username: null,
      estConnecte: false,
    });

    localStorage.clear();
    this.props.history.push("/identification");
  }

  render() {
    return (
      <Navbar color="bleu" dark expand="md">
        <div className="d-flex align-items-center">
          <NavbarBrand className="mr-auto" tag={Link} to="/">
            <img src={logo} width="130px;" />
          </NavbarBrand>
          {this.state.estConnecte ? (
            <Nav navbar className="side-item">
              <NavItem>
                <NavLink className="dl-desktop-navbar-link" href="/dossier/consultationsencours">
                  <div style={{ color: "rgb(249, 186, 20)" }}>
                    Consultation en attente
                  </div>
                </NavLink>
              </NavItem>
            </Nav>
          ) : null}
        </div>
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={this.state.isOpen} navbar>
          {this.state.estConnecte ? (
            <Nav className="ml-auto" navbar>
              <Nav navbar className="side-item-desktop">
                <NavItem>
                  <NavLink className="dl-desktop-navbar-link" href="#">
                    <div style={{ color: "rgb(249, 186, 20)" }}>
                      Consultation en attente
                    </div>
                  </NavLink>
                </NavItem>
              </Nav>
              <NavItem>
                <NavLink
                  className="dl-desktop-navbar-link"
                  href="/prestationadomicile"
                >
                  <div>Prestations à domicile</div>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className="dl-desktop-navbar-link" href="/dossier">
                  <div>Mon dossier</div>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className="dl-desktop-navbar-link" href="/dossier">
                  <div>Mes rendez-vous</div>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className="dl-desktop-navbar-link"
                  href="/equipesoignante"
                >
                  <div>Mon équipe soignante</div>
                </NavLink>
              </NavItem>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret className="dl-desktop-navbar-link">
                  {this.state.username}
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem href="/compte">Mon compte</DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={this.seDeconnecter}>
                    Se déconnecter
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          ) : (
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink
                  className="dl-link dl-desktop-navbar-b2b-btn"
                  href="https://pro.skanmed.com"
                >
                  <svg height="24" viewBox="0 0 24 24" width="24">
                    <path d="M0 0h24v24H0z" fill="none"></path>
                    <path
                      d="M7.048 0a.969.969 0 00-.35.063C6.045.3 5.37.626 4.802 1.193l-.318.318a.969.969 0 00-.182.253c-.02.039-.178.2-.37.585-.107.213-.252.475-.342.835l.018-.063-2.286 7.07a.969.969 0 00-.005.023 4.753 4.753 0 002.337 5.55c-.201 1.816 1.156 3.55 3.025 4.169 2.13.704 4.986-.091 7.2-3.253l.003-.002c2.108-2.99 4.463-2.788 5.88-1.726.708.531 1.147 1.302 1.178 2.112.023.611-.167 1.283-.752 1.98a2.801 2.801 0 00-1.837-.7c-1.551 0-2.831 1.277-2.831 2.828S16.8 24 18.35 24a2.843 2.843 0 002.83-2.828c0-.112-.02-.22-.034-.328 1.211-1.166 1.779-2.547 1.729-3.853-.056-1.454-.825-2.747-1.95-3.59-1.126-.845-2.642-1.215-4.197-.92-1.554.297-3.148 1.26-4.433 3.084a.969.969 0 000 .003c-1.878 2.682-3.725 2.95-5.008 2.525-.984-.325-1.595-1.084-1.69-1.804 2.062.103 4.026-1.162 4.692-3.217a.969.969 0 00.003-.007l2.22-7.035c.34-.95.34-1.986 0-2.937a.969.969 0 00-.005-.015 6.004 6.004 0 00-.757-1.39.969.969 0 10-1.577 1.125c.228.32.39.607.515.936.19.537.191 1.094 0 1.63a.969.969 0 00-.013.033l-2.23 7.062c-.48 1.478-1.98 2.248-3.457 1.77-1.478-.48-2.254-2-1.814-3.48l2.279-7.047a.969.969 0 00.017-.063 3.03 3.03 0 01.192-.44c.013-.026.144-.224.252-.393l.258-.257c.282-.283.67-.49 1.188-.68A.969.969 0 007.048 0zm11.304 20.281c.504 0 .89.387.89.89s-.386.891-.89.891-.893-.387-.893-.89.389-.891.893-.891z"
                      fill-rule="evenodd"
                    ></path>
                  </svg>
                  Vous êtes professionnel de santé ?
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className="dl-desktop-navbar-link"
                  href="/prestationadomicile"
                >
                  <div>Prestations à domicile</div>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className="dl-desktop-navbar-link"
                  href="/identification"
                >
                  <svg
                    className="dl-desktop-navbar-link-icon"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                  >
                    <path d="M20 16.76V19H0v-2.24c0-.7 1.45-1.56 4.34-2.57 1.33-.5 2.24-1 2.72-1.5.5-.5.73-1.17.73-2 0-.3-.2-.67-.5-1.05-.3-.4-.56-.9-.7-1.55-.03-.16-.1-.3-.2-.37a.66.66 0 00-.3-.17c-.1-.03-.2-.15-.3-.36-.1-.2-.15-.5-.18-.9 0-.22.04-.4.1-.55.08-.14.14-.22.2-.25L6 5.43c-.1-.7-.2-1.33-.24-1.86a3.4 3.4 0 01.88-2.37C7.3.4 8.4 0 10 0c1.6 0 2.7.4 3.36 1.22.65.82.94 1.6.85 2.37L14 5.4c.25.1.38.4.38.88-.03.4-.1.7-.2.9-.1.23-.2.35-.3.38-.1.02-.2.07-.3.16-.1.08-.15.2-.18.38-.1.67-.33 1.2-.66 1.56-.3.38-.5.7-.5 1.02 0 .85.27 1.52.77 2 .5.5 1.4 1 2.7 1.47 2.9 1 4.35 1.85 4.35 2.55z"></path>
                  </svg>
                  <div>
                    <div>Se connecter</div>
                    <div style={{ opacity: "0.7", fontSize: "8px" }}>
                      Gérer mes Consultations
                    </div>
                  </div>
                </NavLink>
              </NavItem>
            </Nav>
          )}
        </Collapse>
      </Navbar>
    );
  }
}

export default withRouter(AppNavbar);
