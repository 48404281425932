import React, { Component } from 'react';
import { Collapse, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

import { ActivationContext } from './context';

import './WizardContent.css';

export default class WizardContent extends Component {
    constructor(props) {
        super(props);

        this.state = { isOpen: props.isOpen };

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    render() {
        return (
            <ActivationContext.Consumer>
                {({ activeTab, activateTab, activateNextTab }) => (
                    <div className="setting-item" style={{ pointerEvents: (this.props.valeur === 0 ? "none" : "auto") }}>
                        <div className="setting-header">
                            <div className="setting-header-title">
                                <Row style={{width:"100%"}}>
                                    <Col xs="1">
                                        <FontAwesomeIcon icon={this.props.icon} style={{ marginRight: "20px" }} />
                                    </Col>
                                    <Col xs="10">
                                        <Row className='no-gutters'>
                                            <Col className='col-auto'>
                                                {this.props.title}
                                            </Col>
                                            <Col xs="12" className="col-sm-auto">
                                                <span className="wizard-content-selection">{this.props.designation}</span>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                            <div className="setting-header-icon" onClick={() => activateTab(this.props.tab)}><FontAwesomeIcon icon={(this.props.tab === activeTab) ? faChevronUp : faChevronDown} /></div>
                        </div>
                        <Collapse isOpen={this.props.tab === activeTab}>
                            <div className="setting-body">
                                {this.props.children}
                            </div>
                        </Collapse>
                    </div>
                )}
            </ActivationContext.Consumer>
        )
    }
}