import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class ConditionsDUtilisation extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <div className="admin-container">
                    <div class="dl-legal-content-html">

                        <h1>CONDITIONS GÉNÉRALES D’UTILISATION DES SERVICES DE SKANMED</h1>

                        <h3>DÉFINITIONS</h3>

                        <p>Au sens des conditions générales d’utilisation, les expressions ci-dessous auront la définition suivante :</p>

                        <ul>
                            <li><p><strong>CGU</strong> désigne les présentes conditions générales d’utilisation applicables aux Services.</p></li>
                            <li><p><strong>Données à Caractère Personnel</strong> désigne toute information concernant une personne physique identifiée ou identifiable ; est réputée identifiable une personne qui peut être identifiée, directement ou indirectement, notamment par référence à un numéro d’identification ou à un ou plusieurs éléments spécifiques, propres à son identité physique, physiologique, psychique, économique, culturelle ou sociale.</p></li>
                            <li><p><strong>Données de Santé à Caractère Personnel</strong> désigne toute Donnée à Caractère Personnel de l’Utilisateur recueillie par un Professionnel de Santé à l’occasion d’activités de prévention, de diagnostic, de soins ou de suivi social et médico-social et notamment dans le cadre de l’utilisation des Services.</p></li>
                            <li><p><strong>Identifiant</strong> désigne l’identifiant personnel associé au compte de l’Utilisateur ainsi que tout code confidentiel ou mot de passe délivré à l’Utilisateur par SKANMED puis modifié par l’Utilisateur et permettant à l’Utilisateur de s’identifier afin d’accéder aux Services.</p></li>
                            <li><p><strong>Réglementation sur les données personnelles</strong> désigne la loi n° 2013-450 du 19 juin 2013.</p></li>
                            <li><p><strong>Parties</strong> désigne conjointement SKANMED et l’Utilisateur.</p></li>
                            <li><p><strong>Professionnel de santé</strong> désigne les professionnels intervenant dans le secteur de la santé, et qui sont présentés sur le Site.</p></li>
                            <li><p><strong>Services</strong> désigne conjointement le Service d’Annuaire et le Service de téléconsultation mis à disposition des Utilisateurs par SKANMED par le biais du Site.</p></li>
                            <li><p><strong>Service d’Annuaire</strong> désigne le service gratuit de recherche de Professionnels de Santé mis à disposition des Utilisateurs par SKANMED par le biais du Site.</p></li>
                            <li><p><strong>Service de téléconsultation</strong> désigne le Service de téléconsultation avec des Professionnels de Santé mis à disposition des Utilisateurs par le biais du Site.</p></li>
                            <li><p><strong>Site</strong> désigne le site Internet <a href="https://skanmed.com">skanmed.com</a></p></li>
                            <li><p><strong>Utilisateur</strong> désigne toute personne physique qui utilise le Site et accède aux Services proposés par SKANMED par le biais du Site.</p></li>
                        </ul>

                        <p>Sauf indications contraires dans les CGU :</p>

                        <ul>
                            <li><p>Dès lors qu’un mot ou une phrase a un sens défini, toute autre forme de ce mot ou cette phrase a un sens correspondant ;</p></li>
                            <li><p>Les mots au singulier incluent le pluriel et vice versa ;</p></li>
                            <li><p>Une référence à un document, une norme, une disposition législative, code ou tout autre document implique toute modification ou mise à jour de ce document, norme, disposition législative ou code ;</p></li>
                            <li><p>Si une période de temps est spécifiée et remonte à un jour donné ou au jour d’acceptation des CGU cette période de temps doit être calculée comme comprenant ce jour-là.</p></li>
                        </ul>

                        <h3>MENTIONS LÉGALES</h3>

                        <p>Les Services de SKANMED sont exploités et hébergés par SKAN TECHNOLOGIES, RCCM N° CI-ABJ-2011-A-6390.</p>

                        <p>Directeur de la publication : Monsieur AMANI Nancy-aphey Anicet, Directeur Général.</p>

                        <h3>OBJET DES CGU ET VERSION EN VIGUEUR</h3>

                        <p>Les présentes CGU ont pour objet de définir les conditions dans lesquelles les Utilisateurs peuvent accéder aux Services et les utiliser.</p>

                        <p>Tout Utilisateur qui accède aux Services proposés par SKANMED s'engage à respecter, sans réserve, les présentes CGU, complétées, le cas échéant, des CPU. Ces CPU sont notifiées avant l’utilisation des Services aux Utilisateurs pour acceptation expresse et préalable.</p>

                        <p>Si l’Utilisateur n’est pas en accord avec tout ou partie des CGU, il lui est vivement recommandé de ne pas utiliser le Site et les Services.</p>

                        <p>SKANMED est libre de modifier, à tout moment, les présentes CGU, afin notamment de prendre en compte toute évolution légale, réglementaire, jurisprudentielle et/ou technique. La version qui prévaut est celle qui est accessible en ligne à l’adresse suivante : <a href='https://skanmed.com/conditions'>https://skanmed.com/conditions</a>. Il en est de même pour les éventuelles CPU accessibles en ligne à l’adresse des Services. Tout Utilisateur est donc tenu de se référer à leur version accessible en ligne à la date de son accès et de son utilisation des Services.</p>

                        <p>L’Utilisateur est expressément informé que l’unique version des CGU des Services qui fait foi est celle qui se trouve en ligne sur le Site, ce qu’il reconnaît et accepte sans restriction, s’engageant à s’y référer systématiquement lors de chaque connexion.</p>

                        <h3>CAPACITÉ</h3>

                        <p>L’Utilisation des Services est réservée aux Utilisateurs personnes physiques capables de souscrire des obligations conformément au droit ivoirien ou par lequel il a été mandaté à cet effet.</p>

                        <p>L’Utilisateur peut utiliser les Services pour le compte de tiers sur lesquels il dispose de l’autorité parentale ou pour lesquels il est reconnu tuteur ou curateur conformément au droit ivoirien.</p>

                        <h3>INFORMATIONS, RESPONSABILITÉS ET GARANTIES RELATIVES AUX MOYENS D'ACCÈS AUX SERVICES</h3>

                        <p>SKANMED met en place les moyens nécessaires à la bonne marche des Services. SKANMED prend les mesures nécessaires au maintien de la continuité et de la qualité des Services.</p>

                        <p>L’Utilisateur reconnaît que son utilisation des Services se fait à ses risques et périls. Les Services lui sont fournis « en l’état » et sont accessibles sans aucune garantie de disponibilité et de régularité. SKANMED s’efforcera cependant de rendre les Services accessibles 24 heures sur 24, 7 jours sur 7, sauf en cas de force majeure ou d’un événement hors du contrôle de SKANMED et sous réserve des périodes de maintenance, des pannes éventuelles, des aléas techniques liés à la nature du réseau internet ou des actes de malveillance ou toute atteinte portée au matériel ou aux logiciels de SKANMED.</p>

                        <p>SKANMED ne peut être tenue responsable des perturbations du réseau Internet dues à des cas de force majeure au sens de la jurisprudence de la Cour de Cassation et du fait des opérations de maintenance des Services planifiées par SKANMED. SKANMED ne peut également pas être tenue responsable de l’installation et du fonctionnement des terminaux utilisés par l’Utilisateur pour accéder aux Services et non fournis par SKANMED.</p>

                        <p>Plus généralement, SKANMED ne pourra en aucun cas être tenue pour responsable en raison d’une interruption des Services quel que soit la cause, la durée ou la fréquence de cette interruption.</p>

                        <p>Les taux de transfert et les temps de réponse des informations circulant à partir de la plateforme de SKANMED vers Internet ne sont pas garantis par SKANMED. </p>

                        <p>L’Utilisateur reconnaît que la vitesse de transmission des informations ne dépend pas des Services offerts par SKANMED, mais des caractéristiques inhérentes aux réseaux de communications électronique et des caractéristiques techniques de son mode de connexion (câble, ADSL, 3G, 4G etc.) et de son accès internet.</p>

                        <p>SKANMED ne saurait en aucun cas être tenue de réparer d’éventuels dommages indirects subis par l’Utilisateur à l’occasion de l’utilisation des Services. Les dommages indirects sont ceux qui ne résultent pas exclusivement et directement de la défaillance des Services de SKANMED.</p>

                        <p>En outre, la responsabilité de SKANMED ne peut pas être recherchée pour des actes réalisés par l’Utilisateur ou un tiers utilisant les Services.</p>

                        <p>Tout logiciel, téléchargé par l’Utilisateur, ou obtenu de toute autre manière lors de l’utilisation du Service, l’est aux risques et périls de l’Utilisateur.</p>

                        <h3>SITES TIERS</h3>

                        <p>Pour le cas où les Services contiendraient des liens hypertextes renvoyant vers des sites internet édités par des tiers (ci-après les « Sites Tiers ») sur lesquels SKANMED n'exerce aucune sorte de contrôle, SKANMED n'assume aucune responsabilité quant au contenu des Sites Tiers ou au contenu vers lequel les Sites Tiers peuvent renvoyer. La présence de liens hypertextes vers des Sites Tiers ne saurait signifier que SKANMED approuve de quelque façon que ce soit les contenus des Sites Tiers. SKANMED n'est responsable d'aucune modification ou mise à jour concernant les Sites Tiers. SKANMED n'est pas responsable de la transmission d'informations à partir des Sites Tiers, ni du mauvais fonctionnement de ceux-ci.</p>

                        <h3>PROPRIÉTÉ INTELLECTUELLE</h3>

                        <p>Les Services de SKANMED et tous les éléments qui les composent sont, sauf mentions particulières, la propriété exclusive de SKANMED. Toutes les marques et tous les logos appartenant à SKANMED ne peuvent pas être utilisés par l’Utilisateur sans le consentement préalable écrit de SKANMED.</p>

                        <p>En conséquence, en application des dispositions du Code de la propriété intellectuelle, des dispositions législatives et réglementaires de tous pays et des conventions internationales, toute reproduction, diffusion ou représentation, intégrale ou partielle, des Services de SKANMED ou d’un quelconque élément qui les compose est interdite de même que leur altération. A ce titre, il est notamment interdit à l’Utilisateur d’adapter, arranger, modifier, corriger, associer, traduire en toutes langues ou tous langages, mettre sur le marché à titre gratuit ou onéreux, commercialiser, tout ou partie des Services fournis par SKANMED ou d’un quelconque élément qui les compose, quel qu’en soient le moyen et le support. Aucune stipulation des CGU ne peut être interprétée comme une cession de droits de propriété intellectuelle que ce soit tacitement ou d’une autre façon.</p>

                        <h3>INFORMATIONS PRÉALABLES RELATIVES AUX SERVICES</h3>

                        <p>Le Site offre la possibilité à l’Utilisateur d’utiliser :
                            <ul>
                                <li>Le Service d’Annuaire</li>
                                <li>Le Service de téléconsultation et de recevoir des informations et documents de la part de ses praticiens</li>
                            </ul>
                        </p>

                        <p>Les Services sont fournis à l’Utilisateur à titre gratuit.</p>

                        <p>Néanmoins, les équipements (notamment ordinateur, téléphone, logiciels, moyens de communication électronique) permettant l’accès et l’utilisation des Services sont à la charge exclusive de l’Utilisateur, de même que les frais de communications électroniques (notamment coûts téléphoniques, coûts d’accès à Internet) résultant de leur utilisation. Il appartient à l’Utilisateur de s’informer du prix d’utilisation desdits équipements ou services auprès des opérateurs concernés. L’Utilisateur est seul redevable de leurs prix.</p>

                        <p>Par ailleurs, l’Utilisateur reconnaît que le Site renvoie à des prestations de services payantes. Notamment et par exemple pour le service de téléconsultation, l’Utilisateur reconnaît et accepte que la prestation de santé effectuée par le Professionnel de Santé inscrit sur le Site soit payante.</p>

                        <h3>SERVICE D’ANNUAIRE</h3>

                        <p>Le Service d’Annuaire permet à l’Utilisateur de rechercher un Professionnel de Santé conformément à différents critères (géolocalisation, spécialité, langues parlées, homme/femme, etc.) et de consulter la page des Professionnels de Santé ainsi recherchés.</p>

                        <p>SKANMED ne garantit pas à l’Utilisateur que son annuaire contienne l’ensemble des professionnels de santé exerçant sur le territoire ivoirien et plus spécifiquement sur la zone géographique de recherche de l’Utilisateur. En conséquence, il est possible que l’Utilisateur ne trouve pas un professionnel de santé sur le Site en utilisant le Service d’Annuaire.</p>

                        <p>SKANMED renvoie l’Utilisateur au site des Ordres professionnels compétents s’agissant des professionnels de santé exerçant une profession réglementée et aux sites des syndicats représentatifs des professionnels de santé n’exerçant pas une profession réglementée afin d’obtenir la liste exhaustive de membres de chaque profession concernée.</p>

                        <p>SKANMED rappelle à l’Utilisateur qu’en aucun cas le Service d’Annuaire ne peut être assimilé, du fait de son absence d’exhaustivité, à un service d’adressage de patients aux Professionnels de Santé.</p>

                        <p>L’Utilisateur conserve toujours le choix des professionnels de santé qu’ils consultent conformément aux dispositions applicables du Code de la santé publique, aux dispositions déontologiques applicables aux professions réglementées et aux dispositions contraignantes applicables aux professions non réglementées.</p>

                        <h3>SERVICE DE TELECONSULTATION</h3>

                        <h2>CONDITIONS PARTICULIERES D’UTILISATION DU SERVICE DE CONSULTATION PAR VIDEO</h2>

                        <p>Les présentes Conditions Particulières d’Utilisation (ci-après “CPU”) s’appliquent au Service de Consultation par Video (ou Téléconsultation) proposé par SKANMED. Elles complètent les Conditions Générales d’Utilisation des Services SKANMED. Si tel n’était pas déjà le cas, l’acceptation des présentes CPU emporte acceptation pleine et entière des Conditions Générales d’Utilisation aux Services SKANMED.</p>

                        <h3><a name="telehealth"></a> DÉFINITIONS ADDITIONNELLES</h3>

                        <p>Au sens des conditions particulières d’utilisation, les expressions ci-dessous auront la définition suivante :</p>

                        <ul>
                            <li><p><strong>Application Mobile</strong> désigne l'application SKANMED disponible depuis un smartphone par suite du téléchargement de celle-ci sur un magasin d’applications.</p></li>
                            <li><p><strong>Site Mobile</strong> désigne le site internet skanmed.com accessible depuis un smartphone.</p></li>
                            <li><p><strong>CPU</strong> désigne les présentes conditions particulières d’utilisation applicables au Service de Consultation par Vidéo également nommé “Service de Téléconsultation”.</p></li>
                            <li><p><strong>Service(s) de Téléconsultation</strong> ou Service de Consultation par Vidéo désigne la mise à disposition par SKANMED à l’Utilisateur d’un outil permettant de réaliser une consultation à distance par vidéotransmission avec un Professionnel de santé au travers de l’Application Mobile ou du Site Mobile.</p></li>
                            <li><p><strong>Téléconsultation</strong> ou <strong>Consultation par Vidéo</strong> désigne une consultation à distance entre un Utilisateur et un Professionnel de santé réalisée par vidéotransmission via l’Application Mobile ou le Site Mobile.</p></li>
                        </ul>

                        <h3>OBJET DES CPU ET VERSION EN VIGUEUR</h3>

                        <p>Les présentes CPU ont pour objet de définir les conditions dans lesquelles les Utilisateurs peuvent accéder au Service de Téléconsultation et l’utiliser.
                        Tout Utilisateur qui accède au Service de Téléconsultation proposé par SKANMED s'engage à respecter, sans réserve, les présentes CPU. Ces CPU sont notifiées avant l’utilisation du Service aux Utilisateurs pour acceptation expresse et préalable.
                        Si l’Utilisateur n’est pas en accord avec tout ou partie des CPU, il ne doit pas utiliser le Service de Téléconsultation.
SKANMED est libre de modifier les CPU à tout moment, afin notamment de prendre en compte toute évolution légale, réglementaire, jurisprudentielle et/ou technique. La version qui prévaut est celle qui est accessible en ligne au jour de l’utilisation du Service de Téléconsultation. Tout Utilisateur est donc tenu de se référer à cette version à la date de son utilisation dudit service.</p>

                        <h3>PROTECTION DES DONNÉES À CARACTÈRE PERSONNEL</h3>

                        <p>SKANMED ou tout autre tiers n’ont pas accès à la Téléconsultation ou aux données échangées entre l'Utilisateur et les Professionnels de Santé. Elles restent strictement confidentielles. SKANMED invite expressément l’Utilisateur à consulter les dispositions spécifiques de sa <a href="/conditions/confidentialite">politique relative à la protection des données personnelles</a>, applicables à la Téléconsultation, qui fait partie intégrante des présentes CPU.</p>

                        <h3>INFORMATIONS RELATIVES AUX MOYENS D'ACCÈS ET AU SERVICE</h3>

                        <p>Afin d’accéder au Service de Téléconsultation et bénéficier d’une Téléconsultation de qualité, l’Utilisateur doit disposer à minima,des équipements suivants: (1) un smartphone suffisamment récent disposant d’une caméra comportant une résolution satisfaisante et (2) une connexion internet (en 4G) avec un débit suffisant. Si tel n’est pas le cas, le Professionnel de Santé pourra mettre un terme à la Téléconsultation et s’il le juge opportun, prélever l’Utilisateur de frais afférents aux actes déjà effectués.
SKANMED n’est qu’un intermédiaire entre l’Utilisateur et le Professionnel de santé, leur permettant d’effectuer une consultation à distance par vidéotransmission, l’envoi de documents de manière électronique et le paiement en ligne.</p>

                        <p>Le Service de Téléconsultation est fourni à l’Utilisateur par SKANMED à titre gratuit. Les équipements (smartphone, connexion internet...) permettant l’accès et l’utilisation des Services de Téléconsultation sont à la charge exclusive de l’Utilisateur, de même que les frais de communications électroniques (notamment coûts téléphoniques, coûts d’accès à Internet) y afférents. Il appartient à l’Utilisateur de s’informer du prix d’utilisation desdits équipements ou services auprès des opérateurs concernés avant d’utiliser le Service de Téléconsultation.</p>

                        <p>L’Utilisateur reconnaît que le Service de Téléconsultation permet de bénéficier d’une prestation de santé payante et il en sera redevable au Professionnel de santé.</p>

                        <p>Les informations fournies par SKANMED sur les tarifs ou honoraires pratiqués par un Professionnel de Santé le sont à titre indicatif et par souci de commodité. SKANMED ne garantit pas l’exactitude de telles informations. Le prix final de la Téléconsultation peut varier selon le type de consultation effectivement réalisé. Il appartient au Professionnel de santé d'informer le patient du coût de cet acte.</p>

                        <h3>CONDITIONS D'ACCÈS ET D’UTILISATION DU SERVICE DE TÉLÉCONSULTATION</h3>

                        <p><strong>La Téléconsultation n'est pas un service d'urgence. En cas d’urgence, l’Utilisateur doit se diriger vers un service d’urgences.</strong></p>

                        <p>La Téléconsultation se fait, pour le patient, depuis un smartphone ou un ordinateur. Le smartphone ou l'ordinateur doit être équipé d’une caméra.</p>

                        <p>L’Utilisateur s’engage à compléter toutes les informations nécessaires qui lui sont demandées et indispensables à la bonne utilisation du Service de Téléconsultation.</p>

                        <p>Lors du rendez-vous, l’Utilisateur est invité à se présenter à la Téléconsultation quelques minutes avant celle-ci afin de s’assurer de la qualité de la connexion de l’audio et de la vidéo du smartphone.</p>

                        <p>Lors de sa connexion en vue de la Téléconsultation, l’Utilisateur est mis en attente par le Professionnel de santé pour une durée laissée à l'appréciation de ce dernier. Le Professionnel de santé active ensuite le démarrage de la Téléconsultation.
SKANMED ne saurait être tenu responsable des retards ou annulation des Professionnels de santé.</p>

                        <p>L’Utilisateur s’engage à utiliser le Service de Téléconsultation dans des conditions lui permettant d’assurer la confidentialité et le bon déroulement des échanges avec le Professionnel de Santé. En particulier, l’Utilisateur doit s’assurer de réaliser la Téléconsultation dans un endroit suffisamment lumineux et calme pour permettre une Téléconsultation de qualité.</p>

                        <p>Le Professionnel de santé est seul juge de la pertinence d’une prise en charge à distance de l’Utilisateur et peut interrompre au besoin la Téléconsultation si les conditions d’une prise en charge à distance ne sont pas réunies. La durée d’une Téléconsultation est laissée à la libre appréciation du Professionnel de Santé.
Lors de la Téléconsultation, l’Utilisateur doit se conformer aux instructions précisées par le Professionnel de Santé, pour une prise en charge optimale ou un diagnostic. Les Professionnels de Santé réalisent la Téléconsultation en toute indépendance, suivant les obligations déontologiques et légales qui leur incombent. La Téléconsultation se déroule sous la responsabilité exclusive de l’Utilisateur et du Professionnel de Santé.</p>

                        <p>L'Utilisateur s'engage à ne pas enregistrer, copier ou diffuser tout contenu ou extrait de contenu en lien avec la Téléconsultation quel qu'en soit le moyen, le support, le procédé ou la finalité. La Téléconsultation n’est enregistrée ni par le Professionnel de Santé, ni par SKANMED ni par tout autre tiers. L'Utilisateur est informé que toute atteinte au droit à l’image, au respect de la vie privé ou au secret professionnel et médical peut faire l’objet de sanctions, y compris pénales.</p>

                        <p>En cas de dysfonctionnement de quelque nature que ce soit lors de la Téléconsultation, l’Utilisateur doit en informer immédiatement le Professionnel de Santé.
L’Utilisateur qui interrompt abusivement la Téléconsultation engage sa responsabilité à l’égard du Professionnel de santé.</p>

                        <p>A l’issue de la Téléconsultation, à laquelle le Professionnel de Santé met fin, le Professionnel de santé confirme le montant à payer par l’Utilisateur. Selon la situation, le Professionnel de Santé peut décider de revoir à la baisse ou de ne pas faire payer la Téléconsultation.</p>

                        <p>L’Utilisateur pourra ensuite recevoir dans son espace SKANMED la note d’honoraire correspondante, et le cas échéant, le compte rendu de la téléconsultation et/ou une prescription , en se rendant dans la Rubrique “Mes consultations” et en sélectionnant la Téléconsultation réalisée afin de télécharger lesdits documents. L’établissement d’une prescription est à l’appréciation du Professionnel de Santé. La transmission par l'Utilisateur de ces documents contenant des données personnelles de santé à tout tiers se fait sous sa seule responsabilité.</p>

                        <p>SKANMED décline toute responsabilité en cas de litige, quelle qu’en soit la raison, entre l’Utilisateur et un Professionnel de santé, non directement imputable à SKANMED.</p>

                        <p>Les incivilités, verbales ou écrites, à l’encontre des Professionnels de Santé téléconsultants peuvent faire l’objet de poursuites et de la suppression du compte SKANMED de l’Utilisateur.</p>

                        <p>SKANMED ne peut s’assurer de la conformité de son produit et son utilisation au delà de la juridiction ivoirienne.</p>

                        <h3>PROTECTION DES DONNÉES À CARACTÈRE PERSONNEL</h3>

                        <h4>DONNÉES À CARACTÈRE PERSONNEL RELATIVES À L’UTILISATEUR</h4>

                        <p>SKANMED invite expressément l’Utilisateur à consulter sa <a href="/conditions/confidentialite">politique relative à la protection des données personnelles</a> qui fait partie intégrante des présentes CGU.</p>

                        <h4>COOKIES ET ADRESSE IP</h4>

                        <p>SKANMED invite expressément l’Utilisateur à consulter sa <a href="/conditions/confidentialite">politique relative à la protection des données personnelles</a> qui fait partie intégrante des présentes CGU.</p>

                        <p>A la demande des autorités judiciaires, SKANMED peut transmettre l’adresse IP de l’Utilisateur, afin que ce dernier soit identifié en coopération avec son fournisseur d’accès à Internet.</p>

                        <h3>FORCE MAJEURE</h3>

                        <p>Il y a force majeure lorsqu'un événement échappant au contrôle du débiteur, qui ne pouvait être raisonnablement prévu lors de l’acceptation des CGU et dont les effets ne peuvent être évités par des mesures appropriées, empêche l'exécution de son obligation par le débiteur</p>

                        <p>Le cas de force majeure suspend les obligations de la partie concernée pendant le temps où jouera la force majeure si cet évènement est temporaire. Néanmoins, les Parties s’efforceront d’en minimiser dans toute la mesure du possible les conséquences.</p>

                        <p>A défaut, si l'empêchement est définitif, les parties seront libérées de leurs obligations.</p>

                        <h3>CONVENTION DE PREUVE</h3>

                        <p>Les messages reçus par télécopie ou par voie électronique et plus généralement les documents électroniques échangés entre SKANMED et l’Utilisateur sont des écrits d’origine, c’est à dire comme ayant la même valeur que celle accordée à l’original. Il convient de conserver les télécopies ou les écrits électroniques de telle manière qu’ils puissent constituer des copies fidèles et durables.</p>

                        <h3>RENONCIATION</h3>

                        <p>Le fait pour l’une ou l’autre des Parties de ne pas se prévaloir d’une ou plusieurs stipulations des CGU ne pourra en aucun cas impliquer la renonciation par cette Partie à s’en prévaloir ultérieurement.</p>

                        <h3>NULLITÉ PARTIELLE</h3>

                        <p>Dans le cas où certaines stipulations des CGU seraient inapplicables pour quelque raison que ce soit, y compris en raison d’une loi ou d’une réglementation applicable, les parties resteront liées par les autres stipulations des CGU et s’efforceront de remédier aux clauses inapplicables dans le même esprit que celui qui a présidé lors de la conclusion.</p>

                        <h3>DROIT APPLICABLE ET ATTRIBUTION DE COMPÉTENCE</h3>

                        <p>L’Utilisateur est informé du fait que le Site s’adresse aux patients résidant en Cote d'Ivoire et est soumise en droit ivoirien. Aussi, en naviguant sur le Site ou en utilisant les services de SKANMED, l’Utilisateur se soumet au droit ivoirien. </p>

                        <p>Toutes difficultés relatives à la validité, l’application ou à l’interprétation des CGU seront soumises, à défaut d’accord amiable, au Tribunal de Première Instance d'Abidjan, auquel les Parties attribuent compétence territoriale, quel que soit le lieu d’exécution ou le domicile du défendeur. Cette attribution de compétence s’applique également en cas de procédure en référé, de pluralité de défendeurs ou d’appel en garantie.</p>

                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(ConditionsDUtilisation);