import React, { Component } from 'react';
import { Button } from 'reactstrap';

import ChampDeRecherche from './ChampDeRecherche';

import './Recherche.css';

export default class Recherche extends Component{
    constructor(props){
        super(props);

        this.state = {
            suggestion: null
        }

        this.onSuggestionChange = this.onSuggestionChange.bind(this);
        this.onSuggestionChosen = this.onSuggestionChosen.bind(this);
        this.onClick = this.onClick.bind(this);
    }

    onSuggestionChange(suggession){
        this.props.onSuggestionChange(suggession);
    }

    onSuggestionChosen(suggession){
        this.setState({
            suggession: suggession
        })
        this.props.onSuggestionChosen(suggession);
    }

    onClick(){
        this.props.onClick(this.state.suggession);
    }

    render(){
        return (
            <div style={{ display: "grid", gridTemplateColumns: "70% 30%" }}>
                <ChampDeRecherche suggestionValue={this.props.suggestionValue} url={this.props.url} placeholder={this.props.placeholder} onSuggestionChange={this.onSuggestionChange} onSuggestionChosen={this.onSuggestionChosen}/>
                <Button className="barre-de-recherche-button search-button" onClick={this.onClick}>VALIDER</Button>
            </div>
        )
    }
}