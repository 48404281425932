import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Nav, NavItem } from 'reactstrap';
import { BrowserRouter as Router, Route, Switch, Link, NavLink } from 'react-router-dom';

import AppNavbar from './AppNavbar';
import ConsultationsEnCours from './ConsultationsEnCours';
import Consultations from './Consultations';

import { HttpGET, HttpPOST, HttpDELETE } from './service/HttpService';

import './Dossier.css';
import Medecins from './Medecins';
import Documents from './Documents';

export default class Dossier extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    componentDidMount() {

    }

    render() {
        return (
            <div>
                <AppNavbar />
                <div className="header_search" style={{ marginBottom: "1rem" }}>
                    <Nav style={{ justifyContent: "center" }}>
                        <NavItem>
                            <NavLink to="/dossier/consultationsencours" class="nav-link" activeClassName="active">Mes consultations en cours</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink to="/dossier/consultations" class="nav-link" activeClassName="active">Mes consultations</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink to="/dossier/documents" class="nav-link" activeClassName="active">Mes documents</NavLink>
                        </NavItem>
                    </Nav>
                </div>
                <Switch>
                    <Route exact path={this.props.match.path} component={ConsultationsEnCours} />
                    <Route path={`${this.props.match.path}/consultationsencours`} component={ConsultationsEnCours} />
                    <Route path="/dossier/consultations" component={Consultations} />
                    <Route path="/dossier/documents" component={Documents} />
                    <Route path="/dossier/equipesoignante" component={Medecins} />
                </Switch>
            </div>
        )
    }
}

//export default withRouter(Dossier);