import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTasks, faInfoCircle, faGraduationCap, faUniversity, faFileDownload, faTimes, faEdit, faChevronLeft, faBook, faFile, faHeadSideCough, faDiagnoses, faPills, faVial } from '@fortawesome/free-solid-svg-icons';
import AppNavbar from './AppNavbar';
import ResultatDeRecherche from './ResultatDeRecherche';

import { HttpGET, HttpPOST, HttpDELETE } from './service/HttpService';

import iconeRechercheVide from './assets/img/icone_web-07.png';
import avatarHomme from './assets/img/hom_icon87x87px.png';
import avatarFemme from './assets/img/fem_icon87x87px.png';
import avatarHopital from './assets/img/hosp_icon87x87px.png';
import logoCardiologieHopital from './assets/img/logo_cardio.jpeg';
import iconeVideo from './assets/img/icone_video.png';
import iconeAttente from './assets/img/icone_attente.png';
import DoctorCall from './assets/img/online-health-doctor.svg';

export default class DetailEtablissement extends Component {
    constructor(props) {
        super(props);

        const { params } = this.props.match;

        this.state = {
            minisite: params.minisite,
            professionnels: []
        }
    }

    componentDidMount(){
        HttpGET('/medicinemanagement-api/search/infosminisiteetablissement/' + this.state.minisite, function (data) {
            if (data.statut != 0) {
                return;
            }

            this.setState({
                id: data.donnee.id,
                designation: data.donnee.designation,
                specialite: data.donnee.specialite,
                description: data.donnee.description,
                professionnels: data.donnee.medecins,
                medecinId: data.donnee.medecinId
            });
        }.bind(this),
        function(data){});
    }


    // ===================

    consulterEntitee = (id) => {
        // if(entitee.type==='Etablissement'){
        //     this.props.history.push('/detailetablissement/' + this.state.minisite);
        //     return;
        // }
        this.props.history.push('/detailetablissement/' + this.state.minisite);

        HttpGET('/medicinemanagement-api/search/recherchertokenconsultation/' + id,
            function (data) {
                if (data.statut === 1) {
                    this.setState({
                        message: data.donnee.message
                    });

                    // const horaires = data.donnee.horaires.map((h) =>
                    //     <div key={h.JOUR} className="admin-element" style={{ color: h.etat === 'DESACTIVE' ? 'gray' : '' }}>
                    //         <div>
                    //             <div style={{ fontSize: "1.2rem" }}>{h.designation}</div>
                    //             {h.etat === 'DESACTIVE' ?
                    //                 'Pas de consultation ce jour'
                    //                 :
                    //                 (h.estH24 ?
                    //                     'Disponible 24h/24'
                    //                     :
                    //                     (!h.aPause ?
                    //                         <div style={{ fontSize: "0.9rem" }}>{h.debut + ' - ' + h.fin}</div>
                    //                         :
                    //                         <div>
                    //                             <div style={{ fontSize: "0.9rem" }}>{h.debut + ' - ' + h.debutPause}</div>
                    //                             <div style={{ fontSize: "0.9rem" }}>{h.finPause + ' - ' + h.fin}</div>
                    //                         </div>))
                    //             }
                    //         </div>
                    //     </div>
                    // )

                    // this.setState({
                    //     horaires: horaires
                    // });

                    // this.toggleModal();

                    return;
                }

                if (data.statut != 0) {
                    return;
                }
                this.props.history.push('/consultation/' + data.donnee);
            }.bind(this),
            function (data) {
                localStorage.setItem('pageSuivante', '/listeresultatderecherche/' + this.props.filtre);
                this.props.history.push('/identification');
            }.bind(this));

    }

    // ===============




    render() {
        var professionnels = null;

        if (this.state.medecinId) {
            
            professionnels = (
                <div className='d-flex flex-column align-items-center justify-content-center pt-3 pb-5'>
                    <img src={DoctorCall} alt="online health doctor" style={{ width: '90px' }} />
                    <button className='mt-5 btn btn-contact-medecin' onClick={() => this.consulterEntitee(this.state.medecinId)}>Contacter un médécin</button>
                </div>
            )
        } else {
            if (!this.state.medecinId && this.state.professionnels.length > 0) {
                professionnels = this.state.professionnels.map((p) =>
                    <ResultatDeRecherche key={p.key} filtre={this.state.filtre} entitee={p} rechercheId={p.id} type={p.type} designation={p.designation} caracteristique={p.caracteristique} etablissement={p.etablissement} assurances={p.assurances} sexe={p.sexe} grade={p.grade} />
                );
            } else {
                professionnels = <div style={{ textAlign: 'center' }}>
                    <img src={iconeRechercheVide} style={{ marginTop: '3rem' }}></img>
                    <div style={{ fontSize: '1.5rem' }}>Désolé!!! Nous n'avons trouvé aucun professionnel de santé</div>
                </div>
            }
            
        }

        

        return (
            <div>
                <AppNavbar />
                {this.state.minisite === 'institut-de-cardiologie-d-abidjan' ? <div className="header_search custom-background" style={{ color: "white" }}>
                    <Row noGutters style={{ maxWidth: "1000px", margin: "0 auto", padding: '0 0.5em' }}>
                        <Col xs='auto'>
                            <img src={logoCardiologieHopital} style={{ margin: 'auto 0', width: '6rem', overflow: 'hidden', backgroundColor: 'white', borderRadius: '50%', padding: '0.3em' }}></img>
                        </Col>
                        <Col xs='auto' style={{ padding: '1em' }}>
                            <h3>{this.state.designation}</h3>
                            <h5 style={{ color: "white" }}>{this.state.specialite}</h5>
                        </Col>
                    </Row>
                </div> : <div className="header_search" style={{ color: "white" }}>
                    <Row noGutters style={{ maxWidth: "1000px", margin: "0 auto", padding: '0 0.5em' }}>
                        <Col xs='auto'>
                            <img src={avatarHopital} style={{ margin: 'auto 0', width: '6rem', overflow: 'hidden', backgroundColor: 'white', borderRadius: '50%', padding: '0.3em' }}></img>
                        </Col>
                        <Col xs='auto' style={{ padding: '1em' }}>
                            <h3>{this.state.designation}</h3>
                            <h5 style={{ color: "white" }}>{this.state.specialite}</h5>
                        </Col>
                    </Row>
                </div>}
                <div style={{ maxWidth: "1000px", margin: '0 auto' }}>
                    <Row>
                        <Col>
                            <div className="admin-container" style={{ paddingTop: '24px' }}>
                                <h5><FontAwesomeIcon icon={faTasks} /> &nbsp; Présentation de l'établissement de santé</h5>
                                <p className='message'>
                                    Le médecin généraliste accueille les enfants et les adultes pour tous types de soins médicaux généraux (consultation, contrôle annuel, vaccination, bilan de santé). Il assure également un suivi des patients dans le temps et les oriente vers des médecins spécialistes en cas de besoin.
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="admin-container" style={{ paddingTop: '24px' }}>
                                <h5><FontAwesomeIcon icon={faTasks} /> &nbsp; Médécin</h5>
                                {/* <h5><FontAwesomeIcon icon={faTasks} /> &nbsp; Médécins</h5> */}
                                <div style={{ marginTop: '2em', gap: '10px' }}>
                                    {professionnels}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}