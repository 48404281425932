import React from 'react';
import logo from './logo.svg';
import './App.css';
import Home from './Home';
import ListeResultatDeRecherche from './ListeResultatDeRecherche';
import Compte from './Compte';
import Profil from './Profil';
import Identification from './Identification';
import Consultation from './Consultation';
import Consultations from './Consultations';
import DetailConsultation from './DetailConsultation';
import Dossier from './Dossier';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Conditions from './Conditions';
import DetailMedecin from './DetailMedecin';
import ScrollToTop from './ScrollToTop';
import DetailEtablissement from './DetailEtablissement';
import Medecins from './Medecins';
import CommandePrestation from './CommandePrestation';
import PrestationADomicile from './PrestationADomicile';
import CommandeResume from './CommandeResume';
import ConsultationEnAttente from './ConsultationEnAttente';
import ForgetPassword from './ForgetPassword';

function App() {
  return (
    <Router basename={''}>
      <ScrollToTop>
        <Switch>
          <Route path='/' exact={true} component={Home}/>
          <Route path='/listeresultatderecherche' exact={true} component={ListeResultatDeRecherche}/>
          <Route path='/listeresultatderecherche/:filtre' exact={true} component={ListeResultatDeRecherche}/>
          <Route path='/identification' exact={true} component={Identification}/>
          <Route path='/reinitialisation' exact={true} component={ForgetPassword}/>
          <Route path='/compte' exact={true} component={Compte}/>
          <Route path='/profil' exact={true} component={Profil}/>
          <Route path='/profil/:id' exact={true} component={Profil}/>
          <Route path='/consultation/:id' exact={true} component={Consultation}/>
          <Route path='/detailconsultation/:id' exact={true} component={DetailConsultation}/>
          <Route path='/detailmedecin/:minisite' exact='true' component={DetailMedecin}/>
          <Route path='/detailetablissement/:minisite' exact='true' component={DetailEtablissement}/>
          <Route path='/dossier' component={Dossier}/>
          <Route path='/equipesoignante' component={Medecins}/>
          <Route path='/consultations' exact={true} component={Consultations}/>
          <Route path='/consultationsattente' exact={true} component={ConsultationEnAttente}/>
          <Route path='/commandeprestation/:id' exact={true} component={CommandePrestation}/>
          <Route path='/commandeprestation/:menu/:id' exact={true} component={CommandePrestation}/>
          <Route path='/commanderesume/:id' exact={true} component={CommandeResume}/>
          <Route path='/prestationadomicile' exact={true} component={PrestationADomicile}/>
          <Route path='/commanderesume' exact={true} component={CommandeResume}/>
          <Route path='/conditions' component={Conditions}/>
        </Switch>
      </ScrollToTop>
    </Router>
  );
}

export default App;
