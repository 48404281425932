import React, { Component } from 'react';
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTasks, faInfoCircle, faGraduationCap, faUniversity, faFileDownload, faTimes, faEdit, faChevronLeft, faBook, faFile, faHeadSideCough, faDiagnoses, faPills, faVial } from '@fortawesome/free-solid-svg-icons';
import AppNavbar from './AppNavbar';

import { HttpGET, HttpPOST, HttpDELETE } from './service/HttpService';

import avatarHomme from './assets/img/hom_icon87x87px.png';
import avatarFemme from './assets/img/fem_icon87x87px.png';
import iconeVideo from './assets/img/icone_video.png';
import iconeAttente from './assets/img/icone_attente.png';

export default class DetailMedecin extends Component {
    constructor(props) {
        super(props);

        const { params } = this.props.match;

        this.state = {
            minisite: params.minisite,
            message: '',
            horaires: [],
            modal: false
        }

        this.toggleModal = this.toggleModal.bind(this);
        this.consulterEntitee = this.consulterEntitee.bind(this);
    }

    toggleModal() {
        this.setState({
            modal: !this.state.modal
        });
    }

    consulterEntitee() {
        HttpGET('/medicinemanagement-api/search/recherchertokenconsultation/' + this.state.id, 
        function (data) {
            if (data.statut === 1) {
                this.setState({
                    message: data.donnee.message
                });

                const horaires = data.donnee.horaires.map((h) =>
                    <div key={h.JOUR} className="admin-element" style={{color: h.etat==='DESACTIVE'?'gray':''}}>
                        <div>
                            <div style={{ fontSize: "1.2rem" }}>{h.designation}</div>
                            {h.etat==='DESACTIVE'?
                            'Pas de consultation ce jour'
                            :
                            (h.estH24?
                            'Disponible 24h/24'
                            :
                            (!h.aPause?
                            <div style={{ fontSize: "0.9rem" }}>{h.debut + ' - ' + h.fin}</div>
                            :
                            <div>
                                <div style={{ fontSize: "0.9rem" }}>{h.debut + ' - ' + h.debutPause}</div>
                                <div style={{ fontSize: "0.9rem" }}>{h.finPause + ' - ' + h.fin}</div>
                            </div>))
                            }
                        </div>
                    </div>
                )

                this.setState({
                    horaires: horaires
                });

                this.toggleModal();

                return;
            }

            if (data.statut != 0) {
                return;
            }
            this.props.history.push('/consultation/' + data.donnee);
        }.bind(this),
        function (data) {
            this.props.history.push('/identification');
        }.bind(this));
        
    }
    

    componentDidMount(){
        HttpGET('/medicinemanagement-api/search/infosminisitemedecin/' + this.state.minisite, function (data) {
            if (data.statut != 0) {
                return;
            }

            this.setState({
                id: data.donnee.id,
                designation: data.donnee.designation,
                specialite: data.donnee.specialite,
                description: data.donnee.description
            });
        }.bind(this),
        function(data){});
    }

    render() {
        return (
            <div>
                <AppNavbar />
                <div className="header_search" style={{ color: "white" }}>
                    <Row noGutters style={{ maxWidth: "1000px", margin: "0 auto", padding: '0 0.5em' }}>
                        <Col xs='auto'>
                            <img src={avatarHomme} style={{ margin: 'auto 0', width: '6rem', overflow: 'hidden', backgroundColor: 'white', borderRadius: '50%', padding: '0.3em' }}></img>
                        </Col>
                        <Col xs='auto' style={{ padding: '1em' }}>
                            <h3>{this.state.designation}</h3>
                            <h5 style={{ color: "white" }}>{this.state.specialite}</h5>
                        </Col>
                    </Row>
                </div>
                <div style={{ maxWidth: "1000px", margin: '0 auto' }}>
                    <Row className='d-sm-none d-block'>
                        <Col>
                            <div className="admin-container" style={{ padding: '0'}}>
                                <div style={{ textAlign: 'center', padding: '0.5em 0', backgroundColor: '#344B7B' }}>
                                    <h6 style={{ color: 'white' }}>Contactez le médécin</h6>
                                </div>
                                <div style={{ textAlign: 'center', padding: '1em 0' }}>
                                    <Button className='search-button' style={{ padding: "0", height: "30px", width: "120px", textAlign: "center", verticalAlign: "center", backgroundColor: "#F9BA14", borderColor: "#F9BA14", fontWeight: "bold", fontSize: "0.9rem" }} color="secondary" onClick={this.consulterEntitee}>Téléconsulter</Button>
                                </div>
                            </div>

                            {/*<div className="admin-container" style={{ padding: '0'}}>
                                <div style={{ textAlign: 'center', padding: '0.5em 0', backgroundColor: '#344B7B' }}>
                                    <h6 style={{ color: 'white' }}>Abonnement</h6>
                                </div>
                                <div style={{ textAlign: 'center', padding: '1em 1em' }}>
                                    <p>Abonnez-vous et profitez de x téléconsultations gratuites pendant un an</p>
                                    <p>Cout: 25 000 FCFA</p>
                                    <Button className='search-button' style={{ padding: "0", height: "30px", width: "120px", textAlign: "center", verticalAlign: "center", backgroundColor: "#F9BA14", borderColor: "#F9BA14", fontWeight: "bold", fontSize: "0.9rem" }} color="secondary" onClick={this.rechercher}>S'abonner</Button>
                                </div>
                            </div>*/}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="admin-container" style={{ paddingTop: '24px' }}>
                                <h5><FontAwesomeIcon icon={faTasks} /> &nbsp; Présentation du professionnel de santé</h5>
                                <p className='message'>
                                    Le médecin généraliste accueille les enfants et les adultes pour tous types de soins médicaux généraux (consultation, contrôle annuel, vaccination, bilan de santé). Il assure également un suivi des patients dans le temps et les oriente vers des médecins spécialistes en cas de besoin.
                                </p>
                            </div>
                        </Col>
                        <Col className='d-none d-sm-block' xs='4'>
                            <div className="admin-container" style={{ padding: '0', marginTop: '-2.7em' }}>
                                <div style={{ textAlign: 'center', padding: '0.5em 0', backgroundColor: '#344B7B' }}>
                                    <h6 style={{ color: 'white' }}>Contactez le médécin</h6>
                                </div>
                                <div style={{ textAlign: 'center', padding: '1em 0' }}>
                                    <Button className='search-button' style={{ padding: "0", height: "30px", width: "120px", textAlign: "center", verticalAlign: "center", backgroundColor: "#F9BA14", borderColor: "#F9BA14", fontWeight: "bold", fontSize: "0.9rem" }} color="secondary" onClick={this.consulterEntitee}>Téléconsulter</Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {/*<Row>
                        <Col>
                            <div className="admin-container" style={{ paddingTop: '24px' }}>
                                <h5><FontAwesomeIcon icon={faGraduationCap} /> &nbsp; Diplômes nationnaux et universitaires</h5>
                                <Row className='message'>
                                    <Col xs='auto'>
                                        1987
                                    </Col>
                                    <Col>
                                        Diplôme d'État de docteur en médecine - Université de Poitiers
                                    </Col>
                                </Row>
                                <hr/>
                                <h5><FontAwesomeIcon icon={faUniversity} /> &nbsp; Expériences</h5>
                                <Row className='message'>
                                    <Col xs='auto'>
                                        1987 - 2019
                                    </Col>
                                    <Col>
                                        Cabinet - Paris
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col className='d-none d-sm-block' xs='4'>
                            {/*<div className="admin-container" style={{ padding: '0'}}>
                                <div style={{ textAlign: 'center', padding: '0.5em 0', backgroundColor: '#344B7B' }}>
                                    <h6 style={{ color: 'white' }}>Abonnement</h6>
                                </div>
                                <div style={{ textAlign: 'center', padding: '1em 1em' }}>
                                    <p>Abonnez-vous et profitez de x téléconsultations gratuites pendant un an</p>
                                    <p>Coût: 25 000 FCFA</p>
                                    <Button className='search-button' style={{ padding: "0", height: "30px", width: "120px", textAlign: "center", verticalAlign: "center", backgroundColor: "#F9BA14", borderColor: "#F9BA14", fontWeight: "bold", fontSize: "0.9rem" }} color="secondary" onClick={this.rechercher}>S'abonner</Button>
                                </div>
                            </div>
                        </Col>
                    </Row>*/}
                    <Row>
                        <Col>
                            <div className="admin-container" style={{ paddingTop: '24px' }}>
                                <h5><FontAwesomeIcon icon={faInfoCircle} /> &nbsp; Informations</h5>
                                <p className='message'>
                                    QU'EST-CE-QUE LA TÉLÉCONSULTATION ? <br/><br/>
    
                                    - Consultez simplement votre médecin, en visioconférence, de façon sécurisée
                                    
                                    depuis votre smartphone ou ordinateur, chez vous, au travail ou en vacances <br/>
                                    
                                    - Transmettez lui directement vos résultats d'examens <br/>
                                    
                                    - Recevez instantanément votre ordonnance sous format numérique que vous pouvez imprimer par la suite <br/>
                                    
                                    - Le tarif est les mêmes que les consultations physiques : 5 000 FCFA <br/><br/>
                                    
                                    POUR QUELS MOTIFS UTILISER LA TÉLÉCONSULTATION ?<br/><br/>
                                    
                                    - Avis médical simple<br/>
                                    
                                    - Résultats d'examen et adaptation de traitement<br/>
                                    
                                    - Consultation de suivi ne nécessitant pas d'examen physique<br/><br/>
                                    
                                    LES MOTIFS NE PERMETTANT PAS LA TÉLÉCONSULTATION<br/><br/>
                                    
                                    - Urgences médicales vitales<br/>
                                    
                                    - Consultations complexes et/ou nécessitant un examen physique<br/>
                                    
                                    - Aucun certificat médical ou arrêt maladie n'est délivré en téléconsultation (sauf actuellement compte-tenu de la pandémie à coronavirus et les mesures de confinement)<br/>
                                    
                                    NB : Pour votre santé, le médecin vous proposera une consultation physique au cabinet s'il le juge nécessaire....
                                </p>
                            </div>
                        </Col>
                        <Col className='d-none d-sm-block' xs='4'>
                        </Col>
                    </Row>
                </div>

                <Modal isOpen={this.state.modal} toggle={this.toggleModal}>
                    <ModalHeader toggle={this.toggleModal}>Information</ModalHeader>
                    <ModalBody>
                        {this.state.message}
                        <br/><br/>
                        <h3>Horaires</h3>
                        {this.state.horaires}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.toggleModal}>Fermer</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}