import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col, Label, Input, Button, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { HttpGET, HttpPOST, HttpDELETE } from "./service/HttpService";

import './ResultatDeRecherche.css';

import avatarHomme from './assets/img/hom_icon87x87px.png';
import avatarFemme from './assets/img/fem_icon87x87px.png';
import avatarEntreprise from './assets/img/hosp_icon87x87px.png';
import iconeVideo from './assets/img/icone_video.png';
import iconeAttente from './assets/img/icone_attente.png';
import iconeDossier from './assets/img/icone_dossier.png';

class ResultatDeRecherche extends Component {
    constructor(props) {
        super(props);

        this.state = {
            message: '',
            horaires: [],
            modal: false
        }

        this.toggleModal = this.toggleModal.bind(this);
        this.consulterEntitee = this.consulterEntitee.bind(this);
    }

    toggleModal() {
        this.setState({
            modal: !this.state.modal
        });
    }

    consulterEntitee = (id, entitee) => {
        if(entitee.type==='Etablissement'){
            this.props.history.push('/detailetablissement/' + entitee.minisite);
            return;
        }
        HttpGET('/medicinemanagement-api/search/recherchertokenconsultation/' + id,
            function (data) {
                if (data.statut === 1) {
                    this.setState({
                        message: data.donnee.message
                    });

                    const horaires = data.donnee.horaires.map((h) =>
                        <div key={h.JOUR} className="admin-element" style={{ color: h.etat === 'DESACTIVE' ? 'gray' : '' }}>
                            <div>
                                <div style={{ fontSize: "1.2rem" }}>{h.designation}</div>
                                {h.etat === 'DESACTIVE' ?
                                    'Pas de consultation ce jour'
                                    :
                                    (h.estH24 ?
                                        'Disponible 24h/24'
                                        :
                                        (!h.aPause ?
                                            <div style={{ fontSize: "0.9rem" }}>{h.debut + ' - ' + h.fin}</div>
                                            :
                                            <div>
                                                <div style={{ fontSize: "0.9rem" }}>{h.debut + ' - ' + h.debutPause}</div>
                                                <div style={{ fontSize: "0.9rem" }}>{h.finPause + ' - ' + h.fin}</div>
                                            </div>))
                                }
                            </div>
                        </div>
                    )

                    this.setState({
                        horaires: horaires
                    });

                    this.toggleModal();

                    return;
                }

                if (data.statut != 0) {
                    return;
                }
                this.props.history.push('/consultation/' + data.donnee);
            }.bind(this),
            function (data) {
                localStorage.setItem('pageSuivante', '/listeresultatderecherche/' + this.props.filtre);
                this.props.history.push('/identification');
            }.bind(this));

    }

    render() {
        var i = 0;
        const assurances = this.props.entitee.assurances.slice(0, 3).map((a) =>
            <span key={a.assurance.id}>{(i++ > 0 ? ' - ' : '') + a.assurance.designation}</span>
        );
        return (
            <div className="search-card search-card--tlc">
                <div className="row" style={{ margin: "0" }}>
                    <div className="col-3 col-sm-2" style={{ paddingLeft: '0px' }}>
                        <div className="searchbar-card-profile-avatar" style={{ width: '80px', overflow: 'hidden' }}>
                            {
                                this.props.sexe == 'HOMME' ?
                                    <img src={avatarHomme} style={{ margin: 'auto 0', width: '100%', overflow: 'hidden' }}></img>
                                    :
                                    this.props.sexe == 'FEMME' ?
                                        <img src={avatarFemme} style={{ margin: 'auto 0', width: '100%', overflow: 'hidden' }}></img>
                                        :
                                        <img src={avatarEntreprise} style={{ margin: 'auto 0', width: '100%', overflow: 'hidden' }}></img>
                            }

                        </div>
                    </div>
                    <div className="col-6 col-sm-4" style={{ paddingTop: '10px' }}>
                        <div class="search-card__infos">
                            <div class="prat__name">{(this.props.type === 'Medecin' ? (this.props.grade + ' ') : '') + this.props.designation}</div>
                            <div class="prat__spe">{this.props.caracteristique}</div>
                            <div class="prat__address">{this.props.etablissement}</div>
                            <p class="prat__address" style={{ marginTop: '0.5rem', marginBottom: '0' }}>
                                {assurances}
                                {
                                    this.props.entitee.assurances.length > 3 ?
                                        <a href="#" style={{ marginLeft: '5px', textDecoration: 'underline', color: '#344B7B', fontStyle: 'italic' }}>Voir plus</a>
                                        : ''
                                }
                            </p>
                        </div>
                    </div>
                    <div className="d-none d-sm-block col-sm-3" style={{ paddingTop: '10px', paddingLeft: '0' }}>
                        <div class="search-card__infos">
                            <div class="prat__name">Horaire</div>
                            <div class="prat__spe">{this.props.entitee.debut + (this.props.entitee.estH24 ? '' : ('-' + this.props.entitee.fin))}</div>
                            <div class="prat__address">{(this.props.type === 'Medecin')?(this.props.entitee.nombrePatientsEnAttente > 0 ? this.props.entitee.nombrePatientsEnAttente + ' patients en attente' : 'Aucune attente'):''} </div>
                            <p class="prat__address" style={{ marginTop: '0.5rem', marginBottom: '0' }}></p>
                        </div>
                    </div>
                    <div className="col-3 col-sm-3" style={{ padding: '0px', backgroundColor: '#E5E5E5' }} onClick={this.consulterEntitee.bind(this, this.props.rechercheId, this.props.entitee)}>
                        <div class="d-none d-sm-block action">
                            <div class="button killonmobile online-dot">
                                <img src={(this.props.type === 'Medecin')?(this.props.entitee.nombrePatientsEnAttente > 0 ? iconeAttente : iconeVideo):iconeDossier}></img>
                            </div>
                            <div class="waiting"><span>{(this.props.type === 'Medecin')?(this.props.entitee.nombrePatientsEnAttente > 0 ? "Se mettre sur la liste d'attente" : "Appeler maintenant"):"Consulter"}</span></div>
                        </div>
                        <div class="d-block d-sm-none action" style={{ paddingTop: '10px' }}>
                            <div class="prat__name">Horaire</div>
                            <div class="prat__spe">{this.props.entitee.debut + (this.props.entitee.estH24 ? '' : ('-' + this.props.entitee.fin))}</div>
                            <div class="prat__address" style={{ fontSize: '0.7rem' }}>{(this.props.type === 'Medecin')?(this.props.entitee.nombrePatientsEnAttente > 0 ? this.props.entitee.nombrePatientsEnAttente + ' patients en attente' : 'Aucune attente'):''}</div>
                            <img src={(this.props.type === 'Medecin')?(this.props.entitee.nombrePatientsEnAttente > 0 ? iconeAttente : iconeVideo):iconeDossier} width="70px"></img>
                        </div>
                    </div>
                </div>

                <Modal isOpen={this.state.modal} toggle={this.toggleModal}>
                    <ModalHeader toggle={this.toggleModal}>Information</ModalHeader>
                    <ModalBody>
                        {this.state.message}
                        <br /><br />
                        <h3>Horaires</h3>
                        {this.state.horaires}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.toggleModal}>Fermer</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default withRouter(ResultatDeRecherche);