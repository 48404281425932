import React, { Component } from 'react';
import { Form, FormGroup, Label, Input, Button, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import Loader from 'react-loader-spinner';

import { HttpGET, HttpPOST, HttpDELETE } from "./service/HttpService";

import logoMTN from './assets/img/mtn.jpg';
import logoMoov from './assets/img/moov.jpg';
import logoOrange from './assets/img/orange.jpg';

let timer = null;

export default class WizardConsultationPaiement extends Component {
    constructor(props) {
        super(props);

        this.state = {
            cout: '',
            devise: 'FCFA',
            operateur: 'OM_SKAN',
            token: '',
            numero: '',

            message: '',
            sendingConsultation: false
        }

        this.demarrer = this.demarrer.bind(this);
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    demarrer() {
        this.setState({
            sendingConsultation: true
        });
        var requete = {
            consultationId: this.props.consultationId,
            patientId: this.props.patientId,
            motifId: this.props.motifId,
            operateur: this.state.operateur,
            token: this.state.token,
            numero: this.state.numero
        };

        HttpPOST("/medicinemanagement-api/enregistrerconsultation", requete, function (data) {
            /*this.setState({
                sendingConsultation: false
            });

            if (data.statut != 0) {
                return;
            }*/

            this.retrouverEtatCommandeConsultation(requete.consultationId);


            //this.props.onChoose(data.donnee);
        }.bind(this));
    }

    retrouverEtatCommandeConsultation = (id) => {

        HttpGET('/medicinemanagement-api/retrouveretatcommandeconsultation/' + id, function (data) {
            if (data.statut === 0) {
                this.setState({
                    sendingConsultation: false
                });

                this.props.onChoose(data.donnee);

                return;
            }
            else if (data.statut === 1) {
                this.setState({
                    message: 'Paiement en attente de validation...'
                });

                //Vérifier à nouveau dans 5s
                timer = setTimeout(() => {
                    this.retrouverEtatCommandeConsultation(id);
                    clearTimeout(timer);
                }, 5000);
            }
            else if (data.statut === 2) {
            }
            else if (data.statut === 500) {
                this.setState({
                    message: data.donnee,
                    sendingConsultation: false
                });
            }
        }.bind(this));
    }

    componentDidMount() {

    }

    render() {
        return (
            <div>
                <h6 style={{color: "red"}}>{this.state.message}</h6>
                <Label>Cout de la consultation : <span>{this.props.cout + ' ' + this.props.devise}</span>{this.props.cout === 0 ? " (Cette consultation est assurée à 100%)" : ""}</Label>
                {this.props.cout !== 0 ?
                    <div>
                        <h5>Paiement</h5>
                        <h6>Choisissez un opérateur</h6>
                        <div style={{ display: "grid", gridTemplateColumns: "auto auto auto" }}>
                            <FormGroup check inline>
                                <Label check style={{ margin: "0 auto" }}>
                                    <Input type="radio" name="operateur" value="OM_SKAN" checked={this.state.operateur == "OM_SKAN"} onChange={this.handleChange} /> <img src={logoOrange} width="50px" style={{ borderRadius: "50%" }}></img>
                                </Label>
                            </FormGroup>
                            <FormGroup check inline>
                                <Label check style={{ margin: "0 auto" }}>
                                    <Input type="radio" name="operateur" value="MOMO_SKAN" checked={this.state.operateur == "MOMO_SKAN"} onChange={this.handleChange} /> <img src={logoMTN} width="50px" style={{ borderRadius: "50%" }}></img>
                                </Label>
                            </FormGroup>
                            {/*<FormGroup check inline>
                                <Label check style={{ margin: "0 auto" }}>
                                    <Input type="radio" name="operateur" value="MOOV_SKAN" checked={this.state.operateur == "MOOV_SKAN"} onChange={this.handleChange} /> <img src={logoMoov} width="50px" style={{ borderRadius: "50%" }}></img>
                                </Label>
                            </FormGroup>*/}
                        </div>
                        <h6 style={{ marginTop: "1rem" }}>Effectuez le paiement</h6>
                        <div>
                            {this.state.operateur == "OM_SKAN" ?
                                <FormGroup>
                                    <Label for="token">Code de sécurité</Label>
                                    <Input type="text" name="token" id="token" placeholder="Saisissez le CODE reçu" onChange={this.handleChange} />
                                    <Label for="token" style={{ fontSize: "0.8rem", color: 'red' }}>Tapez #144*82# pour obtenir un code</Label>
                                </FormGroup>
                                : ''}
                            <FormGroup>
                                <Label for="numero">Numéro</Label>
                                <Input type="text" name="numero" id="numero" placeholder="Ex: 0708682326" onChange={this.handleChange} />
                            </FormGroup>
                            <div style={{ textAlign: "center", marginTop: '20px' }}>
                                <Button className="search-button" onClick={this.demarrer} disabled={this.state.sendingConsultation}>
                                    PAYER
                                    &nbsp;
                                <Loader style={{ display: this.state.sendingConsultation ? 'inline' : 'none' }}
                                        type="Puff"
                                        color="#FFFFFF"
                                        height={25}
                                        width={25}
                                    />
                                </Button>
                            </div>
                        </div>
                    </div>
                    :
                    <div style={{ textAlign: "center", marginTop: '20px' }}>
                        <Button className="search-button" onClick={this.demarrer} disabled={this.state.sendingConsultation}>
                            DEMARRER
                            &nbsp;
                            <Loader style={{ display: this.state.sendingConsultation ? 'inline' : 'none' }}
                                type="Puff"
                                color="#FFFFFF"
                                height={25}
                                width={25}
                            />
                        </Button>
                    </div>
                }
            </div>
        )
    }
}