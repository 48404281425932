import React, { Component } from 'react';
import { Collapse } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

import './Setting.css';

export default class Setting extends Component {
    constructor(props) {
        super(props);

        this.state = { isOpen: props.isOpen };

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    render() {
        return (
            <div className="setting-item">
                <div className="setting-header">
                    <div className="setting-header-title"><FontAwesomeIcon icon={this.props.icon} style={{ marginRight: "20px" }} />{this.props.title}</div>
                    <div className="setting-header-icon" onClick={this.handleClick}><FontAwesomeIcon icon={this.state.isOpen ? faChevronUp : faChevronDown} /></div>
                </div>
                <Collapse isOpen={this.state.isOpen}>
                    <div className="setting-body">
                        {this.props.children}
                    </div>
                </Collapse>
            </div>
        );
    }
}